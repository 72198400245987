/* eslint-disable no-unused-vars */
import React from "react";
import Box from "@mui/material/Box";
import {
  Avatar,
  Stack,
  styled,
  useTheme,
  InputLabel,
  Button,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import SendIcon from "@mui/icons-material/Send";
import Text from "components/shared/text/text";
import CheckBox from "components/shared/checkBox";
import { ITheme } from "theme";
import SharedButton from "components/shared/button/button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InvitesAndMembers from "./invites-and-members";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { companySchema } from "utils/form-schema";
import SharedInput from "components/shared/input/input";
import Form from "components/shared/form/form";
import CircularProgress from "@mui/material/CircularProgress";
import { IRoleType, IUploadAvatarRes, IWorkType } from "types/types";
import IndustryDialog from "./industry";
import SharedTooltip from "../shared/tooltip/tooltip";
import { uploadAvatar } from "utils/uploadAvatar";
import { useAppDispatch } from "redux/store";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import { db, doc, setDoc } from "utils/firebase";
import { collection_name } from "utils/firebase-refs";
import { scrollSectionIntoView } from "utils/helpers";
import { _uniqBy } from "utils/lodash";

const Container = styled(Box)`
  display: flex;
  height: 88%;
  width: 71vw;
  position: fixed;
  background: ${({ theme }) => theme.palette.grey[900]};
  padding-bottom: 50px;
  margin-top: 40px;
`;

interface IState {
  billingAddress: string;
  inviteToCollaborate: string;
  billingEmailAddress: string;
  companyName: string;
  locationHq: string;
  VATID: string;
  remote: boolean;
  file: File[];
}

const Input = styled("input")({
  display: "none",
});

interface IProps {
  handleSendInvite: (a: React.MouseEvent<HTMLButtonElement>) => void;
  handleChangeInvite: (a: any) => void;
  handleOnBlurInvite: (a: any) => void;
  onSubmit: (a: IState) => void;
  onEditCompanyIndustry: (a: any) => void;
  inviteResponse: any;
  handleCheckCheckBox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  updateSelectedIndustries: (a: string) => void;
  handleOpenIndustryDialog: () => void;
  handleCloseIndustryDialog: () => void;
  isUpdatingCompany: string;
  userDisplayName: string;
  workType: string;
  userState: any;
  companyResponse: any;
  state: {
    invite_email: string;
    openDialog: boolean;
    email_invalid: boolean;
    invite_list: { [key: string]: { [key: string]: string }[] };
    company_name: string;
    company_id: string;
    location: string;
    industries: string[];
    uid: string;
    billingAddress: string;
    billingEmailAddress: string;
    industriesDialog: boolean;
    VATID: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedIndustries: any;
  };
}

const Company: React.FC<IProps> = ({
  onSubmit,
  handleSendInvite,
  handleChangeInvite,
  handleOnBlurInvite,
  handleCheckCheckBox,
  isUpdatingCompany,
  inviteResponse,
  onEditCompanyIndustry,
  updateSelectedIndustries,
  handleCloseIndustryDialog,
  handleOpenIndustryDialog,
  userDisplayName,
  userState,
  companyResponse,
  state = {},
  workType,
}) => {
  const theme: ITheme = useTheme();
  const dispatch = useAppDispatch();
  const [imageLoading, setImageLoading] = React.useState(false);
  const [removeImgLoading, setRemoveImgLoading] = React.useState(false);
  const [avatarURI, setAvatarURIImage] = React.useState("");
  const [currentSection, setCurrentSection] = React.useState("Company Info");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IState>({
    resolver: yupResolver(companySchema),
  });

  React.useEffect(() => {
    setValue("companyName", state.company_name || "");
    setValue("locationHq", state.location || "");
    setValue("locationHq", state.location || "");
    setValue("VATID", state.VATID || "");
    setValue("billingEmailAddress", state.billingEmailAddress || "");
    setValue("billingAddress", state.billingAddress || "");
    setValue("remote", workType === IWorkType.REMOTE);
  }, [state]);

  const handleUploadProfile = async ({ file }: any) => {
    setImageLoading(true);
    uploadAvatar({
      userType: IRoleType.CLIENT,
      target_id: state.company_id || "",
      target_name: collection_name.v2_company,
      avatarFile: file,
      shouldUpdateCollection: true,
    })
      .then(async (res: IUploadAvatarRes) => {
        if (res.original_avatar_url && res.thumbnail_avatar_url) {
          setAvatarURIImage(res.original_avatar_url);

          // setThumbnail(res.thumbnail_avatar_url);
          toast.success(
            <ToastMessage
              title="Profile upload"
              body="Profile picture uploaded"
            />,
            { type: "success", position: "top-right" }
          );
        } else {
          toast.error(
            <ToastMessage
              title="Profile upload"
              body="Error uploading profile picture"
            />,
            { type: "error", position: "top-right" }
          );
        }
      })
      .catch((error) => {
        toast(
          <ToastMessage
            title="Profile upload"
            body="Error uploading profile picture"
          />,
          { type: "error", position: "top-right" }
        );
      })
      .finally(() => {
        setImageLoading(false);
      });
  };
  const handleFileSelect = (event: any) => {
    event.preventDefault();
    event.persist();
    const file = event.target.files[0];
    if (!file) {
      toast(<ToastMessage title="File" body="Missing File" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }
    setImageLoading(true);
    handleUploadProfile({
      file,
      url: URL && URL.createObjectURL(file),
    });
  };

  function stringAvatar(name: string) {
    return {
      children: imageLoading ? (
        <CircularProgress
          sx={{ marginRight: "10px" }}
          color="inherit"
          size="30px"
        />
      ) : (
        `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      ),
    };
  }

  const removeProfile = async () => {
    setRemoveImgLoading(true);
    if (!state.company_id) {
      toast.error(
        <ToastMessage
          title="Missing company id"
          body="Unable to delete profile. Missing user id"
        />,
        { type: "error" }
      );
      return;
    }
    const companyRef = doc(db, collection_name.v2_company, state.company_id);
    setDoc(
      companyRef,
      {
        original_avatar_url: "",
        thumbnail_avatar_url: "",
      },
      { merge: true }
    );
    setAvatarURIImage("");
    setRemoveImgLoading(false);
  };

  const sortInvite = (list: { [key: string]: string }[]) => {
    if (!Array.isArray(list)) return [];
    return list.sort(
      (a: { [key: string]: string }, b: { [key: string]: string }) => {
        if (!a?.created_at || !b?.created_at) return 0;
        const A = new Date(a.created_at);
        const B = new Date(b.created_at);
        return A > B ? 1 : -1;
      }
    );
  };

  const viewSection = (el: string) => {
    const elem = el
      .replace(/[^a-zA-Z0-9]/g, " ")
      .replace(/\s+/g, "-")
      .toLocaleLowerCase();
    scrollSectionIntoView(`#${elem}`);
    setCurrentSection(el);
  };

  return (
    <Container>
      {!state.company_name ? (
        <Box
          sx={{
            position: "fixed",
            background: "transparent",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            zIndex: 1,
          }}
        >
          <Box sx={{ position: "absolute", top: "50%", left: "50%" }}>
            <CircularProgress
              sx={{ marginRight: "10px" }}
              color="inherit"
              size="50px"
            />
          </Box>
        </Box>
      ) : null}
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        sx={{
          background: theme.palette.grey[900],
          paddingBottom: "24px",
          // borderRadius: "8px",
          "&::-webkit-scrollbar": { display: "none" },
          overflow: "scroll",
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box
            id="company-info"
            display="flex"
            flex="1"
            flexDirection="column"
            sx={{
              background: theme.palette.grey[100],
              padding: "24px",
              borderRadius: "8px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "16px",
                  height: "32px",
                  margin: "0px 16px 0px 0px",
                  background: theme?.custom.colors.green,
                  borderRadius: "4px",
                }}
              />
              <Text
                variant="h2"
                fontSize="20px"
                fontWeight={600}
                lineHeight="32px"
                letterSpacing="-0.02em"
                sx={{
                  marginLeft: 0,
                  textAlign: "left",
                }}
              >
                Company Info
              </Text>
            </Box>
            <Stack
              direction="row"
              sx={{
                mt: "34px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                component="label"
                onChange={handleFileSelect}
                htmlFor="contained-button-file"
                sx={{
                  border: "none",
                  height: "96px",
                  width: "96px",
                  borderRadius: "50%",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                <Avatar
                  sx={{
                    height: "96px",
                    width: "96px",
                    background: imageLoading ? theme.palette.grey[300] : null,
                  }}
                  alt={userDisplayName}
                  src={
                    avatarURI
                      ? avatarURI
                      : companyResponse?.original_avatar_url
                      ? companyResponse.original_avatar_url
                      : null
                  }
                  {...stringAvatar(userDisplayName)}
                />
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  {...register("file")}
                  type="file"
                />
              </Button>
              <Button
                variant="outlined"
                component="label"
                onChange={handleFileSelect}
                htmlFor="contained-button-file"
                sx={{
                  border: "none",
                  height: "48px",
                  width: "auto",
                  background: "transparent",
                  "&:hover": {
                    background: "transparent",
                    boxShadow: "none",
                    border: "none",
                  },
                }}
              >
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  {...register("file")}
                  type="file"
                />
                <SharedButton
                  startIcon={<AddIcon />}
                  variant="contained"
                  color="secondary"
                  component="span"
                  sx={{
                    width: "auto",
                    ml: "32px",
                    height: "48px",
                    boxShadow: "none",
                    textTransform: "none",
                  }}
                >
                  Upload new logo
                </SharedButton>
              </Button>
              <SharedButton
                variant="contained"
                startIcon={removeImgLoading ? null : <RemoveIcon />}
                onClick={removeProfile}
                sx={{
                  ml: "12px",
                  width: "auto",
                  height: "48px",
                  color: theme.palette.text.secondary,
                  backgroundColor: theme.palette.grey[100],
                  textTransform: "none",
                  boxShadow: "none",
                  border: `2px solid ${theme.palette.grey[300]}`,
                  "&:hover": {
                    backgroundColor: theme.palette.grey[100],
                    cursor: "pointer",
                    boxShadow: "none",
                  },
                }}
              >
                {removeImgLoading ? (
                  <>
                    <CircularProgress
                      sx={{ marginRight: "10px" }}
                      color="inherit"
                      size="20px"
                    />
                    <span>Loading...</span>
                  </>
                ) : (
                  "Remove"
                )}
              </SharedButton>
            </Stack>
            <Stack direction="column" sx={{ width: "100%", mt: "35px" }}>
              <Box display="flex" flexDirection={"column"}>
                <InputLabel
                  shrink
                  htmlFor="component-simple"
                  sx={{
                    marginBottom: "5px",
                    display: "flex",
                    fontFamily: "inter",
                  }}
                >
                  Company name
                </InputLabel>
                <SharedInput
                  sx={{
                    marginBottom: "20px",
                    position: "relative",
                  }}
                  placeholder="Company name"
                  variant="filled"
                  id="companyName"
                  register={register}
                  errors={errors.companyName?.message}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  ml: 0,
                  mt: "32px",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  variant="body1"
                  fontSize="14px"
                  fontWeight={400}
                  lineHeight="32px"
                  letterSpacing="-0.01em"
                  sx={{
                    ml: 0,
                    pl: 0,
                  }}
                >
                  Business model & industry
                </Text>
                <Box onClick={handleOpenIndustryDialog}>
                  <Text
                    variant="body1"
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight="32px"
                    letterSpacing="-0.01em"
                    sx={{
                      marginLeft: 0,
                      textAlign: "left",
                      textDecoration: "underline",
                      color: theme.palette.grey[400],
                      cursor: "pointer",
                    }}
                  >
                    Edit
                  </Text>
                </Box>
              </Box>
              <Box sx={{ display: "flex", mb: "34px", mt: "12px" }}>
                {state?.industries?.map((el: string) => (
                  <Box
                    key={el}
                    display="flex"
                    alignItems={"center"}
                    justifyContent="center"
                    sx={{
                      padding: "12px",
                      background: theme.palette.grey[50],
                      border: `2px solid ${theme.custom.colors.neutral_grey}`,
                      borderRadius: "12px",
                      mr: "8px",
                    }}
                  >
                    <Text
                      variant="body1"
                      fontSize="15px"
                      fontWeight={600}
                      lineHeight="24px"
                      letterSpacing="-0.01em"
                      sx={{
                        color: theme.palette.text.secondary,
                        marginLeft: 0,
                      }}
                    >
                      {el}
                    </Text>
                  </Box>
                ))}
              </Box>
              <Box display="flex" flexDirection={"column"}>
                <InputLabel
                  shrink
                  htmlFor="component-simple"
                  sx={{
                    marginBottom: "5px",
                    display: "flex",
                    fontFamily: "inter",
                  }}
                >
                  Location HQ
                </InputLabel>
                <SharedInput
                  sx={{
                    marginBottom: "20px",
                    position: "relative",
                  }}
                  placeholder="Berlin, Germany"
                  variant="filled"
                  id="locationHq"
                  register={register}
                  errors={errors.locationHq?.message}
                />
              </Box>
              {/* <CheckBox label="Remote" {...register("remote")} /> */}
              <CheckBox
                label="Remote"
                checked={workType === IWorkType.REMOTE}
                onChange={handleCheckCheckBox}
              />
            </Stack>
          </Box>
          <Box
            id="billing"
            display="flex"
            flex="1"
            flexDirection="column"
            sx={{
              background: theme.palette.grey[100],
              padding: "24px",
              borderRadius: "8px",
              marginTop: "8px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "16px",
                  height: "32px",
                  margin: "0px 16px 0px 0px",
                  background: theme.custom.colors.purple,
                  borderRadius: "4px",
                }}
              />
              <Text
                variant="h2"
                fontSize="20px"
                fontWeight={600}
                lineHeight="32px"
                letterSpacing="-0.02em"
                sx={{
                  marginLeft: 0,
                  textAlign: "left",
                }}
              >
                Billing
              </Text>
            </Box>
            <Stack direction="column" sx={{ width: "100%", mt: "35px" }}>
              <Box display="flex" flexDirection={"column"}>
                <InputLabel
                  shrink
                  htmlFor="component-simple"
                  sx={{
                    marginBottom: "5px",
                    display: "flex",
                    fontFamily: "inter",
                  }}
                >
                  VAT ID for invoicing
                </InputLabel>
                <SharedInput
                  sx={{
                    marginBottom: "20px",
                    position: "relative",
                  }}
                  placeholder="Type here"
                  variant="filled"
                  id="VATID"
                  register={register}
                  errors={errors.VATID?.message}
                />
              </Box>
              <Box display="flex" flexDirection={"column"}>
                <InputLabel
                  shrink
                  htmlFor="component-simple"
                  sx={{
                    marginBottom: "5px",
                    display: "flex",
                    fontFamily: "inter",
                  }}
                >
                  Billing email address
                </InputLabel>
                <SharedInput
                  sx={{
                    marginBottom: "20px",
                    position: "relative",
                  }}
                  placeholder="Type here"
                  variant="filled"
                  id="billingEmailAddress"
                  register={register}
                  errors={errors.billingEmailAddress?.message}
                />
              </Box>
              <Box display="flex" flexDirection={"column"}>
                <InputLabel
                  shrink
                  htmlFor="component-simple"
                  sx={{
                    marginBottom: "5px",
                    display: "flex",
                    fontFamily: "inter",
                  }}
                >
                  Billing address
                </InputLabel>
                <SharedInput
                  sx={{
                    marginBottom: "20px",
                    position: "relative",
                  }}
                  placeholder="Type here"
                  variant="filled"
                  id="billingAddress"
                  register={register}
                  errors={errors.billingAddress?.message}
                />
              </Box>
            </Stack>
          </Box>
          <Box
            id="teams-access"
            display="flex"
            flex="1"
            flexDirection="column"
            sx={{
              background: theme.palette.grey[100],
              padding: "24px",
              borderRadius: "8px",
              marginTop: "8px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "16px",
                  height: "32px",
                  margin: "0px 16px 0px 0px",
                  background: "#FFD88D",
                  borderRadius: "4px",
                }}
              />
              <Text
                variant="h2"
                fontSize="20px"
                fontWeight={600}
                lineHeight="32px"
                letterSpacing="-0.02em"
                sx={{
                  marginLeft: 0,
                  textAlign: "left",
                }}
              >
                Teams & Access
              </Text>
            </Box>
            <Stack direction="column" sx={{ width: "100%", mt: "35px" }}>
              <Box display="flex" flexDirection={"column"}>
                <InputLabel
                  shrink
                  htmlFor="component-simple"
                  sx={{
                    marginBottom: "5px",
                    display: "flex",
                    fontFamily: "inter",
                  }}
                >
                  Invite a team member to collaborate
                  <SharedTooltip
                    title="Team members invited will be able to view the jobs and interested candidates
                  under the same company, and make decisions on interested candidates."
                  >
                    <ErrorIcon
                      sx={{
                        color: theme.palette.text.primary,
                        height: "23.33px",
                        ml: "5px",
                      }}
                    />
                  </SharedTooltip>
                </InputLabel>
                <SharedInput
                  sx={{
                    marginBottom: "20px",
                    position: "relative",
                  }}
                  placeholder="Enter email"
                  variant="filled"
                  id="invite_email"
                  register={() => {}}
                  value={state.invite_email}
                  onChange={handleChangeInvite}
                  onBlur={handleOnBlurInvite}
                  iconEnd={
                    <SharedButton
                      variant="contained"
                      component="span"
                      onClick={handleSendInvite}
                      sx={{
                        width: "auto",
                        borderRadius: "12px",
                        padding: "12px 20px",
                        marginRight: "-0.8rem",
                        height: "45px",
                      }}
                      startIcon={<SendIcon />}
                    >
                      {inviteResponse.status === "pending" ? (
                        <CircularProgress
                          sx={{ marginRight: "10px" }}
                          color="inherit"
                          size="20px"
                        />
                      ) : (
                        "send"
                      )}
                    </SharedButton>
                  }
                  errors={state?.email_invalid ? "Invalid email address" : ""}
                />
              </Box>
              {companyResponse?.invited_members?.pending?.length ? (
                <InvitesAndMembers
                  invite_list={sortInvite(
                    _uniqBy(
                      companyResponse.invited_members.pending,
                      "receiversEmail"
                    )
                  )}
                  heading="Pending invites"
                  revokeMessage="Revoke Invite"
                  isInvite
                  tooltip="Team members listed here have not yet accepted
                  their invite to join the same company on Connect."
                />
              ) : null}
              {companyResponse?.invited_members?.accepted?.length ? (
                <InvitesAndMembers
                  invite_list={sortInvite(
                    _uniqBy(
                      companyResponse.invited_members.accepted,
                      "receiversEmail"
                    )
                  )}
                  heading="Team Members"
                  revokeMessage="Revoke access"
                  isInvite={false}
                  tooltip="Team members listed here have accepted the invite and
                  created an account under the same company. If they no longer
                  need to view candidates at this stage, you can also revoke their access."
                />
              ) : null}
            </Stack>
            <SharedButton
              variant="contained"
              color="secondary"
              // component="span"
              type="submit"
              sx={{
                width: "291px",
                ml: "32px",
                height: "48px",
                marginLeft: "10px",
                boxShadow: "none",
                textTransform: "none",
              }}
              // onClick={handleSubmit(onSubmit)}
            >
              {isUpdatingCompany === "pending" ? (
                <>
                  <CircularProgress
                    sx={{ marginRight: "10px" }}
                    color="inherit"
                    size="20px"
                  />
                  <span>Loading...</span>
                </>
              ) : (
                "Save"
              )}
            </SharedButton>
          </Box>
        </Form>
      </Box>
      <Box
        display="flex"
        flex="0.4"
        flexDirection="column"
        sx={{
          marginLeft: "8px",
          background: theme.palette.grey[100],
          padding: "24px",
          borderRadius: "8px",
          maxHeight: "25vh",
        }}
      >
        {["Company Info", "Billing", "Teams & Access"].map((el: string) => (
          <Box
            key={el}
            sx={{
              width: "100%",
              float: "right",
              borderRadius: "8px",
              marginBottom: "4px",
              cursor: "pointer",
              background:
                el === currentSection
                  ? theme.palette.grey[300]
                  : theme.palette.grey[100],
            }}
            onClick={() => viewSection(el)}
          >
            <Text
              variant="body1"
              fontWeight={600}
              fontSize={"15px"}
              lineHeight={"24px"}
              sx={{
                color:
                  el === currentSection
                    ? theme.palette.text.secondary
                    : theme.palette.grey[400],
                textAlign: "left",
                marginLeft: 0,
                padding: "8px 16px",
                borderRadius: "8px",
                cursor: "pointer",
                "&:hover": {
                  color: theme.palette.text.secondary,
                },
              }}
            >
              {el}
            </Text>
          </Box>
        ))}
      </Box>
      <IndustryDialog
        openDialog={state.industriesDialog}
        onEditCompanyIndustry={onEditCompanyIndustry}
        updateSelectedIndustries={updateSelectedIndustries}
        selectedIndustries={state.selectedIndustries}
        isUpdatingCompany={isUpdatingCompany}
        handleCloseIndustryDialog={handleCloseIndustryDialog}
      />
    </Container>
  );
};

export default Company;
