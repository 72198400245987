/* eslint-disable no-unused-vars */
import React, { FC, useState, useRef } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { ITheme } from "theme";
import { useNavigate } from "react-router-dom";

import { useOnClickOutside } from "../../hooks/use-on-click-outside";
import Span from "components/shared/span/span";
import Text from "components/shared/text/text";
import SharedTooltip from "components/shared/tooltip/tooltip";
import ForwardArrow from "assets/images/forward-arrow.svg";
import JobTitle from "./job-title";
import JobCard from "./job-card";
import Ellipsis from "assets/images/ellipsis.svg";
import LightTicks from "assets/images/light-ticks.svg";
import EditPencil from "assets/images/edit-pen.svg";
import ArchiveRed from "assets/images/archive_red.svg";
import ArchiveGreen from "assets/images/unarchive.svg";
import Light from "assets/images/light.svg";
import IconLinkedATS from "assets/images/linked-ats.svg";
import { _get } from "utils/lodash";
import { useSelector } from "react-redux";
import { IJobStatus, IJob } from "types/types";
import { IGetJobsPayload } from "redux/services/job/getJob/interface";
import {
  ROUTE_ACTIVE_JOBS,
  ROUTE_EDIT_JOB,
  ROUTE_INTEGRATIONS,
} from "routes/route-items";
import { useAppDispatch, RootState } from "redux/store";
import { doCreateJobAction } from "redux/services/job/create/create.actions";
import ATSJobs from "./ats-jobs";
import Dialog from "components/dialog";
import { doLinkJobToATSAction } from "redux/services/ats/link-job-to-ats/get.actions";
import { doGetJobsAction } from "redux/services/job/getJob/get.actions";
import { db, doc, getDoc } from "utils/firebase";
import { collection_name } from "utils/firebase-refs";
import { doUpdateJobAction } from "redux/services/job/update/update.actions";

interface IProps {
  id?: string;
  title: string;
  jobCategory: string;
  createdAt: string;
  jobStatus?: IJobStatus;
  currJobList: IGetJobsPayload;
  jobData: IJob;
  review?: number;
  handleUpdateJobStatus: (a: { job: IJob }) => void;
}

const EllipsisIcon = styled("img")`
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }
`;

const EditJobContainer = styled(Box)`
  position: absolute;
  display: none;
  width: 102%;
  height: 102%;
  top: -2px;
  left: -3px;
  right: -2px;
  bottom: -2px;
  background-color: rgba(17, 19, 21, 0.8);
  z-index: 2;
  color: #f1f1f1;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  transition: all 0.3s ease;
  &.show {
    display: flex;
  }
`;

const EditTextContainer = styled(Box)`
  display: inline-flex;
  background: #fcfcfc;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  gap: 4px;
  &.archive {
    padding: 8px;
    background: linear-gradient(
        0deg,
        rgba(247, 79, 68, 0.15),
        rgba(247, 79, 68, 0.15)
      ),
      #ffffff;
  }
  &.unarchive {
    background: linear-gradient(
        0deg,
        rgba(131, 191, 110, 0.15),
        rgba(131, 191, 110, 0.15)
      ),
      #ffffff;
  }
`;

const CardWithJob: FC<IProps> = ({
  id,
  title = "",
  jobCategory = "",
  createdAt = "",
  jobStatus,
  jobData,
  currJobList,
  handleUpdateJobStatus,
}) => {
  const theme: ITheme = useTheme();
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const [open, toggleDialog] = useState(false);
  const [openConnectATSDialog, toggleOpenConnectATSDialog] = useState(false);
  const [openATSDialog, toggleATSDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [unlinkATS, toggleUnlinkATSDialog] = useState(false);
  const [unlinkATSLoading, toggleUnlinkATSLoading] = useState(false);
  const [isLinkedToATS, setIsLinkedToATS] = useState(false);

  React.useEffect(() => {
    if (jobData.linked_ats_job_details) {
      setIsLinkedToATS(true);
    } else {
      setIsLinkedToATS(false);
    }
  }, [jobData.linked_ats_job_details]);

  const companyState = useSelector(
    (reduxState: RootState) => reduxState.getCompany.response
  );
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );

  const navigate = useNavigate();
  const interested_candidates =
    (jobData?.attached_candidates?.total || 0) -
    (jobData?.attached_candidates?.archived || 0);

  useOnClickOutside(ref, () => toggleDialog(false));

  const openDialog = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    toggleDialog((prev: boolean) => !prev);
  };

  const handleClickEditJob = async (props?: { add_criteria?: boolean }) => {
    const jobRef = doc(db, `${collection_name.v2_jobs}/${jobData.job_id}`);
    const docSnap = await getDoc(jobRef);
    const job_data = docSnap.data() as IJob;

    await dispatch(
      doCreateJobAction({
        job: {
          seniority_level: job_data.seniority_level,
          job_function: job_data.job_function,
          custom_job_function: job_data.custom_job_function,
          job_description_link: job_data.job_description_link,
          job_description: job_data.job_description,
          job_title: job_data.job_title,
          company_id: job_data.company_id || "",
          company_name: job_data.company_name || "",
          created_by: job_data.created_by || "",
          skills_nice_to_have: job_data.skills_nice_to_have,
          skills_not_to_have: job_data.skills_not_to_have,
          skills_must_have: job_data.skills_must_have,
          job_exciting_things: job_data.job_exciting_things,
          salary_range: job_data.salary_range,
          selected_currency: job_data.selected_currency,
          checkbox_pay_unknown: job_data.checkbox_pay_unknown,
          checkbox_share_salary_range: job_data.checkbox_share_salary_range,
          recruiter_notes: job_data.recruiter_notes,
        },
      })
    );
    navigate(`/${ROUTE_EDIT_JOB}`, {
      state: {
        from: ROUTE_ACTIVE_JOBS,
        uid: jobData.created_by,
        job_id: jobData.job_id,
        active_step: props?.add_criteria ? 1 : 0,
      },
    });
  };

  const handleCardClicked = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigate(`/${ROUTE_ACTIVE_JOBS}/${id}`, { state: title });
  };

  const handleDisableBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const skills = jobData.skills_must_have as {
    [key: string]: string;
  };
  const hasSkillsMustHave = skills && Object.values(skills)?.length > 0;

  const addCriteria = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (hasSkillsMustHave) return;
    handleClickEditJob({ add_criteria: true });
  };

  const linkToATS = () => {
    toggleDialog(false);
    toggleATSDialog(!openATSDialog);
  };

  const handleSaveATSJob = async (data: { [key: string]: string }) => {
    setIsLoading(true);
    if (jobData.job_id && jobData.company_id) {
      const platform = companyState.ats_data?.account_token?.integration
        ?.slug as string;
      await dispatch(
        doLinkJobToATSAction({
          company_id: jobData.company_id,
          job_id: jobData.job_id,
          created_by: jobData.created_by || "",
          ats_jobId: data.ats_jobId,
          ats_jobStatus: data.ats_jobStatus,
          ats_jobTitle: data.ats_jobTitle,
          platform_name: platform,
        })
      );
      let curr_jobs: IJob[] = [];
      if (currJobList?.jobs?.length) {
        currJobList.jobs.map((el) => {
          if (el.job_id === jobData.job_id) {
            curr_jobs.push({
              ...el,
              linked_ats_job_details: {
                ats_jobId: data.ats_jobId,
                ats_jobStatus: data.ats_jobStatus,
                ats_jobTitle: data.ats_jobTitle,
              },
            });
          } else {
            curr_jobs.push({
              ...el,
            });
          }
        });
      } else {
        curr_jobs = currJobList.jobs || [];
      }

      await dispatch(
        doGetJobsAction({
          ...currJobList,
          company_id: jobData.company_id,
          totalPages: currJobList.totalPages,
          page: currJobList.page,
          totalJobs: currJobList.totalJobs,
          hasMore: currJobList.hasMore || false,
          jobs: curr_jobs,
        })
      );
      setIsLinkedToATS(true);
      toggleATSDialog(false);
    } else {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const navigateToIntegration = () => {
    navigate(`/${ROUTE_INTEGRATIONS}`);
  };

  const handleUnlinkJob = async () => {
    toggleUnlinkATSLoading(true);

    await dispatch(
      doUpdateJobAction({
        job_id: jobData.job_id as string,
        job: {
          linked_ats_job_details: null,
        },
      })
    );

    let curr_jobs: IJob[] = [];

    if (currJobList?.jobs?.length) {
      currJobList.jobs.map((el) => {
        if (el.job_id === jobData.job_id) {
          curr_jobs.push({
            ...el,
            linked_ats_job_details: null,
          });
        } else {
          curr_jobs.push({
            ...el,
          });
        }
      });
    } else {
      curr_jobs = currJobList.jobs || [];
    }
    await dispatch(
      doGetJobsAction({
        ...currJobList,
        company_id:
          jobData.company_id ||
          companyState.company_id ||
          userState.company_id ||
          "",
        totalPages: currJobList.totalPages,
        page: currJobList.page,
        totalJobs: currJobList.totalJobs,
        hasMore: currJobList.hasMore || false,
        jobs: curr_jobs,
      })
    );

    toggleUnlinkATSLoading(false);
    toggleUnlinkATSDialog(false);
  };

  return (
    <>
      <JobCard jobStatus={jobStatus} onClick={handleCardClicked}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box display="flex" alignItems={"center"}>
            <Span color={theme.palette.text.secondary}>{createdAt} ago</Span>
            {isLinkedToATS && companyState?.ats_data?.account_token ? (
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginLeft: "10px",
                  background: "#ffd88d",
                  borderRadius: "4px",
                  padding: "4px 6px",
                }}
              >
                <img
                  style={{ width: "16px", height: "16px" }}
                  src={IconLinkedATS}
                  alt="linked icon"
                />
                <Text
                  fontWeight={400}
                  fontSize="13px"
                  lineHeight={"16px"}
                  letterSpacing="-0.01em"
                  color={"#1A1D1F;"}
                >
                  ATS
                </Text>
              </Box>
            ) : null}
          </Box>
          <EllipsisIcon src={Ellipsis} alt="Job options" onClick={openDialog} />
          {open ? (
            <Box
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                return;
              }}
              ref={ref}
              sx={{
                position: "absolute",
                right: "-1rem",
                top: "8px",
                // minHeight: "15rem",
                zIndex: 1,
                background: "#FFFFFF",
                minWidth: "25rem",
                padding: "1.2rem",
                boxShadow:
                  // eslint-disable-next-line max-len
                  "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
                borderRadius: "4px",
              }}
            >
              <Box
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  return;
                }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    return handleClickEditJob();
                  }}
                  onKeyDown={() => handleClickEditJob()}
                  sx={{
                    background: theme.palette.grey[50],
                    borderRadius: "12px",
                    padding: "12px",
                    marginBottom: "0.2rem",
                    "&:hover": {
                      background: theme.palette.grey[900],
                      "&>p": {
                        color: "#1A1D1F !important",
                      },
                    },
                  }}
                >
                  <img src={EditPencil} alt="" style={{ width: "24px" }} />
                  <Text
                    fontSize="15px"
                    lineHeight="24px"
                    fontWeight={600}
                    letterSpacing="-0.01em"
                    color={theme.palette.grey[400]}
                  >
                    Edit Job
                  </Text>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    if (companyState?.ats_data?.account_token) {
                      if (isLinkedToATS) {
                        toggleUnlinkATSDialog(true);
                        return;
                      }
                      return linkToATS();
                    } else {
                      toggleOpenConnectATSDialog(true);
                    }
                  }}
                  onKeyDown={() => {
                    if (companyState?.ats_data?.account_token) {
                      if (isLinkedToATS) {
                        toggleUnlinkATSDialog(true);
                        return;
                      }
                      return linkToATS();
                    } else {
                      toggleOpenConnectATSDialog(true);
                    }
                  }}
                  sx={{
                    background: theme.palette.grey[50],
                    borderRadius: "12px",
                    padding: "12px",
                    marginBottom: "0.2rem",
                    // cursor:
                    //   isLinkedToATS && companyATSData?.account_token
                    //     ? "not-allowed"
                    //     : "cursor",
                    "&:hover": {
                      background: theme.palette.grey[900],
                      "&>p": {
                        // color:
                        //   isLinkedToATS && companyATSData?.account_token
                        //     ? ""
                        //     : "#1A1D1F !important",
                      },
                    },
                  }}
                >
                  <img src={Light} alt="" />
                  <Text
                    fontSize="15px"
                    lineHeight="24px"
                    fontWeight={600}
                    letterSpacing="-0.01em"
                    color={theme.palette.grey[400]}
                  >
                    {isLinkedToATS && companyState?.ats_data?.account_token
                      ? "Unlink job from ATS"
                      : "Link job to ATS"}
                  </Text>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    const job_status =
                      jobStatus === IJobStatus.ARCHIVE
                        ? IJobStatus.ACTIVE
                        : IJobStatus.ARCHIVE;
                    return handleUpdateJobStatus({
                      job: { ...jobData, status: job_status },
                    });
                  }}
                  onKeyDown={() => linkToATS()}
                  sx={{
                    background: theme.palette.grey[50],
                    borderRadius: "12px",
                    padding: "12px",
                    "&:hover": {
                      background: theme.palette.grey[900],
                      "&>p": {
                        color: "#1A1D1F !important",
                      },
                    },
                  }}
                >
                  <img
                    src={
                      jobStatus === IJobStatus.ARCHIVE
                        ? ArchiveGreen
                        : ArchiveRed
                    }
                    alt=""
                  />
                  <Text
                    fontSize="15px"
                    lineHeight="24px"
                    fontWeight={600}
                    letterSpacing="-0.01em"
                    color={theme.palette.grey[400]}
                  >
                    {jobStatus === IJobStatus.ARCHIVE
                      ? "Unarchive job"
                      : "Archive job"}
                  </Text>
                </Box>
              </Box>
              <Box
                sx={{
                  right: "1.2rem",
                  position: "absolute",
                  top: "-17px",
                  borderLeft: "12px solid transparent",
                  borderRight: "12px solid transparent",
                  borderBottom: "19px solid #FFFFFF",
                }}
              />
            </Box>
          ) : null}
        </Box>
        <Box>
          <JobTitle title={title} job_category={jobCategory} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <Span
              fontWeight={600}
              fontSize="15px"
              color={theme.custom.colors.dark_grey_1}
              sx={{ lineHeight: "1" }}
            >
              {interested_candidates} candidate
              {interested_candidates !== 1 && "s"}
            </Span>
            <Span
              onClick={addCriteria}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 7px",
                gap: "10px",
                backgroundColor: hasSkillsMustHave ? "#b5e4ca" : "#ffbc99",
                borderRadius: "6px",
                lineHeight: "150%",
              }}
              fontSize="14px"
              color={theme.palette.text.secondary}
            >
              {hasSkillsMustHave && <img src={LightTicks} alt="" />}
              <Box>
                {hasSkillsMustHave ? (
                  <>{jobData?.attached_candidates?.PENDING || 0} to review</>
                ) : (
                  <SharedTooltip
                    title="Click to add the ideal candidate profile for this
                        job so we can start delivering relevant candidates"
                  >
                    <Span>Add criteria</Span>
                  </SharedTooltip>
                )}
              </Box>
            </Span>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img src={ForwardArrow} alt="" />
        </Box>
      </JobCard>
      <ATSJobs
        openDialog={openATSDialog}
        handleCloseDialog={() => toggleATSDialog(false)}
        handleAcceptDialog={handleSaveATSJob}
        isLoading={isLoading}
      />
      <Dialog
        openDialog={unlinkATS}
        isLoading={unlinkATSLoading}
        handleCloseDialog={() => toggleUnlinkATSDialog(false)}
        handleAcceptDialog={handleUnlinkJob}
        title="Confirm your action"
        body="Are you sure you want to unlink this job?"
      />
      <Dialog
        openDialog={openConnectATSDialog}
        isLoading={false}
        handleCloseDialog={() => toggleOpenConnectATSDialog(false)}
        handleAcceptDialog={navigateToIntegration}
        canCloseDialog={false}
        submitButtonText="Setup ATS integration"
        showCloseIcon={true}
        title={
          <Text
            fontSize="22px"
            lineHeight="24px"
            fontWeight={500}
            letterSpacing="-0.01em"
            color={"#003350"}
            sx={{
              textAlign: "left",
            }}
          >
            Switch on your ATS integration
          </Text>
        }
        body={
          <Text
            fontSize="14px"
            lineHeight="150%"
            fontWeight={400}
            letterSpacing="-0.01em"
            sx={{
              marginBottom: "1.5rem",
              textAlign: "left",
            }}
          >
            You have not switched on your ATS integration yet - let’s do it!
          </Text>
        }
        styles={{
          minWidth: "560px",
          maxWidth: "560px",
        }}
        body_2={
          <Text
            fontSize="14px"
            lineHeight="150%"
            fontWeight={400}
            letterSpacing="-0.01em"
            sx={{
              textAlign: "left",
            }}
          >
            Proceed to the{" "}
            <span
              onClick={navigateToIntegration}
              style={{
                textDecoration: "underline",
                color: "#3785F9",
                cursor: "pointer",
              }}
            >
              Integrations page
            </span>{" "}
            and setup your ATS integration there. Doing so is quick and easy. It
            will automatically create candidates in your ATS whenever they are
            created in your Connect jobs.
          </Text>
        }
      />
    </>
  );
};

export default CardWithJob;
