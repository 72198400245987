/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React from "react";
import SharedButton from "components/shared/button/button";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { companySchema } from "utils/form-schema";
import PrivateLayout from "layouts/drawer";
import SendIcon from "@mui/icons-material/Send";
import { useAppDispatch, RootState } from "redux/store";
import { ITheme } from "theme";
import { useSelector } from "react-redux";
import { doInviteMemberAction } from "redux/services/company/invite/invite.actions";
import { emailRegex } from "constants/constants";
import { _get, _uniqBy, _findIndex } from "utils/lodash";
import { auth } from "utils/firebase";
import CompanyComponent from "components/company";
import Dialog from "components/dialog";
import { toast, TypeOptions } from "react-toastify";
import ToastMessage from "components/toast-message";
import { collection_name } from "utils/firebase-refs";
import {
  onSnapshot,
  query,
  db,
  collection,
  orderBy,
  doc,
} from "utils/firebase";
import { InviteStatus, IRoleType, IWorkType } from "types/types";
import { doUpdateCompanyAction } from "redux/services/company/update/update-company.actions";
import { doGetCompanyAction } from "redux/services/company/get/get.actions";
interface IState {
  billingAddress: string;
  inviteToCollaborate: string;
  billingEmailAddress: string;
  companyName: string;
  locationHq: string;
  VATID: string;
  remote: boolean;
  file: File[];
}

interface IEState {
  invite_email: string;
  openDialog: boolean;
  isLoading: boolean;
  email_invalid: boolean;
  company_name: string;
  company_id: string;
  location: string;
  industries: string[];
  uid: string;
  billingAddress: string;
  billingEmailAddress: string;
  VATID: string;
  industriesDialog: boolean;
  selectedIndustries: string[];
  invite_list: { [key: string]: { [key: string]: string }[] };
  change_type: string;
}

const initialState: IEState = {
  invite_email: "",
  company_name: "",
  company_id: "",
  location: "",
  industries: [],
  uid: "",
  openDialog: false,
  isLoading: false,
  email_invalid: false,
  industriesDialog: false,
  selectedIndustries: [],
  invite_list: {
    pending: [],
    accepted: [],
    rejected: [],
  },
  billingAddress: "",
  billingEmailAddress: "",
  VATID: "",
  change_type: "",
};

let global_invited_members: { [key: string]: { [key: string]: string }[] } = {};
let inviteExistingUser: {
  [key: string]: { [key: string]: string };
} = {
  user: {
    receiversUID: "",
    receiversEmail: "",
    receiversDisplayName: "",
  },
  request_info: {
    company_id: "",
    company_name: "",
    receiver_id: "",
    receiversEmail: "",
    receiversFirstName: "",
    receiversLastName: "",
    role: "",
    sendersEmail: "",
    sendersFirstName: "",
    sendersId: "",
    sendersLastName: "",
  },
};

const CompanyProfile = React.memo(() => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IState>({
    resolver: yupResolver(companySchema),
  });

  const dispatch = useAppDispatch();
  const [state, setState] = React.useState<IEState>(initialState);
  const [workType, setWorkType] = React.useState<IWorkType>(IWorkType.OFFICE);

  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const companyResponse = useSelector(
    (reduxState: RootState) => reduxState.getCompany.response
  );
  const companyUpdateReduxState = useSelector(
    (reduxState: RootState) => reduxState.updateCompany || {}
  );
  const inviteReduxState = useSelector(
    (reduxState: RootState) => reduxState.inviteMember || {}
  );

  global_invited_members = companyResponse.invited_members as {
    [key: string]: { [key: string]: string }[];
  };

  const onSubmit = async (data: IState) => {
    if (!companyResponse.company_id) {
      toast(<ToastMessage title="Company" body="Missing company data" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", data.file[0]);
    const resp = await dispatch(
      doUpdateCompanyAction({
        company_id: companyResponse.company_id,
        company: {
          vatID: data.VATID,
          billingAddress: data.billingAddress,
          billingEmailAddress: data.billingEmailAddress,
          work_type: workType,
          name: data.companyName,
          location: data.locationHq,
        },
      })
    );
    const comp_resp = _get(resp, "payload.message.data.message") || {};
    const company_meta =
      _get(resp, "payload.message.data.message._company.message") || {};
    if (comp_resp.company_id) {
      await dispatch(
        doGetCompanyAction({
          company_id: comp_resp.company_id,
          company: {
            ...companyResponse,
            ...comp_resp,
            ...company_meta,
            company_id: comp_resp.company_id,
            company_name: companyResponse.name,
          },
        })
      );
    }
    toast(<ToastMessage title="Company" body="Company details saved" />, {
      type: "success",
      position: "top-right",
    });
  };

  // React.useEffect(() => {
  //   if(companyResponse) {
  //     setState((st: IEState) => ({
  //       ...st,
  //       company_name: companyResponse.name ||  companyResponse.company_name || "",
  //       location: companyResponse.location || "",
  //       company_id: companyResponse.company_id || "",
  //       industries: companyResponse.industries || [],
  //       billingAddress: companyResponse.billingAddress,
  //       billingEmailAddress: companyResponse.billingEmailAddress,
  //       VATID: companyResponse.vatID,
  //       uid: companyResponse.uid || "",
  //     }));
  //     setWorkType(companyResponse.work_type);
  //   }
  // }, [companyResponse]);

  // Opens confirm dialog for existing users

  // React.useEffect(() => {
  //   if (inviteResponse?.code === 409 && !state.openDialog) {
  //     setState((st: IEState) => ({
  //       ...st,
  //       openDialog: true,
  //     }));
  //   }
  // }, [inviteResponse?.code]);

  const sortInvite = (list: { [key: string]: string }[]) => {
    if (!Array.isArray(list)) return [];
    return list.sort(
      (a: { [key: string]: string }, b: { [key: string]: string }) => {
        if (!a?.created_at || !b?.created_at) return 0;
        const A = new Date(a.created_at);
        const B = new Date(b.created_at);
        return A > B ? 1 : -1;
      }
    );
  };

  const getInviteStatus = React.useCallback(async () => {
    if (!userState.company_id) {
      toast(<ToastMessage title="Company" body="Missing company data" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }

    const q = query(
      collection(
        db,
        `${collection_name.v2_company}`,
        userState.company_id,
        "invited_members"
      ),
      orderBy("created_at")
    );
    let curr_invite_state: { [key: string]: { [key: string]: string }[] } = {
      [InviteStatus.pending]: [],
      [InviteStatus.accepted]: [],
      [InviteStatus.rejected]: [],
    };
    if (companyResponse.invited_members) {
      curr_invite_state = {
        ...companyResponse.invited_members,
      };
    }
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added") {
          const inviteDoc = change.doc.data();
          const parsed_created_at = inviteDoc.created_at
            ? new Date(inviteDoc.created_at.seconds * 1000).toISOString()
            : "";

          const invites_doc: { [key: string]: string } = {
            created_at: parsed_created_at,
            invite_link: inviteDoc?.invite_link,
            receiversEmail: inviteDoc?.receiversEmail,
            sendersEmail: inviteDoc?.sendersEmail,
            sendersId: inviteDoc?.sendersId,
            status: inviteDoc?.status,
          };
          if (
            invites_doc.status.toLowerCase() ===
            InviteStatus.pending.toLowerCase()
          ) {
            curr_invite_state = {
              ...curr_invite_state,
              [InviteStatus.pending.toLowerCase()]: _uniqBy(
                [
                  ...curr_invite_state[InviteStatus.pending.toLowerCase()],
                ].concat({ ...invites_doc }),
                "receiversEmail"
              ),
            };
          }
          if (
            invites_doc.status.toLowerCase() ===
            InviteStatus.accepted.toLowerCase()
          ) {
            curr_invite_state = {
              ...curr_invite_state,
              [InviteStatus.accepted.toLowerCase()]: _uniqBy(
                [
                  ...curr_invite_state[InviteStatus.accepted.toLowerCase()],
                ].concat({ ...invites_doc }),
                "receiversEmail"
              ),
            };
          }
          if (
            invites_doc.status.toLowerCase() ===
            InviteStatus.rejected.toLowerCase()
          ) {
            curr_invite_state = {
              ...curr_invite_state,
              [InviteStatus.rejected.toLowerCase()]: _uniqBy(
                [
                  ...curr_invite_state[InviteStatus.rejected.toLowerCase()],
                ].concat({ ...invites_doc }),
                "receiversEmail"
              ),
            };
          }
        }

        if (change.type === "modified") {
          const updated_invite = change.doc.data();

          const updatedInvite: { [key: string]: string } = {
            created_at: updated_invite.created_at
              ? new Date(updated_invite.created_at.seconds * 1000).toISOString()
              : "",
            invite_link: updated_invite?.invite_link,
            receiversEmail: updated_invite?.receiversEmail,
            sendersEmail: updated_invite?.sendersEmail,
            sendersId: updated_invite?.sendersId,
            status: updated_invite?.status,
          };

          const invite_status: string = updatedInvite.status.toLowerCase();
          const curr_members = _get(global_invited_members, "") || [];

          if (global_invited_members) {
            if (invite_status === InviteStatus.accepted.toLowerCase()) {
              const acc_list = _get(global_invited_members, "pending") || [];
              const filtered_pending_list = acc_list.filter(
                (el: { [key: string]: string }) => {
                  return el.receiversEmail !== updatedInvite.receiversEmail;
                }
              );
              curr_invite_state = {
                ...curr_invite_state,
                [InviteStatus.pending.toLowerCase()]: _uniqBy(
                  [...filtered_pending_list],
                  "receiversEmail"
                ),
                [InviteStatus.accepted.toLowerCase()]: _uniqBy(
                  [
                    ...curr_invite_state[InviteStatus.accepted.toLowerCase()],
                  ].concat({ ...updatedInvite }),
                  "receiversEmail"
                ),
              };
            }

            if (invite_status === InviteStatus.pending.toLowerCase()) {
              const acc_list = _get(global_invited_members, "accepted") || [];
              const filtered_accepted_list = acc_list.filter(
                (el: { [key: string]: string }) => {
                  return el.receiversEmail !== updatedInvite.receiversEmail;
                }
              );
              curr_invite_state = {
                ...curr_invite_state,
                [InviteStatus.pending.toLowerCase()]: _uniqBy(
                  [
                    ...curr_invite_state[InviteStatus.pending.toLowerCase()],
                  ].concat({ ...updatedInvite }),
                  "receiversEmail"
                ),
                [InviteStatus.accepted.toLowerCase()]: _uniqBy(
                  [...filtered_accepted_list],
                  "receiversEmail"
                ),
              };
            }

            if (invite_status === InviteStatus.rejected.toLowerCase()) {
              const acc_pending_list =
                _get(global_invited_members, "pending") || [];
              const acc_accepted_list =
                _get(global_invited_members, "accepted") || [];
              const filtered_pending_list = acc_pending_list.filter(
                (el: { [key: string]: string }) => {
                  return el.receiversEmail !== updatedInvite.receiversEmail;
                }
              );
              const filtered_accepted_list = acc_accepted_list.filter(
                (el: { [key: string]: string }) => {
                  return el.receiversEmail !== updatedInvite.receiversEmail;
                }
              );
              curr_invite_state = {
                ...curr_invite_state,
                [InviteStatus.rejected.toLowerCase()]: _uniqBy(
                  [
                    ...curr_invite_state[InviteStatus.rejected.toLowerCase()],
                  ].concat({ ...updatedInvite }),
                  "receiversEmail"
                ),
                [InviteStatus.pending.toLowerCase()]: _uniqBy(
                  [...filtered_pending_list],
                  "receiversEmail"
                ),
                [InviteStatus.accepted.toLowerCase()]: _uniqBy(
                  [...filtered_pending_list],
                  "receiversEmail"
                ),
              };
            }
          }
        }
      });
      const _company_item = _get(companyResponse, "_company.message") || {};

      await dispatch(
        doGetCompanyAction({
          company_id: companyResponse.company_id,
          company: {
            ...companyResponse,
            ..._company_item,
            company_id: companyResponse.company_id,
            company_name: companyResponse.name,
            invited_members: {
              [InviteStatus.pending]: sortInvite(
                _uniqBy(
                  curr_invite_state[InviteStatus.pending.toLowerCase()],
                  "receiversEmail"
                )
              ),
              [InviteStatus.accepted]: sortInvite(
                _uniqBy(
                  curr_invite_state[InviteStatus.accepted.toLowerCase()],
                  "receiversEmail"
                )
              ),
              [InviteStatus.rejected]: sortInvite(
                _uniqBy(
                  curr_invite_state[InviteStatus.rejected.toLowerCase()],
                  "receiversEmail"
                )
              ),
            },
          },
        })
      );
    });
    return () => {
      unsubscribe();
    };
  }, [userState.company_id]);
  const getCompany = React.useCallback(async () => {
    if (!userState.company_id) {
      toast(<ToastMessage title="Company" body="Missing company data" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const q = doc(db, `${collection_name.v2_company}`, userState.company_id);

    const unsubscribe = onSnapshot(q, async (doc) => {
      const company_data = doc.data();
      setState((st: IEState) => ({
        ...st,
        company_name: company_data?.name,
        location: company_data?.location,
        company_id: company_data?.company_id,
        industries: company_data?.industries || [],
        billingAddress: company_data?.billingAddress,
        billingEmailAddress: company_data?.billingEmailAddress,
        VATID: company_data?.vatID,
        uid: company_data?.uid,
      }));
      setWorkType(company_data?.work_type);
    });
    return () => {
      unsubscribe();
    };
  }, [userState.company_id]);

  React.useEffect(() => {
    getCompany();
  }, [getCompany]);

  React.useEffect(() => {
    getInviteStatus();
  }, [getInviteStatus]);

  const handleChangeInvite = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((st: IEState) => ({
      ...st,
      email_invalid: false,
      invite_email: e.target.value,
    }));
  };

  const handleOnBlurInvite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValidEmail = e.target.value.match(emailRegex);
    if (!isValidEmail) {
      setState((st: IEState) => ({
        ...st,
        email_invalid: true,
      }));
    }
  };

  const handleAcceptDialog = async () => {
    // setState((st: IEState) => ({ ...st, isLoading: true }));
    const response = await dispatch(
      doInviteMemberAction({
        // ...inviteExistingUser.request_info,
        sendersFirstName:
          inviteExistingUser.request_info.sendersFirstName || "",
        sendersLastName: inviteExistingUser.request_info.sendersLastName || "",
        sendersEmail: inviteExistingUser.request_info.sendersEmail || "",
        sendersId: inviteExistingUser.request_info.sendersId || "",
        receiversEmail: inviteExistingUser.request_info.receiversEmail,
        company_id: inviteExistingUser.request_info.company_id,
        company_name: companyResponse.name || userState.company_name,
        receiver_id: inviteExistingUser?.user?.receiversUID || "",
        receiversFirstName:
          inviteExistingUser.user.receiversDisplayName.split(" ")[0] || "",
        receiversLastName:
          inviteExistingUser.user.receiversDisplayName.split(" ")[1] || "",
        isExistingUser: true,
      })
    );
    setState((st: IEState) => ({
      ...st,
      openDialog: false,
      invite_email: "",
      isLoading: false,
    }));
    const resp = _get(response, "payload");
    if (resp.success) {
      toast(<ToastMessage title="Email sent" body="Invite email sent" />, {
        type: "success",
        position: "top-right",
      });
    } else {
      toast(
        <ToastMessage
          title="Email error"
          body="Error occurred sending email"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }
  };

  const handleSendInvite = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const response = await dispatch(
      doInviteMemberAction({
        sendersFirstName: userState.firstName || "",
        sendersLastName: userState.lastName || "",
        sendersEmail: userState.email || "",
        sendersId: userState.uid || "",
        receiversEmail: state.invite_email,
        company_id: userState.company_id,
        company_name: companyResponse.name || userState.company_name,
        isExistingUser: false,
      })
    );
    const resp = _get(response, "payload");
    if (resp.message.code === 409) {
      inviteExistingUser = {
        user: {
          ...resp.message.user,
        },
        request_info: {
          ...resp.message.request_info,
        },
      };
      await handleAcceptDialog();
      return;
    }
    if (resp.success) {
      setState((st: IEState) => ({
        ...st,
        invite_email: "",
      }));
      toast(<ToastMessage title="Email sent" body="Invite email sent" />, {
        type: "success",
        position: "bottom-right",
      });
    } else {
      toast(
        <ToastMessage
          title="Email error"
          body="Error occurred sending email"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }
  };

  const handleCloseDialog = async () => {
    setState((st: IEState) => ({ ...st, openDialog: false }));
    await dispatch(
      doInviteMemberAction({
        sendersFirstName: "",
        sendersLastName: "",
        sendersEmail: "",
        sendersId: "",
        receiversEmail: "",
        company_id: "",
        company_name: "",
        cancelled: true,
      })
    );
  };

  const handleCheckCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWorkType(event.target.checked ? IWorkType.REMOTE : IWorkType.OFFICE);
  };

  const updateSelectedIndustries = (value: any) => {
    if (state.selectedIndustries?.length) {
      if (state.selectedIndustries.includes(value)) {
        setState((st: IEState) => ({
          ...st,
          selectedIndustries: st.selectedIndustries.filter(
            (industry) => industry !== value
          ),
        }));
      } else {
        setState((st: IEState) => ({
          ...st,
          selectedIndustries: [...st.selectedIndustries, value],
        }));
      }
    } else {
      setState((st: IEState) => ({
        ...st,
        selectedIndustries: [value],
      }));
    }
  };

  const onEditCompanyIndustry = async (data: any) => {
    if (!companyResponse.company_id) {
      toast(<ToastMessage title="Company" body="Missing company data" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }

    await dispatch(
      doUpdateCompanyAction({
        company_id: companyResponse.company_id,
        company: {
          industries: state.selectedIndustries || [],
        },
      })
    );
    setState((st: IEState) => ({
      ...st,
      industriesDialog: false,
    }));
  };

  const handleOpenIndustryDialog = () => {
    setState((st: IEState) => ({
      ...st,
      industriesDialog: true,
    }));
  };

  const handleCloseIndustryDialog = () => {
    setState((st: IEState) => ({
      ...st,
      industriesDialog: false,
    }));
  };

  return (
    <PrivateLayout menu="Company Settings">
      <CompanyComponent
        onSubmit={onSubmit}
        onEditCompanyIndustry={onEditCompanyIndustry}
        handleCloseIndustryDialog={handleCloseIndustryDialog}
        updateSelectedIndustries={updateSelectedIndustries}
        handleCheckCheckBox={handleCheckCheckBox}
        handleSendInvite={handleSendInvite}
        handleOpenIndustryDialog={handleOpenIndustryDialog}
        handleChangeInvite={handleChangeInvite}
        handleOnBlurInvite={handleOnBlurInvite}
        state={state}
        workType={workType}
        inviteResponse={inviteReduxState}
        isUpdatingCompany={companyUpdateReduxState.status}
        userDisplayName={`${userState.firstName} ${userState.lastName}`}
        userState={userState}
        companyResponse={companyResponse}
      />
      <Dialog
        openDialog={state.openDialog}
        isLoading={state.isLoading}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
        body={`Invite an existing account`}
      />
    </PrivateLayout>
  );
});
CompanyProfile.displayName = "CompanyProfile";
export default CompanyProfile;
