import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Google } from "assets/images/google.svg";
import { ReactComponent as Warning } from "assets/images/warning.svg";
import CircularProgress from "@mui/material/CircularProgress";
import AuthForm from "components/auth-form/auth-form";
import SharedButton from "components/shared/button/button";
import Form from "components/shared/form/form";
import HR from "components/shared/horizontal-rule";
import Input from "components/shared/input/input";
import Text from "components/shared/text/text";
import { useForm } from "react-hook-form";
import { InitialSignUpSchema } from "utils/form-schema";
import { styled } from "@mui/system";

interface IState {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  terms: boolean;
}

interface IProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: IState) => void;
  handleGoogleSignUp: () => void;
  isLoading?: boolean | string;
}

const FormContainer = styled(Form)`
  max-width: 330px;
  align-items: center;
`;

const SignUp: React.FC<IProps> = ({
  onSubmit,
  handleGoogleSignUp,
  isLoading,
}) => {
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IState>({
    resolver: yupResolver(InitialSignUpSchema),
  });

  return (
    <AuthForm id="sign-up" title="Sign up" sx={{ minWidth: "330px" }}>
      <SharedButton
        onClick={handleGoogleSignUp}
        sx={{ marginBottom: "2rem" }}
        variant="outlined"
      >
        <Google />
        <Box
          sx={{
            marginLeft: "1rem",
            textTransform: "initial",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isLoading === "pending" || isLoading ? (
            <>
              <CircularProgress
                sx={{ marginRight: "5px" }}
                color="inherit"
                size="20px"
              />
              <span>Loading ...</span>
            </>
          ) : (
            "Sign up with Google"
          )}
        </Box>
      </SharedButton>
      <HR />
      <Text
        fontSize="14px"
        lineHeight="24px"
        fontWeight={600}
        letterSpacing="-0.01em"
        sx={{ marginLeft: 0, marginTop: "2rem", marginBottom: "1.5rem" }}
      >
        Or sign up with email address
      </Text>
      <FormContainer onSubmit={(e) => handleSubmit(onSubmit)(e)}>
        <Input
          iconStart={
            <MailOutlineIcon
              sx={{ color: theme.palette.text.primary, fontSize: 25 }}
            />
          }
          ref={(input) => input && input.focus()}
          iconEnd={errors?.email?.message ? <Warning /> : null}
          sx={{
            marginBottom: "20px",
            position: "relative",
          }}
          placeholder="Your work email"
          variant="filled"
          id="email"
          register={register}
          errors={errors?.email?.message}
        />
        <SharedButton type="submit" variant="contained">
          <Box
            sx={{
              marginLeft: "1rem",
              textTransform: "initial",
              display: "flex",
              alignItems: "center",
            }}
          >
            {isLoading === "pending" || isLoading ? (
              <>
                <CircularProgress
                  sx={{ marginRight: "10px" }}
                  color="inherit"
                  size="20px"
                />
                <span>Loading ...</span>
              </>
            ) : (
              "Continue"
            )}
          </Box>
        </SharedButton>
      </FormContainer>
    </AuthForm>
  );
};

export default SignUp;
