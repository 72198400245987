import React from "react";
import Text from "components/shared/text/text";
import CreateJob from "components/new-job/page-one";
import { useNavigate } from "react-router-dom";
import AuthForm from "components/auth-form/auth-form";
import { doCreateJobAction } from "redux/services/job/create/create.actions";
import {
  ROUTE_JOB_ONBOARDING_COMPLETE,
  ROUTE_BUILDING,
} from "routes/route-items";
import { RootState, useAppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import { _get } from "utils/lodash";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import { resetUsers } from "redux/services/user/user";
import storage from "redux-persist/lib/storage";
import { restructureJobs } from "redux/reselect";
import { doGetJobsAction } from "redux/services/job/getJob/get.actions";
import { auth as firebaseAuth } from "utils/firebase";
import { ICreateJobPayload } from "redux/services/job/create/interface";

interface IState {
  step_1: {
    checkboxChecked: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editorState: any;
    selected_seniority_level: string;
    selected_job_function: string;
    custom_job_function: string;
    job_description_link: string;
    job_title: string;
  };
}

const initialState = {
  step_1: {
    checkboxChecked: false,
    editorState: "",
    selected_seniority_level: "",
    selected_job_function: "",
    custom_job_function: "",
    job_description_link: "",
    job_title: "",
  },
};

const Welcome = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [state, setState] = React.useState<IState>(initialState);
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const companyState = useSelector(
    (reduxState: RootState) => reduxState.getCompany.response
  );
  const reduxCompanyCreate = useSelector(
    (reduxState: RootState) => reduxState.companyCreate.response || {}
  );
  const createJobState = useSelector(
    (reduxState: RootState) => reduxState.jobCreate || {}
  );
  const jobsGetState = useSelector(
    (reduxState: RootState) => reduxState.jobsGet.response
  );

  const handleSignout = () => {
    dispatch(resetUsers());
    storage.removeItem("persist:root");
    navigate("/");
  };

  const handleSaveDescription = async (data: {
    jobDescriptionLink: string;
    jobTitle: string;
    customJobFunction: string;
  }) => {
    const { step_1 } = state;
    const companyCreate =
      _get(reduxCompanyCreate, "response.data.message") || {};

    if (!userState.uid || !userState.company_id) {
      toast(<ToastMessage title="Authentication" body="Missing user data" />, {
        type: "error",
        position: "top-right",
      });
      return handleSignout();
    }

    await firebaseAuth.getAuth().currentUser?.getIdTokenResult(true);

    const response = await dispatch(
      doCreateJobAction({
        step: "step_3",
        job: {
          seniority_level: step_1.selected_seniority_level,
          job_function: step_1.selected_job_function,
          custom_job_function:
            step_1.selected_job_function === data.customJobFunction
              ? null
              : data.customJobFunction,
          job_description_link: data.jobDescriptionLink,
          job_title: data.jobTitle,
          job_description: "",
          salary_range: [25000, 100000],
          selected_currency: { value: "eur", label: "€ EUR" },
          company_id: userState.company_id,
          company_name:
            userState.company_name ||
            companyState.name ||
            companyCreate.companyName,
          created_by: userState.uid,
          recruiter_notes: "",
          skills_nice_to_have: {},
          skills_not_to_have: {},
          skills_must_have: {},
          job_exciting_things: {},
          checkbox_pay_unknown: false,
          checkbox_share_salary_range: false,
        },
      })
    );

    if (response.meta.requestStatus === "rejected") {
      return toast(
        <ToastMessage title="New Job" body="Error adding new job" />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }

    const payload = response.payload as ICreateJobPayload;
    const jobData = payload.job;
    const job_added = {
      seniority_level: jobData.seniority_level,
      job_function: jobData.job_function,
      custom_job_function: jobData.custom_job_function,
      job_description: jobData.job_description,
      recruiter_notes: jobData.recruiter_notes,
      job_description_link: jobData.job_description_link,
      job_title: jobData.job_title,
      skills_must_have: jobData.skills_must_have,
      skills_nice_to_have: jobData.skills_nice_to_have,
      skills_not_to_have: jobData.skills_not_to_have,
      job_exciting_things: jobData.job_exciting_things,
      salary_range: jobData.salary_range,
      selected_currency: jobData.selected_currency,
      checkbox_pay_unknown: jobData.checkbox_pay_unknown,
      checkbox_share_salary_range: jobData.checkbox_share_salary_range,
      company_id: jobData.company_id,
      company_name: jobData.company_name,
      created_by: jobData.created_by,
      status: jobData.status,
      job_id: jobData.job_id,
      created_at: jobData.created_at
        ? new Date(jobData.created_at._seconds * 1000).toISOString()
        : new Date().toISOString(),
      attached_candidates: {
        total: jobData.attached_candidates?.total,
        PENDING: jobData.attached_candidates?.PENDING,
        SHORTLISTED: jobData.attached_candidates?.SHORTLISTED,
        REJECTED: jobData.attached_candidates?.REJECTED,
        archived: jobData.attached_candidates?.archived,
      },
    };

    const jobs = jobsGetState?.jobs || [];
    const restructuredList = restructureJobs({
      jobState: [...jobs, job_added],
      jobStatus: jobsGetState.jobStatus,
    });

    await dispatch(
      doGetJobsAction({
        ...jobsGetState,
        jobs: restructuredList,
        from_onboarding: true,
      })
    );
    return navigate(`/${ROUTE_JOB_ONBOARDING_COMPLETE}`);
  };

  const skipCreatingFirstJob = async () => {
    await firebaseAuth.getAuth().currentUser?.getIdTokenResult(true);
    navigate(`/${ROUTE_JOB_ONBOARDING_COMPLETE}`, {
      state: { skip: true },
    });
  };

  const handleSetState = ({
    key,
    value,
    step,
  }: {
    key: string;
    step: string;
    value: string | boolean | Object;
  }) => {
    const curr_step = state[step as keyof typeof state];

    setState((st) => ({
      ...st,
      [step]: {
        ...curr_step,
        [key]: value,
      },
    }));
  };

  return (
    <AuthForm
      id="create-job"
      title=""
      currentStep={3}
      stepsStyles={{
        width: "100%",
        height: "1rem",
      }}
      sx={{ width: "80%", paddingTop: "2rem" }}
    >
      <Text
        variant="h2"
        fontSize="40px"
        fontWeight={600}
        lineHeight="48px"
        letterSpacing="-0.03em"
        sx={{
          marginBottom: "1rem",
          marginLeft: 0,
          textAlign: "center",
        }}
      >
        Add your first job
      </Text>
      <CreateJob
        handleSaveDescription={handleSaveDescription}
        handleSetState={handleSetState}
        state={state}
        currentPage="on-boarding_newJob"
        createJobStatus={createJobState.status}
        handleSkip={skipCreatingFirstJob}
        handleBack={() => navigate(`/${ROUTE_BUILDING}`)}
      />
    </AuthForm>
  );
};

export default Welcome;
