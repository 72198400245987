import React from "react";
import { styled } from "@mui/material/styles";
import Text from "components/shared/text/text";
import SharedButton from "components/shared/button/button";
import Box from "@mui/material/Box";
import { ROUTE_ACTIVE_JOBS } from "routes/route-items";
import { useNavigate } from "react-router-dom";

const NoJobArea = styled("div")`
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 24px;
  margin-top: 8px;
  border-radius: 8px;
  align-items: center;
  font-family: "Inter";
`;

const NotCompanyJob = () => {
  const navigate = useNavigate();

  const goBackToConnect = () => {
    navigate(`/${ROUTE_ACTIVE_JOBS}`);
  };

  return (
    <NoJobArea
      sx={{
        height: "538px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "24px",
      }}
    >
      <Text
        sx={{
          marginTop: "2rem",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "40px",
          letterSpacing: "-0.03em",
        }}
      >
        Sorry, this page is not available
      </Text>
      <Text
        sx={{
          marginTop: "10px",
          fontFamily: "Inter",
          fontStyle: "normal",
          color: "#6F767e",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "150%",
          letterSpacing: "-0.01em",
          marginLeft: "0",
          marginBottom: "24px",
        }}
      >
        If you think this is a mistake, please
        <br /> reach out to{" "}
        <a href="mailto:catia@acelr8.com" style={{ color: "#6F767e" }}>
          our support team
        </a>
        .
      </Text>
      {/* <Text
        sx={{
          marginTop: "1rem",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "150%",
          letterSpacing: "-0.01em",
          textDecorationLine: "underline",
          cursor: "pointer",
        }}
        onClick={goBackToConnect}
      >
        Connect Home Page
      </Text> */}
      <SharedButton
        onClick={goBackToConnect}
        sx={{ width: "auto" }}
        variant="contained"
      >
        <Box sx={{ textTransform: "initial" }}>Connect Home Page</Box>
      </SharedButton>
    </NoJobArea>
  );
};

export default NotCompanyJob;
