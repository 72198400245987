/* eslint-disable object-curly-spacing */
import React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import ForwardArrow from "assets/images/forward-arrow.svg";
import JobTitle from "./job-title";
import JobCard from "./job-card";
import { ROUTE_ACTIVE_JOBS, ROUTE_NEW_JOB } from "routes/route-items";
import { useAppDispatch } from "redux/store";
import { doCreateJobAction } from "redux/services/job/create/create.actions";

function CreateJobCard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCreateNewJob = async () => {
    await dispatch(
      doCreateJobAction({
        job: {
          seniority_level: "",
          job_function: "",
          job_description_link: "",
          job_title: "",
          job_description: "",
          company_id: "",
          company_name: "",
          created_by: "",
          skills_nice_to_have: {},
          skills_not_to_have: {},
          skills_must_have: {},
          job_exciting_things: {},
          selected_currency: {
            label: "",
            value: "",
          },
          recruiter_notes: "",
        },
      })
    );
    navigate(`/${ROUTE_NEW_JOB}`, { state: { from: ROUTE_ACTIVE_JOBS } });
  };
  return (
    <JobCard onClick={handleCreateNewJob} className="new-job">
      <Box>
        <JobTitle title="Add a new job" />
      </Box>
      <Box sx={{ position: "absolute", bottom: "15px" }}>
        <img src={ForwardArrow} alt="" />
      </Box>
    </JobCard>
  );
}

export default CreateJobCard;
