/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPayload } from "./interface";
import * as axios from "api/axios";
const axiosInstance = axios.default;

export const doLinkJobToATSAction = createAsyncThunk(
  "ats/link-atsJobs",
  async (payload: IPayload) => {
    try {
      const response = await axiosInstance.post("/link-ats-job", {
        company_id: payload.company_id,
        created_by: payload.created_by,
        job_id: payload.job_id,
        ats_jobId: payload.ats_jobId,
        ats_jobStatus: payload.ats_jobStatus,
        ats_jobTitle: payload.ats_jobTitle,
        platform_name: payload.platform_name,
      });

      return {
        success: true,
        message: response.data || {},
      };
    } catch (error: any) {
      return error.response?.data || "Something went wrong";
    }
  }
);
