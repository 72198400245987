import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { db, doc, getDoc } from "utils/firebase";
import { IPayload } from "./interface";
import { IJob } from "types/types";

export const doGetJobAction: AsyncThunk<IJob | unknown, IPayload, {}> =
  createAsyncThunk("job/get", async (payload: IPayload) => {
    if (payload.job) {
      return payload.job;
    }

    try {
      const jobRef = doc(db, `v2_jobs/${payload.job_id}`);
      const jobSnapshot = await getDoc(jobRef);
      const {
        seniority_level,
        job_function,
        custom_job_function,
        job_description,
        linked_ats_job_details,
        recruiter_notes,
        job_description_link,
        job_title,
        skills_must_have,
        skills_nice_to_have,
        skills_not_to_have,
        job_exciting_things,
        salary_range,
        selected_currency,
        checkbox_pay_unknown,
        checkbox_share_salary_range,
        company_id,
        company_name,
        created_by,
        status,
        job_id,
        created_at,
        attached_candidates,
      } = jobSnapshot.data() as IJob;

      return {
        seniority_level,
        job_function,
        custom_job_function,
        job_description,
        linked_ats_job_details,
        recruiter_notes,
        job_description_link,
        job_title,
        skills_must_have,
        skills_nice_to_have,
        skills_not_to_have,
        job_exciting_things,
        salary_range,
        selected_currency,
        checkbox_pay_unknown,
        checkbox_share_salary_range,
        company_id,
        company_name,
        created_by,
        status,
        job_id,
        created_at: created_at
          ? new Date(created_at._seconds * 1000).toISOString()
          : new Date().toISOString(),
        attached_candidates: {
          total: attached_candidates?.total,
          PENDING: attached_candidates?.PENDING,
          SHORTLISTED: attached_candidates?.SHORTLISTED,
          REJECTED: attached_candidates?.REJECTED,
          archived: attached_candidates?.archived,
        },
      };
    } catch (error) {
      return error;
    }
  });
