/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { IState } from "./interface";
import { doGetATSJobsAction } from "./get.actions";

const initialState: IState = {
  status: "",
  response: {},
  error: {},
};

export const getATSJobs = createSlice({
  name: "atsJobs/get",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(doGetATSJobsAction.pending, (state: IState) => {
        state.status = "pending";
      })
      .addCase(
        doGetATSJobsAction.fulfilled,
        (state: IState, action: AnyAction) => {
          state.status = "fulfilled";
          state.response = action.payload;
          state.error = {};
        }
      )
      .addCase(
        doGetATSJobsAction.rejected,
        (state: IState, action: AnyAction) => {
          state.status = "rejected";
          state.response = {};
          state.error = action.error;
        }
      );
  },
});

export default getATSJobs.reducer;
