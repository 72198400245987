/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Text from "components/shared/text/text";
import Input from "components/shared/input/input";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IndustrySchema } from "utils/form-schema";
import STYLES from "./styles";
import SharedButton from "components/shared/button/button";
import Form from "components/shared/form/form";
import { IndustryTypes } from "constants/constants";
import { isEmpty } from "utils/lodash";

const DialogContainer = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    min-width: 42rem;
    background-color: ${({ theme }) => theme.palette.grey[50]};
  }
  & .MuiDialogTitle-root {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 500;
    font-size: 22px;
    font-family: Inter;
  }
  & .MuiDialogContentText-root {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 400;
    font-size: 16px;
    font-family: Inter;
  }
  & .MuiLoadingButton-text {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 400;
    font-size: 16px;
    font-family: Inter;
  }
`;

const DisButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 400;
  font-size: 16px;
  font-family: Inter;
`;
const LoadButton = styled(LoadingButton)`
  color: ${({ theme }) => theme.palette.primary.main} !important;
  font-weight: 400;
  font-size: 16px;
  font-family: Inter;
  min-width: 5.3rem;
  display: flex;
  justify-content: flex-start;
  border: none;
  &:hover {
    border: none !important;
  }
`;

interface IProps {
  openDialog?: boolean;
  onEditCompanyIndustry: (a: any) => void;
  updateSelectedIndustries: (a: string) => void;
  handleCloseIndustryDialog: () => void;
  selectedIndustries: string[]
  isUpdatingCompany: string;
}

const IndustryDialog: React.FC<IProps> = ({
  openDialog = false,
  onEditCompanyIndustry,
  updateSelectedIndustries,
  handleCloseIndustryDialog,
  selectedIndustries,
  isUpdatingCompany,
}) => {
  const { register, handleSubmit, control, trigger } = useForm({
    resolver: yupResolver(IndustrySchema),
  });

  const industryName = useWatch({
    control,
    name: "modelAndIndustry",
  });

  const [_selectedIndustries, setSelectedIndustries] = React.useState<
    string[] | null
  >([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [error, setError] = React.useState("");
  const theme = useTheme();

  const industries: string[] = React.useMemo(() => {
    return IndustryTypes.filter((industry: string) => {
      if (searchQuery?.length) {
        return industry.toLowerCase().includes(searchQuery.toLowerCase());
      } else {
        return industry.toLowerCase();
      }
    });
  }, [searchQuery]);

  React.useEffect(() => {
    if (industryName?.length) {
      setSearchQuery(industryName);
    } else {
      setSearchQuery(industryName);
    }
  }, [industryName]);

  const ContentForm = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  return (
    <DialogContainer
      open={openDialog}
      onClose={handleCloseIndustryDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <ContentForm
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSubmit={(e: any) => {
            if (isEmpty(selectedIndustries)) {
              setError("Pick at least one industry");
              return trigger("modelAndIndustry");
            }
            handleSubmit(onEditCompanyIndustry)(e);
          }}
        >
          <Box
            sx={{
              mx: "auto",
              textAlign: "left",
              width: "100%"
            }}
          >
            <Text
              variant="h2"
              fontSize="48px"
              fontWeight={600}
              lineHeight="48px"
              letterSpacing="-0.03em"
              sx={{
                mb: "2rem",
                mx: "auto",
                width: "100%",
                textAlign: "left",
              }}
            >
              What are you building?
            </Text>
            <Text
              variant="body1"
              fontFamily={"Inter"}
              fontStyle={"normal"}
              fontWeight={400}
              fontSize={"14px"}
              lineHeight={"1.5"}
              color={theme.palette.grey[400]}
              marginBottom={"20px"}
              sx={{
                maxWidth: "30rem",
                textAlign: "left",
                marginLeft: "0px",
                marginRight: "0px"
              }}
            >
              This helps us match you with relevant candidates. This is 1 of 3
              steps in kicking off your job with us.
            </Text>
            <Input
              iconStart={
                <SearchOutlinedIcon
                  sx={{ color: theme.palette.text.primary, fontSize: 25 }}
                />
              }
              fullWidth
              id="modelAndIndustry"
              register={register}
              errors={error}
              sx={{
                mb: "20px",
                mx: "auto",
                width: "100%",
                textAlign: "left",
              }}
              autoFocus
              placeholder="Type to find or select below"
              variant="filled"
            />
          </Box>

          <Box
            sx={[
              {
                ...STYLES.choicesScrollContainer,
              },
              (theme) => ({
                [theme.breakpoints.between("sm", "md")]: {
                  width: "100%",
                },
                [theme.breakpoints.down("xl")]: {
                  width: "100%",
                },
              })
            ]}
          >
            <Box
              sx={{
                ...STYLES.choicesContainer,
                marginBottom: "-1rem",
              }}
            >
              {industries.map((industry: string, index: number) => (
                <Box
                  key={index}
                  sx={{
                    ...STYLES.buttonChoice,
                    ...(selectedIndustries?.includes(industry) && {
                      ...STYLES.buttonChoiceSelected,
                    }),
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    updateSelectedIndustries(industry);
                  }}
                >
                  {industry}
                </Box>
              ))}
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
            <SharedButton
              type="submit"
              variant="contained"
              sx={{
                mx: "auto",
                mt: "20px",
                marginLeft: 0,
                marginRight: 0,
                }}
            >
                <Box
                  sx={[
                    {
                    marginLeft: "6px",
                    textTransform: "initial",
                    mx: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    },
                    (theme) => ({
                    [theme.breakpoints.between("sm", "md")]: {
                        width: "100%",
                    },
                    [theme.breakpoints.down("xl")]: {
                        width: "600px",
                    },
                    })
                ]}
                >
                {isUpdatingCompany === "pending" ? (
                  <>
                    <CircularProgress
                      sx={{ marginRight: "10px" }}
                      color="inherit"
                      size="20px"
                    />
                    <span>Loading...</span>
                  </>
                ) : (
                  "Continue"
                )}
                </Box>
            </SharedButton>
          </Box>
        </ContentForm>
      </DialogContent>
    </DialogContainer>
  );
};

export default IndustryDialog;
