import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { IUserState } from "./interface";
import { doActionFetchUserAction } from "./user.actions";
import { ReduxStatus } from "types/types";
import { initialUser } from "constants/constants";

const initialState: IUserState = {
  status: ReduxStatus.empty,
  response: initialUser,
  error: {},
};

export const user = createSlice({
  name: "users/fetch",
  initialState,
  reducers: {
    resetUsers: () => initialState,
    signOut: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(doActionFetchUserAction.pending, (state: IUserState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doActionFetchUserAction.fulfilled,
        (state: IUserState, action: AnyAction) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doActionFetchUserAction.rejected,
        (state: IUserState, action: AnyAction) => {
          state.status = ReduxStatus.rejected;
          state.response = initialUser;
          state.error = action.error;
        }
      );
  },
});

export const { resetUsers } = user.actions;
export default user.reducer;
