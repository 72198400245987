import React, { FC, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SharedButton from "components/shared/button/button";
import { RootState, useAppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import Plus from "../../../assets/images/Union.svg";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { auth } from "utils/firebase";
import storage from "redux-persist/lib/storage";
import { PURGE } from "redux-persist";
import { useLocation, useNavigate } from "react-router-dom";

import { doCreateJobAction } from "redux/services/job/create/create.actions";
import { ROUTE_NEW_JOB } from "routes/route-items";

interface IProps {
  drawerWidth: number;
  menu?: string;
}

interface IState {
  email: string;
  firstName: string;
  lastName: string;
  original_avatar_url: string;
  thumbnail_avatar_url: string;
}

const TopMenu: FC<IProps> = ({ drawerWidth, menu }) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [user, setUser] = React.useState<IState>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignout = async () => {
    const user_auth = auth.getAuth();
    await window.localStorage.clear();
    await storage.removeItem("persist:root");
    await auth.signOut(user_auth).then(async () => {
      dispatch({
        type: PURGE,
        key: "persist:root",
        result: () => null,
      });
    });
    window.location.reload();
  };

  function stringAvatar(name: string) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  useEffect(() => {
    const user_data = {
      ...userState,
    };

    setUser(user_data);
  }, [userState]);

  const handleCreateNewJob = async () => {
    await dispatch(
      doCreateJobAction({
        job: {
          seniority_level: "",
          job_function: "",
          job_description_link: "",
          job_description: "",
          company_id: "",
          company_name: "",
          created_by: "",
          skills_nice_to_have: {},
          skills_not_to_have: {},
          skills_must_have: {},
          job_exciting_things: {},
          selected_currency: {
            label: "",
            value: "",
          },
          recruiter_notes: "",
        },
      })
    );
    const pathFrom = location.pathname.replace("/", "");
    navigate(`/${ROUTE_NEW_JOB}`, { state: { from: pathFrom } });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth + 1}px)`,
        ml: `${drawerWidth}px`,
        backgroundColor: (theme) => theme.palette.grey[50],
        minHeight: "96px",
        boxShadow: "none",
        justifyContent: "center",
        transition: "width 0.6s cubic-bezier(0.16, 1, 0.3, 1)",
      }}
    >
      <Toolbar>
        <Grid container spacing={2} columns={12} sx={{ alignItems: "center" }}>
          <Grid item xs={7}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                fontFamily: "Inter",
                color: (theme) => theme.palette.grey[500],
                paddingLeft: "40px",
                fontSize: "38px",
                fontWeight: "600",
                lineHeight: "1.25",
              }}
            >
              {menu || "Connect"} {/*Make component reusable*/}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <SharedButton
              onClick={handleCreateNewJob}
              sx={{ width: "auto" }}
              variant="contained"
            >
              <img src={Plus} alt="Add" />
              <Box sx={{ marginLeft: "30px", textTransform: "initial" }}>
                New Job
              </Box>
            </SharedButton>
            {/* comment the bell icon out instead of deleting as it may be used later */}
            {/* <Badge
              color="error"
              variant="dot"
              sx={{
                height: "25px",
                "& .MuiBadge-dot": {
                  top: "9px",
                  right: "8px",
                },
              }}
            >
              <NotificationsIcon
                sx={{
                  height: "25px",
                  width: "25px",
                }}
              />
            </Badge> */}

            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                sx={{
                  width: 50,
                  height: 50,
                  marginLeft: "30px",
                  marginRight: "30px",
                  // background: imageLoading ? theme.palette.grey[300] : null,
                }}
                alt={`${user?.firstName} ${user?.lastName}`}
                src={
                  userState.original_avatar_url
                    ? userState.original_avatar_url
                    : ""
                }
                {...stringAvatar(
                  `${userState.firstName} ${userState.lastName}`
                )}
              />
            </IconButton>

            <Menu
              sx={{
                mt: "53px",
                "& .MuiPaper-root": {
                  padding: "8px",
                  marginTop: "0px",
                  backgroundColor: "#FCFCFC",
                  backdropFilter: "blur(32px)",
                  borderRadius: "12px",
                },
                "& .MuiList-padding": {
                  padding: 0,
                },
                "& .MuiMenuItem-root": {
                  borderRadius: "4px",
                  ":hover": {
                    background: "#EFEFEF",
                    color: "#000000",
                  },
                },
                width: "auto",
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={handleSignout}
                sx={{ paddingTop: 0, paddingBottom: 0, lineHeight: 30 }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    lineHeight: "40px",
                    fontWeight: "600",
                    fontFamily: "inter",
                    fontSize: "15px",
                    letterSpacing: "-0.01em",
                  }}
                >
                  Sign Out
                </Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;
