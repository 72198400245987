import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { IJob, IJobStatus, TJobStatusOption } from "types/types";

export const updateJobs = ({
  updatedJob,
  jobList,
}: {
  updatedJob: IJob;
  jobList: IJob[];
}) => {
  const select = createDraftSafeSelector(() => {
    const list = jobList.map((job) => {
      if (job.job_id === updatedJob.job_id) {
        return updatedJob;
      }
      return job;
    });

    return list;
  });
  return select(jobList);
};

export const deleteJob = ({
  deletedJob,
  jobList,
}: {
  deletedJob: IJob;
  jobList: IJob[];
}) => {
  const select = createDraftSafeSelector(() => {
    const list = jobList.map((job) => job.job_id !== deletedJob.job_id);
    return list;
  });
  return select(jobList);
};

export const restructureJobs = ({
  jobState,
  jobStatus,
}: {
  jobState: IJob[];
  jobStatus: TJobStatusOption | undefined;
}) => {
  const select = createDraftSafeSelector(
    (state: IJob[]) => state,
    (items) => {
      const activeList: IJob[] = [];
      let archivedList: IJob[] = [];
      let restructuredJobs = items;

      items.map((job: IJob) => {
        if (job.status === IJobStatus.ACTIVE || !job.status) {
          activeList.push(job);
        } else {
          archivedList.push(job);
        }
      });

      activeList.sort((a, b) => {
        const A = new Date(a.created_at);
        const B = new Date(b.created_at);
        return A > B ? -1 : A < B ? 1 : 0;
      });

      archivedList.sort((a, b) => {
        const A = new Date(a.updated_at);
        const B = new Date(b.updated_at);
        return A > B ? -1 : A < B ? 1 : 0;
      });

      if (jobStatus === "ALL_JOBS") {
        restructuredJobs = activeList.concat(archivedList.reverse());
      } else if (jobStatus === IJobStatus.ARCHIVE) {
        restructuredJobs = archivedList;
      } else {
        restructuredJobs = activeList;
      }

      return restructuredJobs;
    }
  );
  return select(jobState);
};
