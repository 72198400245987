import * as yup from "yup";

const urlRegex =
  /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%/&=?.]+\.[a-z]{2,4}\/?([^\s<>#%",{}\\|\\^[\]`]+)?)/gi;

export const EmailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .matches(
      // eslint-disable-next-line
      /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,8})?$/,
      "Please enter a valid work email"
    )
    .required("Email is required"),
});

export const SignInSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const ForgotPassSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const InitialSignUpSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .matches(
      // eslint-disable-next-line
      /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,8})?$/,
      "Please enter a valid work email"
    )
    .required("Email is required"),
});

export const SignUpSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .matches(
      // eslint-disable-next-line
      /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,8})?$/,
      "Please use a company email address"
    )
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(/(?=.{5,})/, {
      message: "Password must be five characters or longer",
      excludeEmptyString: false,
    }),
  terms: yup.bool().oneOf([true], "You must accept the terms and conditions"),
});

export const CompanySchema = yup.object().shape({
  companyName: yup.string().required("Company name required"),
  companyLocation: yup.string(),
});

export const IndustrySchema = yup.object().shape({
  modelAndIndustry: yup.string(),
});

export const JobDescriptionSchema = yup.object().shape({
  jobTitle: yup.string().required("Enter a valid job title"),
  customJobFunction: yup.string().required("Enter valid job function"),
  jobDescriptionLink: yup.string().matches(urlRegex, {
    message: "Enter a valid URL (http://www.job_description.com)",
    excludeEmptyString: true,
  }),
});

export const JobPitchSchema = yup.object({
  "0_job_exciting_things": yup.string().required("Provide a selling point"),
});

export const profileSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .matches(
      // eslint-disable-next-line
      /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,8})?$/,
      "Please enter a valid work email"
    )
    .required("Email is required"),
  oldPassword: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v)),
  newPassword: yup
    .string()
    .required("New Password is required")
    .when("oldPassword", (oldPassword) =>
      oldPassword
        ? yup
            .string()
            .matches(/(?=.{5,})/, "Password must be five characters or longer")
        : yup.string()
    ),
  confirmNewPassword: yup
    .string()
    .nullable()
    .oneOf([yup.ref("newPassword")], "Passwords do not match"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
});
export const companySchema = yup.object({
  billingAddress: yup.string().required("Billing address is required"),
  // inviteToCollaborate: yup.string().required("Confirm password is required"),
  billingEmailAddress: yup
    .string()
    .email("Invalid email address")
    .matches(
      // eslint-disable-next-line
      /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,8})?$/,
      "Please enter a valid work email"
    )
    .required("Billing email address is required"),
  companyName: yup.string().required("Company name is required"),
  locationHq: yup.string().required("Location HQ is required"),
  VATID: yup.string().required("VAT ID is required"),
  remote: yup.string().required("Work format is required"),
});

export const JobCandidateProfileSchema = yup.object({
  "0_skills_must_have": yup.string().required("Provide a skill"),
});
