import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { auth as firebaseAuth, db, doc, setDoc } from "utils/firebase";
import { IAuthType, IRoleType, IUser } from "types/types";
import { ISignUpPayload } from "./interface";
import { serverTimestamp } from "firebase/firestore";
import { emailRegex, initialUser } from "constants/constants";
import { customError } from "utils/helpers";

const _signUpWithEmailAndPassword = async (payload: ISignUpPayload) => {
  if (!payload.password || !payload.email) {
    throw customError({
      code: "auth/missing-params",
      message: "Missing password or email",
      name: "Authentication Error",
    });
  }

  if (!payload.firstName || !payload.lastName) {
    throw customError({
      code: "user/missing-firstname-or-lastname",
      message: "Missing first name or last name",
      name: "Authentication Error",
    });
  }

  const auth = firebaseAuth.getAuth();

  const userCredential = await firebaseAuth.createUserWithEmailAndPassword(
    auth,
    payload.email,
    payload.password
  );

  if (!userCredential.user) {
    throw customError({
      code: "auth/account-creation-failure",
      message: "Unable to create account",
      name: "Authentication Error",
    });
  }

  const currentUser = auth.currentUser as firebaseAuth.User;
  await firebaseAuth.updateProfile(currentUser, {
    displayName: `${payload.firstName} ${payload.lastName}`,
  });

  const firstName = payload.firstName;
  const lastName = payload.lastName;
  const email = payload.email;
  const uid = userCredential.user.uid;
  const role = IRoleType.CLIENT;
  const accepted_terms = payload.accepted_terms || false;
  const next_onboarding_stage = payload.next_onboarding_stage || "your-company";
  const company_id = payload.company_id || "";
  const company_name = payload.company_name || "";
  const has_posted_jobs = false;
  const create_job_prompt = true;

  const client: IUser = {
    ...initialUser,
    firstName,
    lastName,
    email,
    accepted_terms,
    uid,
    role,
    company_id,
    company_name,
    next_onboarding_stage,
    has_posted_jobs,
    create_job_prompt,
    notifications: {
      email: {
        new_candidate: true,
      },
    },
  };

  const clientRef = doc(db, `v2_clients/${uid}`);
  await setDoc(
    clientRef,
    { ...client, created_at: serverTimestamp() },
    { merge: true }
  );

  return client;
};

const _signUpWithGoogle = async () => {
  const GoogleAuthProvider = firebaseAuth.GoogleAuthProvider;
  const provider = new GoogleAuthProvider();
  const auth = firebaseAuth.getAuth();
  const userCredential = await firebaseAuth.signInWithPopup(auth, provider);

  if (!userCredential.user) {
    throw customError({
      code: "auth/account-creation-failure",
      message: "Unable to create account",
      name: "Authentication Error",
    });
  }

  const email = userCredential.user.email as string;
  const isWorkEmail = emailRegex.test(email);

  if (!isWorkEmail) {
    await firebaseAuth.deleteUser(userCredential.user);

    throw customError({
      code: "auth/not-work-email",
      message: "Please provide a valid work email",
      name: "Authentication Error",
    });
  }

  const firstName = userCredential.user.displayName?.split(" ")[0] || "";
  const lastName = userCredential.user.displayName?.split(" ")[1] || "";
  const uid = userCredential.user.uid;
  const next_onboarding_stage = "your-company";
  const role = IRoleType.CLIENT;
  const has_posted_jobs = false;
  const create_job_prompt = true;

  const client: IUser = {
    ...initialUser,
    firstName,
    lastName,
    email,
    accepted_terms: true,
    uid,
    role,
    next_onboarding_stage,
    company_id: "",
    has_posted_jobs,
    create_job_prompt,
    notifications: {
      email: {
        new_candidate: true,
      },
    },
  };

  const clientRef = doc(db, `v2_clients/${uid}`);
  await setDoc(
    clientRef,
    { ...client, created_at: serverTimestamp() },
    { merge: true }
  );

  return client;
};

export const doSignUpAction: AsyncThunk<IUser, ISignUpPayload, {}> =
  createAsyncThunk("users/signup", async (payload) => {
    if (payload.type === IAuthType.EMAIL_PASS) {
      const user = await _signUpWithEmailAndPassword(payload);
      return user;
    }

    const user = await _signUpWithGoogle();
    return user;
  });
