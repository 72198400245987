/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import SharedButton from "components/shared/button/button";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import SelectComponent from "../shared/select/select-component";
import Box from "@mui/material/Box";
import CustomStyles from "./styles";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { _get } from "utils/lodash";
import { doGetATSJobsAction } from "redux/services/ats/get-ats-jobs/get.actions";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";

interface IProps {
  openDialog: boolean;
  isLoading: boolean;
  handleAcceptDialog: (e: { [key: string]: string }) => void;
  handleCloseDialog: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const DialogContainer = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    min-width: 35rem;
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.grey[50]};
  }
  & .MuiDialogTitle-root {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 500;
    font-size: 22px;
    font-family: Inter;
    padding: 0px 24px;
    padding-top: 10px;
    padding-left: 12px;
  }
  & .MuiDialogContentText-root {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 400;
    font-size: 16px;
    font-family: Inter;
  }
  & .MuiLoadingButton-text {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 400;
    font-size: 16px;
    font-family: Inter;
  }
`;

const DisButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 400;
  font-size: 16px;
  font-family: Inter;
`;

interface IState {
  companies: { [key: string]: string }[];
  selected: { [key: string]: string };
}

const initialState: IState = {
  companies: [],
  selected: {},
};

const ATSJobs: React.FC<IProps> = ({
  openDialog,
  handleCloseDialog,
  handleAcceptDialog,
  isLoading,
}) => {
  const [state, setState] = React.useState<IState>(initialState);
  const [isLoadingATSJobs, setIsLoadingATSJobs] =
    React.useState<boolean>(false);
  const atsData = useSelector((reduxState: RootState) => reduxState.getATSJobs);
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const atsList = _get(atsData, "response.message.response.results") || [];
  const connected_ats_jobs =
    _get(atsData, "response.message.connected_ats_jobs") || "";
  const dispatch = useAppDispatch();

  const fetchATSJobs = React.useCallback(async () => {
    if (openDialog) {
      setIsLoadingATSJobs(true);

      if (userState.company_id) {
        await dispatch(
          doGetATSJobsAction({
            company_id: userState.company_id,
          })
        );
      } else {
        toast(
          <ToastMessage title="ATS Jobs" body="Unable to fetch ATS Jobs" />,
          {
            type: "warning",
            position: "top-right",
          }
        );
      }
      setIsLoadingATSJobs(false);
    }
  }, [openDialog]);

  React.useEffect(() => {
    if (atsData.status !== "pending") {
      fetchATSJobs();
    }
  }, [fetchATSJobs]);

  const format_jobs = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let resp: any[] = [];
    if (atsList.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      atsList.map((el: { [key: string]: any }) => {
        resp.push({
          ...el,
          value: el.name,
          label: connected_ats_jobs.includes(el.id)
            ? `${el.name} - Job already linked`
            : el.name,
          isDisabled: connected_ats_jobs.includes(el.id),
        });
      });
    }
    return resp;
  }, [atsList]);

  const filterATSJobs = (inputValue: string) => {
    let list = format_jobs;
    if (Array.isArray(list)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return list.filter((i: { [key: string]: string | any }) => {
        return i?.label?.toLowerCase().includes(inputValue.toLowerCase());
      });
    } else {
      return [];
    }
  };

  const promiseOptions = async (inputValue: string) => {
    return new Promise<{ [key: string]: string }[]>((resolve) => {
      setTimeout(() => {
        resolve(filterATSJobs(inputValue));
      }, 1000);
    });
  };

  const handleChangeSelect = async (options: any) => {
    setState((st: IState) => ({
      ...st,
      selected: {
        ats_jobTitle: options.name,
        ats_jobId: options.id,
        ats_jobStatus: options.status,
      },
    }));
  };

  const handleSelectCompany = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleAcceptDialog(state.selected);
  };

  return (
    <DialogContainer
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            padding: 0,
          }}
        >
          <span>Connect job to ATS</span>
        </DialogTitle>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 5,
              top: 20,
            }}
          >
            <CloseIcon htmlColor="#000000" />
          </IconButton>
        </DialogTitle>
      </DialogTitle>
      <DialogContent sx={{ minHeight: "27rem", overflowY: "hidden" }}>
        <DialogContentText id="alert-dialog-description">
          Choose an ATS job that corresponds to the selected Connect job. Then
          the candidates added in Connect will appear also in your ATS.
        </DialogContentText>
        <Box sx={{ marginTop: ".5rem" }}>
          <SelectComponent
            menuIsOpen
            closeMenuOnSelect={false}
            cacheOptions
            defaultOptions
            styles={CustomStyles}
            handleChange={handleChangeSelect}
            options={isLoadingATSJobs ? [] : format_jobs}
            placeholder={"Type to search..."}
            isLoading={isLoadingATSJobs}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {Object.keys(state.selected).length ? (
          <SharedButton
            variant="contained"
            onClick={handleSelectCompany}
            autoFocus
            sx={{ marginBottom: "1rem", marginRight: "1rem", width: "auto" }}
          >
            {isLoading ? (
              <CircularProgress
                sx={{ marginRight: "10px" }}
                color="inherit"
                size="20px"
              />
            ) : (
              `Link to ATS job`
            )}
          </SharedButton>
        ) : null}
      </DialogActions>
    </DialogContainer>
  );
};

export default ATSJobs;
