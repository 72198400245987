import React, { useState, useEffect, useCallback } from "react";
// import React, { useState, useEffect } from "react";
import Drawer from "layouts/drawer";
import Card from "components/shared/card";
import { styled } from "@mui/material/styles";
import Slack from "assets/images/slack.svg";
import ATS from "assets/images/ats.svg";
import { clientMail } from "constants/constants";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { toast } from "react-toastify";
import { IRoleType } from "types/types";
import { _get } from "utils/lodash";
import { useMergeLink } from "@mergeapi/react-merge-link";

import ToastMessage from "components/toast-message";

import { doGetLinkTokenAction } from "redux/services/ats/link-token/get.actions";
import { doRemoveSlackUserAction } from "redux/services/manageSlackNotificationUsers/manageSlackNotification.actions";
import { refetchLinkToken } from "utils/helpers";
import AlertDialog from "components/dialog";
import { doUpdateCompanyAction } from "redux/services/company/update/update-company.actions";
import * as axios from "api/axios";
const axiosInstance = axios.default;
import { config } from "config";

const initialState = {
  user: {} as any,
};

const Integrations = () => {
  const Container = styled("div")`
    display: flex;
    flex-wrap: wrap;
    background: ${({ theme }) => theme.palette.grey[100]};
    padding: 40px;
    margin-top: 40px;
    border-radius: 8px;
    align-items: center;
  `;

  const [state, setState] = useState(initialState);
  const [slackNotificationsOff, setSlackNotificationsOff] = useState(false);
  const [ATSSwitch, setATSswitch] = useState(false);
  const [slackNotificationsOn, setSlackNotificationsOn] = useState(true);
  const [offSwitchSlack, setOffSwitchSlack] = useState(false);
  const [onSwitchSlack, setOnSwitchSlack] = useState(true);
  const [alertDialogOpen, setAlertDialog] = useState(false);
  const [isUnLinkLoading, setUnlinkLoading] = useState(false);

  const dispatch = useAppDispatch();

  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const atsData = useSelector((reduxState: RootState) => reduxState.linkToken);
  const companyState = useSelector(
    (reduxState: RootState) => reduxState.getCompany.response
  );

  React.useEffect(() => {
    const hasAccountToken = companyState?.ats_data?.account_token?.account_token
      ? true
      : false;
    if (hasAccountToken) {
      setATSswitch(true);
    }
  }, [companyState?.ats_data]);

  useEffect(() => {
    const url_query = new URLSearchParams(window.location.search);
    const success_token = url_query.get("slack_success");
    if (success_token) {
      toast(
        <ToastMessage
          title="Slack Integration"
          body="Slack Integration successful!"
        />,
        { type: "success", position: "top-right" }
      );
    }
  }, []);

  useEffect(() => {
    const user_data = {
      ...userState,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setState((st: any) => ({
      ...st,
      user: user_data,
    }));
    setOffSwitchSlack(userState.integrations?.Slack?.selected);
    setOnSwitchSlack(userState.integrations?.Slack?.selected);
  }, [userState]);

  const handleSlackOnSettings = useCallback(async () => {
    if (slackNotificationsOn === false) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await dispatch(
        doRemoveSlackUserAction({
          email: userState.email,
          uid: userState.uid,
          slack_access_token: userState.slack_access_token,
          slack_user_access_token: userState.slack_user_access_token,
          role: IRoleType.CLIENT,
        })
      );

      if (response.payload.data.success) {
        toast(
          <ToastMessage
            title="Slack Integration"
            body="Unlinking Slack was successful!"
          />,
          { type: "success", position: "top-right" }
        );
      }
    }
  }, [slackNotificationsOn]);

  const handleSlackOffSettings = useCallback(async () => {
    if (slackNotificationsOff === true) {
      window.open(
        `https://slack.com/oauth/v2/authorize?client_id=3417414999671.3444660245457&scope=chat:write,chat:write.customize,users:read&user_scope=chat:write&redirect_uri=${
          config.REACT_APP_SLACK_REDIRECT_URI
        }/&state=${state.user.email}~${state.user.company_id}~${
          state.user?.firstName
        }~${
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname
        }~${state.user?.uid}
  		`,
        "_blank"
      );
    }
  }, [slackNotificationsOff]);

  const handleSlackOffNotifications = () => {
    setSlackNotificationsOff(!slackNotificationsOff);
  };

  const handleSlackOnNotifications = () => {
    setSlackNotificationsOn(!slackNotificationsOn);
  };

  const onSuccess = useCallback(async (public_token: string) => {
    setUnlinkLoading(true);
    await axiosInstance.get("/ats-account-token", {
      params: {
        company_id: userState.company_id,
        user_email: userState.email,
        public_token,
      },
    });
    await dispatch(
      doGetLinkTokenAction({
        company_id: userState.company_id,
        user_email: userState.email,
        _link_token: {},
      })
    );
    setUnlinkLoading(false);
    setATSswitch(true);
  }, []);

  const linkedAccount = _get(atsData, "response.message") || "";
  // eslint-disable-next-line no-unused-vars
  const { open, isReady } = useMergeLink({
    linkToken: linkedAccount.link_token,
    onSuccess,
  });

  const handleATS = async () => {
    if (!ATSSwitch) {
      const is_tokenExpired = linkedAccount.created_at
        ? refetchLinkToken(new Date(linkedAccount.created_at?._seconds * 1000))
        : true;

      if (linkedAccount.link_token && !is_tokenExpired) {
        open();
      } else {
        setUnlinkLoading(true);
        const resp = await dispatch(
          doGetLinkTokenAction({
            company_id: userState.company_id,
            user_email: userState.email,
          })
        );

        const _resp = _get(resp, "payload.message") || {};
        if (_resp.success) {
          open();
        } else {
          setUnlinkLoading(false);
          toast(
            <ToastMessage
              title="Link ATS"
              body={_resp.message || "Error occurred linking to ATS "}
            />,
            { type: "error", position: "top-right" }
          );
        }
      }
    } else {
      setAlertDialog(true);
    }
  };

  const handleUlinkATS = async () => {
    if (!userState.company_id) {
      toast(<ToastMessage title="Status" body="Missing company id" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }
    setUnlinkLoading(true);
    await dispatch(
      doUpdateCompanyAction({
        company_id: userState.company_id,
        company: {
          ats_data: null,
        },
      })
    );
    await dispatch(
      doGetLinkTokenAction({
        company_id: userState.company_id,
        user_email: userState.email,
        _link_token: {},
      })
    );
    setATSswitch(false);
    setAlertDialog(false);
    setUnlinkLoading(false);
  };

  useEffect(() => {
    handleSlackOnSettings();
  }, [handleSlackOnSettings]);

  useEffect(() => {
    handleSlackOffSettings();
  }, [handleSlackOffSettings]);

  const mailTo = `mailto:${clientMail}?subject=Connect-Integrations`;

  return (
    <>
      <Drawer menu="Integrations">
        <Container>
          {!offSwitchSlack && (
            <Card
              title="Slack"
              switch
              icon={Slack}
              switchState={slackNotificationsOff}
              switchOnchange={handleSlackOffNotifications}
            >
              Integrate with your Slack, so that you can be notified when new
              candidates are added to your jobs.
            </Card>
          )}
          {onSwitchSlack && (
            <Card
              title="Slack"
              switch
              icon={Slack}
              switchState={slackNotificationsOn}
              switchOnchange={handleSlackOnNotifications}
            >
              Integrate with your Slack, so that you can be notified when new
              candidates are added to your jobs.
            </Card>
          )}
          <>
            <Card
              title="ATS"
              switch
              icon={ATS}
              switchState={ATSSwitch}
              switchLoading={isUnLinkLoading}
              switchOnchange={handleATS}
            >
              Choose your ATS from 35 popular market options, so that candidate
              information gets seamlessly transferred.
            </Card>
          </>
          <Card
            title="Need more integrations?"
            action="Submit a suggestion"
            sx={{
              backgroundColor: (theme: { palette: { grey: never[] } }) =>
                theme.palette.grey[700],
            }}
            onClickHandler={() => window.open(mailTo, "_self")}
          >
            What integrations would help you the most? Let our team know.
          </Card>
        </Container>
      </Drawer>
      <AlertDialog
        openDialog={alertDialogOpen}
        isLoading={isUnLinkLoading}
        handleAcceptDialog={handleUlinkATS}
        handleCloseDialog={() => setAlertDialog(false)}
        body={`Are you sure you want to turn off ATS?`}
      />
    </>
  );
};

export default Integrations;
