import React, { FC, MouseEventHandler, ReactNode } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActions } from "@mui/material";
import Switch from "components/shared/switch";
import { styled } from "@mui/material/styles";
import SharedButton from "components/shared/button/button";
import Box from "@mui/material/Box";
import Text from "components/shared/text/text";
import Placeholder from "assets/images/placeholder.svg";
// import { auth, db } from "utils/firebase";
// import { collection_name } from "utils/firebase-refs";
// import { doc, updateDoc, getDoc } from "firebase/firestore";
import { _get } from "utils/lodash";
import Rolling from "assets/images/rolling.svg";

interface IProps {
  title?: string;
  children: ReactNode;
  description?: string;
  switch?: Boolean;
  action?: string;
  icon?: string;
  sx?: {};
  onClickHandler?: MouseEventHandler;
  switchOnchange?: () => void;
  switchState?: boolean;
  switchLoading?: boolean;
}

const MultiActionAreaCard: FC<IProps> = (props) => {
  const Logo = styled("div")`
    background-image: url(${props.icon || Placeholder}); // To remove error
    background-repeat: no-repeat;
    background-size: cover;
    height: 40px;
    width: 40px;
    margin-right: 10px;
  `;

  const CardTitle = styled("div")`
    display: flex;
    align-items: center;
  `;

  // const [user, setUser] = useState("");
  // const [integrations, setIntegrations] = useState({});

  // Is there an easier way to get value from store ?
  //   const getUser = () =>
  //     new Promise((resolve) => {
  //       const _user = auth.getAuth();
  //       auth.onAuthStateChanged(_user, (value) => {
  //         resolve(value?.uid);
  //       });
  //     });

  //   const getSwitchData = async (user: unknown) => {
  //     const docRef = doc(db, `${collection_name.v2_clients}/${user}`);
  //     const snapshot = await getDoc(docRef);
  //     if (snapshot.exists()) {
  //       const { integrations } = snapshot.data();
  //       setIntegrations(integrations);
  //     }
  //   };

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       const user = await getUser();
  //       // @ts-ignore
  //       setUser(user);
  //       await getSwitchData(user);
  //     };
  //     fetchData();
  //   }, []);

  //   const updateSwitch = async (value: {
  //     selected: boolean;
  //     integration: string;
  //   }) => {
  //     setIntegrations((prevState) => {
  //       return {
  //         ...prevState,
  //         [value.integration]: { selected: value.selected },
  //       };
  //     });
  //     const docRef = doc(db, `${collection_name.v2_clients}/${user}`);
  //     const path = `integrations.${value.integration}`;
  //     return await updateDoc(docRef, {
  //       [path]: { selected: value.selected },
  //     });
  //   };

  return (
    <Card
      sx={{
        maxWidth: 345,
        cursor: "pointer",
        height: 200,
        boxShadow: "none",
        backgroundColor: (theme) => theme.palette.background.default,
        border: (theme) => `3px solid ${theme.palette.grey[800]}`,
        borderRadius: "8px",
        margin: "16px",
        "&:hover": {
          border: (theme) =>
            `3px solid ${_get(theme, "palette.colors.light_grey")}`,
        },
        "&:focus": {
          border: (theme) =>
            `3px solid ${_get(theme, "palette.colors.light_grey")}`,
        },
        ...props.sx,
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          display="flex"
          color="grey.600"
          justifyContent="space-between"
        >
          <CardTitle>
            {props.icon && <Logo />}
            <Text sx={{ fontSize: "20px", marginLeft: 0 }}>{props.title}</Text>
          </CardTitle>
          {/* {props.title === "ATS" && (
            <Text
              sx={{
                textAlign: "left",
                marginLeft: 0,
                color: (theme) => theme.palette.grey[400],
              }}
              fontWeight={400}
              fontSize="14"
            >
              Coming soon
            </Text>
          )} */}
          {props.switch &&
            (props.title === "ATS" && props.switchLoading ? (
              <img
                src={Rolling}
                alt="Loader"
                style={{
                  height: "35px",
                  width: "35px",
                  marginRight: "0.4rem",
                }}
              />
            ) : (
              <Switch
                // checked={_get(integrations, `${props.title}.selected`, false)}
                //   onChange={(e) =>
                //     updateSwitch({
                //       integration: props.title || "",
                //       selected: e.target.checked,
                //     })
                //   }
                onChange={() => props.switchOnchange && props.switchOnchange()}
                checked={props.switchState}
              />
            ))}
        </Typography>
        <Typography component="div" color="text.secondary">
          <Text
            sx={{
              textAlign: "left",
              marginLeft: 0,
              color: (theme) => theme.palette.grey[400],
            }}
            fontWeight={400}
            fontSize="14"
          >
            {props.children}
          </Text>
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 0,
        }}
      >
        {props.action && (
          <SharedButton
            sx={{
              width: "auto",
              marginRight: "30px",
              background: "white",
              border: "black",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
                border: "none",
              },
            }}
            onClick={(e) => props.onClickHandler && props.onClickHandler(e)}
            variant="contained"
          >
            <Box
              sx={{
                textTransform: "initial",
                color: "black",
                fontFamily: "Inter",
              }}
            >
              {props.action}
            </Box>
          </SharedButton>
        )}
      </CardActions>
    </Card>
  );
};

export default MultiActionAreaCard;
