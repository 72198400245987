import { yupResolver } from "@hookform/resolvers/yup";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Google } from "assets/images/google.svg";
import { ReactComponent as Warning } from "assets/images/warning.svg";
import AuthForm from "components/auth-form/auth-form";
import SharedButton from "components/shared/button/button";
import Form from "components/shared/form/form";
import HR from "components/shared/horizontal-rule";
import Input from "components/shared/input/input";
import Text from "components/shared/text/text";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { ReactComponent as LockIcon } from "assets/images/lock.svg";
import { SignInSchema } from "utils/form-schema";
import { useSelector } from "react-redux";
import { doSignInAction } from "redux/services/signin/signin.actions";
import { RootState, useAppDispatch } from "redux/store";
import { IAuthType, IUser } from "types/types";
import { doActionFetchUserAction } from "redux/services/user/user.actions";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_ACTIVE_JOBS,
  ROUTE_SIGN_UP,
  ROUTE_FORGOT_PASSWORD,
} from "routes/route-items";
import { _get } from "utils/lodash";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import { SerializedError } from "@reduxjs/toolkit";

interface IProps {}
interface IState {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
}
interface IEState {
  email: string;
  company_name: string;
  company_id: string;
  isLoading: boolean;
}

const initialState: IEState = {
  email: "",
  company_name: "",
  company_id: "",
  isLoading: false,
};

const SignIn: React.FC<IProps> = () => {
  const [state, setState] = useState(initialState);
  const theme = useTheme();
  // eslint-disable-next-line no-empty-pattern
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reduxState = useSelector(
    (reduxState: RootState) => reduxState.signin || {}
  );

  const _errors: {
    [key: string]: string;
  } = {
    "auth/wrong-password": "Wrong password",
    "auth/user-not-found": "Email does not exist",
  };

  const [error, setError] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IState>({
    resolver: yupResolver(SignInSchema),
  });

  React.useEffect(() => {
    if (location?.search?.includes("invitationCode")) {
      navigate(
        { search: location.search, pathname: "/sign-up" },
        { state: null }
      );
    }
  }, [location]);

  const onSubmit: SubmitHandler<IState> = async (data: IState) => {
    setState((st: IEState) => ({ ...st, isLoading: true }));

    const signInResponse = await dispatch(
      doSignInAction({
        password: data.password as string,
        email: data.email,
        type: IAuthType.EMAIL_PASS,
      })
    );

    if (signInResponse.meta.requestStatus === "rejected") {
      setState((st: IEState) => ({ ...st, isLoading: false }));
      const serializedError = (signInResponse as any).error as SerializedError;
      const code = serializedError.code as string;

      const authError = _errors[code];
      if (authError) {
        return setError({ [code]: _errors[code] });
      }

      toast.error(
        <ToastMessage
          title="Sign in error"
          body={
            code === "user/recruiter"
              ? "Use the Recruiter page"
              : code === "auth/no-linked-data"
              ? "Missing Data. Contact ACELR8 to fix the issue"
              : code === "auth/wrong-password"
              ? "Invalid Password"
              : code === "auth/user-not-found"
              ? "User does not exist"
              : code === "auth/missing-params"
              ? "Provied both email and password"
              : "Something went wrong. Try again"
          }
        />,
        { type: "error", position: "top-right" }
      );
      return;
    }
    const user = signInResponse.payload as IUser;

    await dispatch(
      doActionFetchUserAction({
        uid: user.uid,
        user: {
          ...user,
          next_onboarding_stage: ROUTE_ACTIVE_JOBS,
        },
      })
    );
    navigate(`/${ROUTE_ACTIVE_JOBS}`, { state: null });
  };

  const handleSignInWithGoogle = async () => {
    const signInResponse = await dispatch(
      doSignInAction({
        type: IAuthType.GOOGLE_AUTH,
      })
    );

    if (signInResponse.meta.requestStatus === "rejected") {
      setState((st: IEState) => ({ ...st, isLoading: false }));
      const serializedError = (signInResponse as any).error as SerializedError;
      toast.error(
        <ToastMessage
          title="Sign in error"
          body={
            serializedError.code === "user/recruiter"
              ? "Use the Recruiter page"
              : serializedError.code === "auth/no-linked-data"
              ? "Missing Data. Contact ACELR8 to fix the issue"
              : serializedError.code === "auth/wrong-password"
              ? "Invalid Password"
              : serializedError.code === "auth/user-not-found"
              ? "User does not exist"
              : serializedError.code === "auth/missing-params"
              ? "Provied both email and password"
              : serializedError.code === "auth/not-work-email"
              ? serializedError.message
              : serializedError.code === "auth/account-creation-failure"
              ? serializedError.message
              : "Something went wrong. Try again"
          }
        />,
        { type: "error", position: "top-right" }
      );
      return;
    }

    const user = signInResponse.payload as IUser;

    await dispatch(
      doActionFetchUserAction({
        uid: user.uid,
        user: {
          ...user,
          next_onboarding_stage: ROUTE_ACTIVE_JOBS,
        },
      })
    );
    setState((st: IEState) => ({ ...st, isLoading: false }));
  };

  const handleNavToSignup = () => {
    navigate(`/${ROUTE_SIGN_UP}`);
  };

  const renderErrors = {
    email: _get(errors, "email.message") || _get(error, "auth/user-not-found"),
    password:
      _get(errors, "password.message") || _get(error, "auth/wrong-password"),
  };

  return (
    <AuthForm
      id="sign-in"
      title="Sign in"
      sx={{ width: "330px", paddingTop: "2rem" }}
    >
      <SharedButton
        onClick={handleSignInWithGoogle}
        sx={{ marginBottom: "2rem" }}
        variant="outlined"
      >
        <Google />
        <Box
          sx={{
            marginLeft: "1rem",
            textTransform: "initial",
            display: "flex",
            alignItems: "center",
          }}
        >
          {reduxState.status === "pending" || state.isLoading ? (
            <>
              <CircularProgress
                sx={{ marginRight: "5px" }}
                color="inherit"
                size="20px"
              />
              <span>Loading ...</span>
            </>
          ) : (
            "Sign in with Google"
          )}
        </Box>
      </SharedButton>
      <HR />
      <Text
        fontSize="14px"
        lineHeight="14px"
        fontWeight={400}
        letterSpacing="-0.01em"
        color={theme.palette.text.primary}
        sx={{ marginLeft: 0, marginTop: "2rem", marginBottom: "1.5rem" }}
      >
        Or continue with email address
      </Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          iconStart={
            <MailOutlineIcon
              sx={{ color: theme.palette.text.primary, fontSize: 25 }}
            />
          }
          iconEnd={renderErrors.email ? <Warning /> : null}
          sx={{
            marginBottom: "20px",
            position: "relative",
          }}
          placeholder="Your work email"
          variant="filled"
          id="email"
          register={register}
          errors={renderErrors.email}
          disabled={!!state.company_id}
        />
        <Input
          iconStart={<LockIcon />}
          iconEnd={renderErrors.password ? <Warning /> : null}
          sx={{
            marginBottom: "20px",
            position: "relative",
          }}
          placeholder="Password"
          type="password"
          variant="filled"
          id="password"
          register={register}
          errors={renderErrors.password}
        />
        <SharedButton type="submit" variant="contained">
          <Box
            sx={{
              marginLeft: "6px",
              textTransform: "initial",
              display: "flex",
              alignItems: "center",
            }}
          >
            {reduxState.status === "pending" || state.isLoading ? (
              <>
                <CircularProgress
                  sx={{ marginRight: "10px" }}
                  color="inherit"
                  size="20px"
                />
                <span>Loading ...</span>
              </>
            ) : (
              "Sign in"
            )}
          </Box>
        </SharedButton>
        <Box sx={{ marginTop: "1rem" }}>
          <Link
            to={`/${ROUTE_FORGOT_PASSWORD}`}
            style={{ textDecoration: "none" }}
          >
            <Text
              fontSize="14px"
              lineHeight="24px"
              fontWeight={600}
              letterSpacing="-0.01em"
              sx={{ marginLeft: "0", textAlign: "left" }}
            >
              Forgotten Password?
            </Text>
          </Link>
        </Box>
        <Box display="flex" sx={{ marginTop: "1rem" }}>
          <Text
            fontSize="14px"
            lineHeight="24px"
            fontWeight={600}
            color={theme.palette.text.primary}
            letterSpacing="-0.01em"
            sx={{
              marginLeft: 0,
              textAlign: "left",
            }}
          >
            Don’t have an account?
          </Text>
          <Box onClick={handleNavToSignup} sx={{ cursor: "pointer" }}>
            <Text
              fontSize="14px"
              lineHeight="24px"
              fontWeight={600}
              letterSpacing="-0.01em"
              sx={{ marginLeft: "5px" }}
            >
              Sign up
            </Text>
          </Box>
        </Box>
      </Form>
    </AuthForm>
  );
};

export default SignIn;
