import React, { useEffect } from "react";
import Text from "components/shared/text/text";
import {
  Avatar,
  Box,
  Divider,
  Stack,
  styled,
  useTheme,
  Button,
} from "@mui/material";
import SharedButton from "components/shared/button/button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useForm } from "react-hook-form";
// import BootstrapInputField from "components/shared/bootstarped-input/bootstrap-input";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { profileSchema } from "utils/form-schema";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import ToastMessage from "components/toast-message";
import SharedInput from "components/shared/input/input";
import Switch from "components/shared/switch";
import PrivateLayout from "layouts/drawer";
import { ITheme } from "theme";
import { INotificationTypes, IRoleType, IUploadAvatarRes } from "types/types";
import { _get } from "utils/lodash";
import { auth } from "utils/firebase";
import { uploadAvatar } from "utils/uploadAvatar";
import { db, doc, setDoc } from "utils/firebase";
import { collection_name } from "utils/firebase-refs";

import { doUpdateUserAction } from "redux/services/user/update/update.actions";
import { scrollSectionIntoView } from "utils/helpers";
import { config } from "config";

interface IState {
  email: string;
  newPassword: string | undefined;
  confirmNewPassword: string | undefined;
  oldPassword: string | undefined;
  firstName: string;
  lastName: string;
  slackNotifications: boolean;
  emailNotifications: boolean;
  file?: File[];
}

interface ErrorProps {
  error?: number;
}

interface INotificationState {
  slack: INotificationTypes;
  email: INotificationTypes;
}

const initialNotificationState: INotificationState = {
  email: {
    new_candidate: false,
  },
  slack: {
    new_candidate: false,
  },
};

export const FormLabel = styled("span")<ErrorProps>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: ${({ theme, error }) =>
    error
      ? _get(theme, "palette.colors.red")
      : _get(theme, "custom.colors.orange")};
`;

const Input = styled("input")({
  display: "none",
});

const Container = styled(Box)`
  display: flex;
  height: 88%;
  width: 71vw;
  position: fixed;
  background: ${({ theme }) => theme.palette.grey[900]};
  padding-bottom: 50px;
  margin-top: 40px;
`;

const Settings: React.FC = () => {
  const [imageLoading, setImageLoading] = React.useState(false);
  const [removeImgLoading, setRemoveImgLoading] = React.useState(false);
  const [avatarURI, setAvatarURIImage] = React.useState("");
  const [currentSection, setCurrentSection] = React.useState(
    "Profile Information"
  );
  const [notificationsState, setNotificationsState] =
    React.useState<INotificationState>(initialNotificationState);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IState>({
    resolver: yupResolver(profileSchema),
  });
  const theme: ITheme = useTheme();
  const dispatch = useAppDispatch();
  const _auth = auth.getAuth();
  const _user = _auth.currentUser as auth.User;
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );

  const handleUploadProfile = async ({ file }: any) => {
    setImageLoading(true);
    // uploadAvatar(userState.uid, IRoleType.CLIENT, file, true)
    uploadAvatar({
      userType: IRoleType.CLIENT,
      target_id: userState.uid || "",
      target_name: collection_name.v2_clients,
      avatarFile: file,
      shouldUpdateCollection: false,
    })
      .then(async (res: IUploadAvatarRes) => {
        if (res.original_avatar_url && res.thumbnail_avatar_url) {
          const data: {
            original_avatar_url: string;
            thumbnail_avatar_url: string;
          } = {
            original_avatar_url: res.original_avatar_url,
            thumbnail_avatar_url: res.thumbnail_avatar_url,
          };
          setAvatarURIImage(res.original_avatar_url);
          await dispatch(
            doUpdateUserAction({
              uid: userState.uid,
              user: data,
            })
          );

          // setThumbnail(res.thumbnail_avatar_url);
          toast.success(
            <ToastMessage
              title="Profile upload"
              body="Profile picture uploaded"
            />,
            { type: "success", position: "top-right" }
          );
        } else {
          toast.error(
            <ToastMessage
              title="Profile upload"
              body="Error uploading picture"
            />,
            { type: "error", position: "top-right" }
          );
        }
      })
      .catch(() => {
        toast(
          <ToastMessage
            title="Profile upload"
            body="Error uploading picture"
          />,
          { type: "error", position: "top-right" }
        );
      })
      .finally(() => {
        setImageLoading(false);
      });
  };
  const handleFileSelect = (event: any) => {
    event.preventDefault();
    event.persist();
    const file = event.target.files[0];
    if (!file) {
      toast(<ToastMessage title="File" body="Missing File" />, {
        type: "error",
        position: "top-right",
      });
      return;
    }
    setImageLoading(true);
    handleUploadProfile({
      file,
      url: URL && URL.createObjectURL(file),
    });
  };

  function stringAvatar(name: string) {
    return {
      children: imageLoading ? (
        <CircularProgress
          sx={{ marginRight: "10px" }}
          color="inherit"
          size="30px"
        />
      ) : (
        `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      ),
    };
  }

  const removeProfile = async () => {
    setRemoveImgLoading(true);
    if (!userState.uid) {
      toast.error(
        <ToastMessage
          title="Missing uid"
          body="Unable to delete profile. Missing user id"
        />,
        { type: "error", position: "top-right" }
      );
      return;
    }
    const userRef = doc(db, collection_name.v2_clients, userState.uid);
    setDoc(
      userRef,
      {
        original_avatar_url: "",
        thumbnail_avatar_url: "",
      },
      { merge: true }
    );
    setAvatarURIImage("");
    const data: {
      original_avatar_url: string;
      thumbnail_avatar_url: string;
    } = {
      original_avatar_url: "",
      thumbnail_avatar_url: "",
    };
    await dispatch(
      doUpdateUserAction({
        uid: userState.uid,
        user: data,
      })
    );

    setRemoveImgLoading(false);
  };

  const onSubmit = async (data: IState) => {
    if (data.newPassword) {
      if (!data.oldPassword) {
        return toast(
          <ToastMessage
            title="Password Error"
            body="Please add Old password to update your password"
          />,
          {
            type: "error",
            position: "top-right",
          }
        );
      }

      const credentials = auth.EmailAuthProvider.credential(
        data.email,
        data.oldPassword
      );

      try {
        await auth.reauthenticateWithCredential(_user, credentials);
        await auth.updatePassword(_user, data.newPassword);
      } catch (error: any) {
        const body =
          error?.code === "auth/wrong-password"
            ? "Invalid old password"
            : "There was an error updating the information";
        return toast(<ToastMessage title="Profile Information" body={body} />, {
          type: "error",
          position: "top-right",
        });
      }
    }

    const updateResponse = await dispatch(
      doUpdateUserAction({
        uid: userState.uid,
        user: {
          firstName: data.firstName,
          lastName: data.lastName,
        },
      })
    );

    if (updateResponse.meta.requestStatus === "rejected") {
      return toast(
        <ToastMessage
          title="Profile Information"
          body="There was an error updating the information"
        />,
        {
          type: "error",
          position: "top-right",
        }
      );
    }

    setValue("oldPassword", "");
    setValue("newPassword", "");
    setValue("confirmNewPassword", "");
    return toast(
      <ToastMessage
        title="Profile Information"
        body="Profile information has been updated"
      />,
      {
        type: "success",
        position: "top-right",
      }
    );
  };

  const viewSection = (el: string) => {
    const elem = el
      .replace(/[^a-zA-Z0-9]/g, " ")
      .replace(/\s+/g, "-")
      .toLocaleLowerCase();
    scrollSectionIntoView(`#${elem}`);
    setCurrentSection(el);
  };

  useEffect(() => {
    const user_data = {
      ...userState,
    };

    setValue("firstName", user_data.firstName);
    setValue("lastName", user_data.lastName);
    setValue("email", user_data.email);
    setNotificationsState({
      email: {
        new_candidate: userState?.notifications?.email?.new_candidate || false,
      },
      slack: {
        new_candidate: userState?.notifications?.slack?.new_candidate || false,
      },
    });
  }, [userState]);

  const handleNewCandidatesEmailChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNotificationsState({
      ...notificationsState,
      email: {
        new_candidate: event.target.checked,
      },
    });

    const data = {
      notifications: {
        email: {
          new_candidate: event.target.checked,
        },
      },
    };
    await dispatch(
      doUpdateUserAction({
        uid: userState.uid,
        user: data,
      })
    );

    const body = event.target.checked
      ? "Email notification for my jobs enabled"
      : "Email notification for my jobs disabled";
    toast.success(
      <ToastMessage title="New Candidate Notifications" body={body} />,
      { type: "success", position: "top-right" }
    );
  };

  const handleNewCandidatesSlackChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNotificationsState({
      ...notificationsState,
      slack: {
        new_candidate: event.target.checked,
      },
    });

    const isSlackSelected = userState?.integrations?.Slack?.selected;
    const shouldIntegrateSlack = !isSlackSelected && event.target.checked;

    if (shouldIntegrateSlack) {
      window.open(
        `https://slack.com/oauth/v2/authorize?client_id=3417414999671.3444660245457&scope=chat:write,chat:write.customize,users:read&user_scope=chat:write&redirect_uri=${
          config.REACT_APP_SLACK_REDIRECT_URI
        }/&state=${userState.email}~${userState.company_id}~${
          userState?.firstName
        }~${
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname
        }~${userState?.uid}
  		`,
        "_blank"
      );
      return;
    }

    const data = {
      notifications: {
        slack: {
          new_candidate: event.target.checked,
        },
      },
    };
    await dispatch(
      doUpdateUserAction({
        uid: userState.uid,
        user: data,
      })
    );

    const body = event.target.checked
      ? "Slack notification enabled"
      : "Slack notification disabled";
    toast.success(
      <ToastMessage title="New Candidate Notifications" body={body} />,
      { type: "success", position: "top-right" }
    );
  };

  return (
    <PrivateLayout menu="User Settings">
      <Container>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          sx={{
            background: theme.palette.grey[100],
            borderRadius: "8px",
            "&::-webkit-scrollbar": { display: "none" },
            overflow: "scroll",
            marginBottom: "24px",
          }}
        >
          <Box
            id="profile-information"
            sx={{
              padding: "24px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "16px",
                  height: "32px",
                  margin: "0px 16px 0px 0px",
                  background: theme?.custom.colors.green,
                  borderRadius: "4px",
                }}
              />
              <Text
                variant="h2"
                fontSize="20px"
                fontWeight={600}
                lineHeight="32px"
                letterSpacing="-0.02em"
                sx={{
                  ml: 0,
                  textAlign: "left",
                }}
              >
                Profile Information
              </Text>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                direction="row"
                sx={{
                  mt: "34px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  component="label"
                  onChange={handleFileSelect}
                  htmlFor="contained-button-file"
                  sx={{
                    border: "none",
                    height: "96px",
                    width: "96px",
                    borderRadius: "50%",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      height: "96px",
                      width: "96px",
                      background: imageLoading ? theme.palette.grey[300] : null,
                    }}
                    alt={userState.firstName}
                    src={
                      avatarURI
                        ? avatarURI
                        : userState?.original_avatar_url
                        ? userState.original_avatar_url
                        : ""
                    }
                    {...stringAvatar(
                      `${userState.firstName} ${userState.lastName}`
                    )}
                  />
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    {...register("file")}
                    type="file"
                  />
                </Button>
                <Button
                  variant="outlined"
                  component="label"
                  onChange={handleFileSelect}
                  htmlFor="contained-button-file-1"
                  sx={{
                    border: "none",
                    height: "48px",
                    width: "auto",
                    background: "transparent",
                    "&:hover": {
                      background: "transparent",
                      boxShadow: "none",
                      border: "none",
                    },
                  }}
                >
                  <Input
                    accept="image/*"
                    id="contained-button-file-1"
                    multiple
                    {...register("file")}
                    type="file"
                  />
                  <SharedButton
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="secondary"
                    component="span"
                    sx={{
                      width: "auto",
                      ml: "32px",
                      height: "48px",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    Upload new logo
                  </SharedButton>
                </Button>
                <SharedButton
                  variant="contained"
                  startIcon={removeImgLoading ? null : <RemoveIcon />}
                  onClick={removeProfile}
                  sx={{
                    ml: "12px",
                    width: "auto",
                    height: "48px",
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.palette.grey[100],
                    textTransform: "none",
                    boxShadow: "none",
                    border: `2px solid ${theme.palette.grey[300]}`,
                    "&:hover": {
                      backgroundColor: theme.palette.grey[100],
                      cursor: "pointer",
                      boxShadow: "none",
                    },
                  }}
                >
                  {removeImgLoading ? (
                    <>
                      <CircularProgress
                        sx={{ marginRight: "10px" }}
                        color="inherit"
                        size="20px"
                      />
                      <span>Loading...</span>
                    </>
                  ) : (
                    "Remove"
                  )}
                </SharedButton>
              </Stack>
              <Stack direction="column" sx={{ width: "100%", mt: "34px" }}>
                <Box
                  sx={{
                    mt: "34px",
                    display: "flex",
                    flexFlow: "row",
                  }}
                >
                  <Box sx={{ width: "47.5%", mr: "5%" }}>
                    <Box sx={{ marginBottom: "10px" }}>
                      <FormLabel>First Name</FormLabel>
                    </Box>
                    <Box>
                      <SharedInput
                        type="text"
                        register={register}
                        id="firstName"
                        errors={errors?.firstName?.message}
                        errorSx={{
                          top: "20px",
                        }}
                        variant="filled"
                      />
                    </Box>
                  </Box>
                  <Box sx={{ width: "47.5%" }}>
                    <Box sx={{ marginBottom: "10px" }}>
                      <FormLabel>Last Name</FormLabel>
                    </Box>
                    <Box>
                      <SharedInput
                        type="text"
                        register={register}
                        id="lastName"
                        errors={errors?.lastName?.message}
                        variant="filled"
                        errorSx={{
                          top: "20px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: "10px" }}>
                  <Box sx={{ marginBottom: "10px" }}>
                    <FormLabel>Email</FormLabel>
                  </Box>
                  <Box>
                    <SharedInput
                      register={register}
                      id="email"
                      errors={errors?.email?.message}
                      variant="filled"
                      errorSx={{
                        top: "20px",
                      }}
                      disabled
                    />
                  </Box>
                </Box>
                <Divider
                  sx={{
                    mt: "27px",
                    mb: "27px",
                    height: "1px",
                    background: theme.palette.grey[300],
                  }}
                />
                <Box id="login" sx={{ display: "flex", widht: "100%" }}>
                  <Box
                    sx={{
                      width: "16px",
                      height: "32px",
                      margin: "0px 16px 0px 0px",
                      background: theme.custom.colors.purple,
                      borderRadius: "4px",
                    }}
                  />
                  <Text
                    variant="h2"
                    fontSize="20px"
                    fontWeight={600}
                    lineHeight="32px"
                    letterSpacing="-0.02em"
                    sx={{
                      ml: 0,
                      textAlign: "left",
                      mb: "38px",
                    }}
                  >
                    Login
                  </Text>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ marginBottom: "10px" }}>
                    <FormLabel>Old Password</FormLabel>
                  </Box>
                  <Box>
                    <SharedInput
                      type="password"
                      register={register}
                      id="oldPassword"
                      errors={errors?.oldPassword?.message}
                      variant="filled"
                      errorSx={{
                        top: "20px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: "34px",
                      display: "flex",
                      flexFlow: "row",
                    }}
                  >
                    <Box sx={{ width: "47.5%", mr: "5%" }}>
                      <Box sx={{ marginBottom: "10px" }}>
                        <FormLabel>New Password</FormLabel>
                      </Box>
                      <Box>
                        <SharedInput
                          type="password"
                          register={register}
                          id="newPassword"
                          errors={errors?.newPassword?.message}
                          variant="filled"
                          errorSx={{
                            top: "20px",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ width: "47.5%" }}>
                      <Box sx={{ marginBottom: "10px" }}>
                        <FormLabel>Confirm new password</FormLabel>
                      </Box>
                      <SharedInput
                        type="password"
                        register={register}
                        id="confirmNewPassword"
                        errors={errors?.confirmNewPassword?.message}
                        variant="filled"
                        errorSx={{
                          top: "20px",
                        }}
                      />
                    </Box>
                  </Box>
                  {/*<SharedButton
                    variant="contained"
                    sx={{
                      mt: "34px",
                      width: "auto",
                      color: theme.palette.text.secondary,
                      backgroundColor: theme.palette.grey[100],
                      textTransform: "none",
                      borderBottomRightRadius: "20px",
                      borderTopLeftRadius: "20px",
                      boxShadow: "none",
                      border: `2px solid ${theme.palette.grey[300]}`,
                      "&:hover": {
                        backgroundColor: theme.palette.grey[100],
                        cursor: "pointer",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Update password
				</SharedButton>*/}
                </Box>
                <Divider
                  sx={{
                    mt: "27px",
                    mb: "27px",
                    height: "1px",
                    background: theme.palette.grey[300],
                  }}
                />
                <Box id="notifications" sx={{ display: "flex", widht: "100%" }}>
                  <Box
                    sx={{
                      width: "16px",
                      height: "32px",
                      margin: "0px 16px 0px 0px",
                      background: theme.custom.colors.redFaded,
                      borderRadius: "4px",
                    }}
                  />
                  <Text
                    variant="h2"
                    fontSize="20px"
                    fontWeight={600}
                    lineHeight="32px"
                    letterSpacing="-0.02em"
                    sx={{
                      ml: 0,
                      textAlign: "left",
                    }}
                  >
                    Notifications
                  </Text>
                </Box>

                <Stack>
                  <Text
                    variant="h2"
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight="32px"
                    letterSpacing="-0.02em"
                    sx={{
                      ml: 0,
                      display: "flex",
                      alignItems: "center",
                      mt: "34px",
                      justifyContent: "space-between",
                      color: theme.palette.grey[600],
                    }}
                  >
                    Turn on email notifications for new candidates added to my
                    jobs
                    <Switch
                      onChange={handleNewCandidatesEmailChange}
                      checked={notificationsState.email.new_candidate}
                      sx={{ float: "end" }}
                    />
                  </Text>
                  <Text
                    variant="h2"
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight="32px"
                    letterSpacing="-0.02em"
                    sx={{
                      textAlign: "left",
                      ml: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: "34px",
                      color: theme.palette.grey[600],
                    }}
                  >
                    Turn on Slack notifications for new candidates added to my
                    jobs
                    <Switch
                      onChange={handleNewCandidatesSlackChange}
                      checked={notificationsState.slack.new_candidate}
                      color={"secondary"}
                      sx={{ float: "right" }}
                    />
                  </Text>
                  <SharedButton
                    type="submit"
                    variant="contained"
                    color="secondary"
                    // onClick={handleSubmit(onSubmit)}
                    sx={{
                      mt: "34px",
                      width: "212px",
                      height: "48px",
                      padding: "12px 20px",
                      boxShadow: "none",
                    }}
                  >
                    save
                  </SharedButton>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Box>
        <Box
          display="flex"
          flex="0.4"
          flexDirection="column"
          sx={{
            marginLeft: "8px",
            background: theme.palette.grey[100],
            padding: "24px",
            borderRadius: "8px",
            maxHeight: "20vh",
          }}
        >
          {["Profile Information", "Login", "Notifications"].map(
            (el: string) => (
              <Box
                key={el}
                sx={{
                  width: "100%",
                  float: "right",
                  borderRadius: "8px",
                  marginBottom: "4px",
                  cursor: "pointer",
                  background:
                    el === currentSection
                      ? theme.palette.grey[300]
                      : theme.palette.grey[100],
                }}
                onClick={() => viewSection(el)}
              >
                <Text
                  variant="body1"
                  fontWeight={600}
                  fontSize={"15px"}
                  lineHeight={"24px"}
                  sx={{
                    color:
                      el === currentSection
                        ? theme.palette.text.secondary
                        : theme.palette.grey[400],
                    textAlign: "left",
                    marginLeft: 0,
                    padding: "8px 16px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    "&:hover": {
                      color: theme.palette.text.secondary,
                    },
                  }}
                >
                  {el}
                </Text>
              </Box>
            )
          )}
        </Box>
      </Container>
    </PrivateLayout>
  );
};

export default Settings;
