/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPayload } from "./interface";
import * as axios from "api/axios";
const axiosInstance = axios.default;

export const doGetLinkTokenAction = createAsyncThunk(
  "ats/get-linkToken",
  async (payload: IPayload) => {
    try {
      if (payload._link_token) {
        return {
          message: {},
        };
      }

      const response = await axiosInstance.get("/link-token", {
        params: {
          company_id: payload.company_id,
          user_email: payload.user_email,
        },
      });
      return {
        success: true,
        message: response.data || {},
      };
    } catch (error: any) {
      return error.response?.data || "Something went wrong";
    }
  }
);
