// un-authenticated routes
export const ROUTE_SIGN_UP = "sign-up";
export const ROUTE_SIGN_IN = "sign-in";
export const ROUTE_LOADER = "loader"; // this is to make testing of the loader easier, since it's not a real route
export const ROUTE_TOAST_DEMO = "toast-demo"; // this is for demo purposes, to view the toasts
export const ROUTE_PRIVACY_POLICY = "privacy-policy";
export const ROUTE_MOBILE_NOTICE = "mobile-notice";
export const ROUTE_FORGOT_PASSWORD = "forgot-password";

// authenticated routes
export const ROUTE_HOME = "home";
export const ROUTE_ACTIVE_JOBS = "connect";
export const ROUTE_ACTIVE_JOB = "connect/:id";
export const ROUTE_ACTIVE_JOB_CANDIDATE = "connect/:id/candidate/:candidateid";
export const ROUTE_CANDIDATE_REJECT =
  "connect/:id/reject-candidate/:candidateID";
export const ROUTE_CANDIDATE_SHORTLIST =
  "connect/:id/shortlist-candidate/:candidateID";
export const ROUTE_COMPANY = "your-company";
export const ROUTE_WELCOME = "welcome";
export const ROUTE_BUILDING = "building";
export const ROUTE_SLACK_CONNECT = "slack-connect";
export const ROUTE_SLACK_CONNECT_SUCCESS = "slack-connect-success";
export const ROUTE_NEW_JOB = "new-job";
export const ROUTE_EDIT_JOB = "edit-job";
export const ROUTE_JOB_ONBOARDING_COMPLETE = "job-onboarding-complete";
export const ROUTE_INTEGRATIONS = "integrations";
export const ROUTE_SETTINGS = "settings";
export const ROUTE_COMPANY_PROFILE = "company-profile";
export const ROUTE_COMMAND_BAR = "command-bar";
export const ROUTE_RELEASE_NOTES = "release-notes";
export const ROUTE_FAQS = "faqs";
export const ROUTE_RESIZE_NAVBAR = "resize-navbar";
// below is for demo purposes, to show the candidate detail frontend
export const ROUTE_CANDIDATE_DETAIL = "candidate-detail";

export default {
  ROUTE_SIGN_UP,
  ROUTE_SIGN_IN,
  ROUTE_PRIVACY_POLICY,
  ROUTE_HOME,
  ROUTE_ACTIVE_JOB,
  ROUTE_ACTIVE_JOBS,
  ROUTE_COMPANY,
  ROUTE_WELCOME,
  ROUTE_BUILDING,
  ROUTE_SLACK_CONNECT,
  ROUTE_SLACK_CONNECT_SUCCESS,
  ROUTE_NEW_JOB,
  ROUTE_EDIT_JOB,
  ROUTE_INTEGRATIONS,
  ROUTE_SETTINGS,
  ROUTE_LOADER,
  ROUTE_TOAST_DEMO,
  ROUTE_COMPANY_PROFILE,
  ROUTE_FAQS,
  ROUTE_RESIZE_NAVBAR,
  ROUTE_JOB_ONBOARDING_COMPLETE,
  ROUTE_CANDIDATE_DETAIL,
  ROUTE_MOBILE_NOTICE,
  ROUTE_ACTIVE_JOB_CANDIDATE,
  ROUTE_CANDIDATE_SHORTLIST,
  ROUTE_CANDIDATE_REJECT,
  ROUTE_COMMAND_BAR,
  ROUTE_RELEASE_NOTES,
};
