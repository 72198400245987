import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRemoveState } from "./interface";
import * as axios from "api/axios";
const axiosInstance = axios.default;

export const doRemoveSlackUserAction = createAsyncThunk(
  "clients-removeSlackUsers",
  async (payload: IRemoveState) => {
    try {
      const userData = await axiosInstance.post("/slack-delete", {
        email: payload.email,
        uid: payload.uid,
        slack_access_token: payload.slack_access_token,
        slack_user_access_token: payload.slack_user_access_token,
        role: payload.role,
      });
      return userData;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.response?.data || "Something went wrong";
    }
  }
);
