import React, { useEffect } from "react";
import clsx from "clsx";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import ReactHtmlParser from "react-html-parser";
import draftToHtml from "draftjs-to-html";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Span from "components/shared/span/span";
import Text from "components/shared/text/text";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CandidateNext from "assets/images/candidate-next.svg";
import CandidatePrevious from "assets/images/candidate-previous.svg";
import CandidateArrow from "assets/images/candidate-arrow.svg";
import CandidateClose from "assets/images/candidate-close.svg";
import LinkIcon from "assets/images/candidate-link-icon.svg";
import Resume from "assets/images/candidate-resume.svg";
import Briefcase from "assets/images/candidate-case.svg";
import LinkedIn from "assets/images/candidate-linkedin.svg";
import CandidateStatus from "components/job/candidate-status";
import { IConnectedCandidate, TCandidateUpdate } from "types/types";
import { formatCandidateDate, getRecruiterNotes } from "utils/helpers";
import SelectedSkill from "./selected-skill";
import { _first, _get } from "utils/lodash";

// eslint-disable-next-line react/display-name
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(17, 19, 21, 0.6);
  -webkit-tap-highlight-color: transparent;
  backdrop-filter: blur(30px);
`;

const ModalBody = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

const ContentContainer = styled(Box)`
  width: 100%;
  margin-bottom: 50px;
  max-width: 1000px;
`;

const ModalHeader = styled(Box)`
  margin-bottom: 40px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalFooter = styled(Box)`
  height: 50px;
`;

const ModalContentBody = styled(Box)`
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

const FlexSpan = styled("span")`
  display: inline-flex;
  align-items: center;
`;

const BackButton = styled(Button)`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.palette.grey[400]};
  text-transform: none;
`;

const TrackBox = styled("span")`
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 8px;
  margin-left: 13.5px;
  display: flex;
  align-items: center;
`;

const CandidateIntro = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const CandidateNameBox = styled(Box)`
  display: flex;
`;

const CandidateLinks = styled(Box)`
  margin-top: 50px;
`;

const CandidateLink = styled("a")`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
  color: #6f767e;
`;

const LinkIconContainer = styled("a")`
  margin-left: 3.5px;
`;

const LinksContainer = styled(Box)`
  display: flex;
`;

const NotesContainer = styled(Box)`
  margin-top: 50px;
  background: #fefbea;
  padding: 20px;
  border: 2px solid #fce998;
  border-radius: 8px;
`;

const SkillsBox = styled(Box)`
  margin-top: 50px;
`;

const SkillsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`;

const Skill = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  background: #efefef;
  border: 2px solid #efefef;
  border-radius: 20px 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1a1d1f;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ExperienceBox = styled(Box)`
  margin-top: 30px;
`;

const EducationBox = styled(Box)`
  margin-top: 30px;
`;

const IconContainer = styled(Box)`
  cursor: pointer;
  height: 30px;
`;

interface IProps {
  handleClose: () => void;
  open: boolean;
  candidate: IConnectedCandidate;
  updated_by: TCandidateUpdate;
  // eslint-disable-next-line no-unused-vars
  switchCandidate: (move: "previous" | "next") => void;
  candidatesList: IConnectedCandidate[];
}

const CandidateDetails = ({
  handleClose,
  open,
  candidate,
  updated_by,
  switchCandidate,
  candidatesList,
}: IProps) => {
  function stringAvatar(name: string) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const getLinkedInURL = () => {
    let url = candidate?.connected_links?.find(
      (link) => link?.key === "linkedin"
    )?.link;

    if (url) {
      if (url.startsWith("linkedin")) {
        url = "https://www." + url;
      }
      try {
        return new URL(url).href;
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  };

  const prepareExternalLink = (link: string) => {
    if (!link.includes("://") && !link.startsWith("/")) {
      return "//" + link;
    } else {
      return link;
    }
  };

  function onArrowKeysPress(e: KeyboardEvent) {
    if (e.key == "ArrowLeft") {
      switchCandidate("previous");
    } else if (e.key == "ArrowRight") {
      switchCandidate("next");
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", onArrowKeysPress);
    return () => {
      window.removeEventListener("keydown", onArrowKeysPress);
    };
  });

  const linked_in_url = getLinkedInURL();
  const experiences = _first(_get(candidate, "candidate_experiences")) || {};
  const jobTitle = experiences.jobTitle || candidate.jobTitle;
  const companyName = experiences.companyName || candidate.currentCompany;
  const location = experiences.location || candidate.location;

  return (
    <Modal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      components={{ Backdrop }}
    >
      <ModalBody>
        <Box
          sx={{
            width: "20%",
            paddingTop: "411px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconContainer onClick={() => switchCandidate("previous")}>
            <img
              width={48}
              height={48}
              src={CandidatePrevious}
              alt="Previous"
            />
          </IconContainer>
        </Box>
        <ContentContainer>
          <ModalHeader>
            <BackButton onClick={handleClose}>
              <FlexSpan>
                <img src={CandidateArrow} alt="a" />
                <TrackBox>
                  <Span
                    fontWeight={500}
                    fontSize="13px"
                    color={"#ffffff"}
                    sx={{
                      lineHeight: "1",
                    }}
                  >
                    {candidatesList?.indexOf(candidate) + 1}/
                    {candidatesList?.length}
                  </Span>
                </TrackBox>
              </FlexSpan>
            </BackButton>
            <BackButton onClick={handleClose} sx={{ right: "-4px" }}>
              <FlexSpan>
                <Span
                  fontWeight={400}
                  fontSize="14px"
                  color={"#dddddd"}
                  sx={{
                    lineHeight: "1",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                >
                  Close
                </Span>
                <img src={CandidateClose} alt="" />
              </FlexSpan>
            </BackButton>
          </ModalHeader>
          <ModalContentBody>
            <CandidateIntro>
              <CandidateNameBox>
                <Box>
                  <Avatar
                    sx={{
                      height: "80px",
                      width: "80px",
                      background: "#ddd",
                      marginRight: "24px",
                    }}
                    alt={candidate?.firstName}
                    src={candidate?.candidate_avatar_url}
                    {...stringAvatar(
                      `${candidate?.firstName} ${candidate?.lastName}`
                    )}
                  />
                </Box>
                <Box>
                  <Text
                    variant="body1"
                    fontSize="20px"
                    fontWeight={600}
                    lineHeight="20px"
                    letterSpacing="-0.02em"
                    sx={{
                      marginBottom: "10px",
                      marginLeft: 0,
                      textAlign: "left",
                    }}
                  >
                    {candidate?.firstName} {candidate?.lastName}
                  </Text>
                  <Text
                    variant="body1"
                    fontSize="13px"
                    fontWeight={500}
                    lineHeight="13px"
                    letterSpacing="-0.01em"
                    color="#9A9FA5"
                    sx={{
                      marginBottom: "10px",
                      marginLeft: 0,
                      textAlign: "left",
                    }}
                  >
                    {jobTitle || ""}{" "}
                    {(
                      <>
                        {companyName ? "@ " : ""}
                        {companyName}
                      </>
                    ) || ""}
                  </Text>
                  <Text
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "13px",
                      color: "#9A9FA5",
                      fontWeight: 400,
                      lineHeight: "16px",
                      letterSpacing: "-1%",
                      textAlign: "left",
                      marginBottom: "10px",
                      marginLeft: 0,
                    }}
                  >
                    {location || ""}
                  </Text>
                  <Text
                    variant="body1"
                    fontSize="13px"
                    fontWeight={500}
                    lineHeight="13px"
                    letterSpacing="-0.01em"
                    color="#9A9FA5"
                    sx={{
                      marginBottom: "10px",
                      marginLeft: 0,
                      textAlign: "left",
                    }}
                  >
                    Added {formatCandidateDate(candidate?.created_at)}
                  </Text>
                </Box>
              </CandidateNameBox>
              <Box
                sx={{
                  width: "30%",
                }}
              >
                <CandidateStatus
                  candidate={candidate}
                  updated_by={updated_by}
                />
              </Box>
            </CandidateIntro>
            {!linked_in_url &&
            !candidate?.file &&
            !candidate?.cv_url &&
            !candidate?.portfolio ? (
              ""
            ) : (
              <CandidateLinks>
                <Text
                  variant="body1"
                  fontSize="13px"
                  fontWeight={400}
                  lineHeight="13px"
                  letterSpacing="-0.01em"
                  color="#9A9FA5"
                  sx={{
                    marginBottom: "20px",
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  Documents & Links
                </Text>
                <LinksContainer>
                  {linked_in_url && (
                    <FlexSpan>
                      <img
                        src={LinkedIn}
                        alt=""
                        style={{ marginRight: "8px" }}
                      />
                      <CandidateLink target="_blank" href={linked_in_url}>
                        LinkedIn
                      </CandidateLink>
                      <LinkIconContainer target="_blank" href={linked_in_url}>
                        <img src={LinkIcon} />
                      </LinkIconContainer>
                    </FlexSpan>
                  )}
                  {(candidate?.file || candidate?.cv_url) && (
                    <FlexSpan
                      sx={{
                        ...(linked_in_url && {
                          marginLeft: "60px",
                        }),
                      }}
                    >
                      <img
                        src={Resume}
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                      <CandidateLink
                        target="_blank"
                        href={candidate?.file || candidate?.cv_url}
                      >
                        Resume
                      </CandidateLink>
                      <LinkIconContainer
                        target="_blank"
                        href={candidate?.file || candidate?.cv_url}
                      >
                        <img src={LinkIcon} />
                      </LinkIconContainer>
                    </FlexSpan>
                  )}
                  {candidate?.portfolio && (
                    <FlexSpan
                      sx={{
                        ...((linked_in_url ||
                          candidate?.file ||
                          candidate?.cv_url) && {
                          marginLeft: "60px",
                        }),
                      }}
                    >
                      <img
                        src={Briefcase}
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                      <CandidateLink
                        target="_blank"
                        href={prepareExternalLink(candidate?.portfolio)}
                      >
                        Portfolio
                      </CandidateLink>
                      <LinkIconContainer
                        target="_blank"
                        href={prepareExternalLink(candidate?.portfolio)}
                      >
                        <img src={LinkIcon} />
                      </LinkIconContainer>
                    </FlexSpan>
                  )}
                </LinksContainer>
              </CandidateLinks>
            )}
            {candidate?.skills_must_have || candidate?.skills_nice_to_have ? (
              <NotesContainer>
                <Text
                  variant="body1"
                  fontSize="12px"
                  fontWeight={700}
                  lineHeight="18px"
                  letterSpacing="-0.01em"
                  color="#414141"
                  sx={{
                    marginBottom: "14px",
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  Candidate meets the following must-have criteria:
                </Text>
                {candidate?.skills_must_have &&
                candidate?.skills_must_have?.length > 0
                  ? candidate?.skills_must_have?.map((skill, i) => (
                      <SelectedSkill key={i} skill={skill}></SelectedSkill>
                    ))
                  : "-"}
                <Box
                  sx={{
                    border: "0.5px solid #FCE998",
                    boxShadow:
                      "46.361px 46.361px 154.537px rgba(25, 75, 56, 0.15)",
                    margin: "14px -20px",
                  }}
                ></Box>
                <Text
                  variant="body1"
                  fontSize="12px"
                  fontWeight={700}
                  lineHeight="18px"
                  letterSpacing="-0.01em"
                  color="#414141"
                  sx={{
                    marginBottom: "14px",
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  Candidate meets the following nice-to-have criteria:
                </Text>
                {candidate?.skills_nice_to_have &&
                candidate?.skills_nice_to_have?.length > 0
                  ? candidate?.skills_nice_to_have
                    ? candidate?.skills_nice_to_have?.map((skill, i) => (
                        <SelectedSkill key={i} skill={skill}></SelectedSkill>
                      ))
                    : "-"
                  : "-"}
                <Box
                  sx={{
                    border: "0.5px solid #FCE998",
                    boxShadow:
                      "46.361px 46.361px 154.537px rgba(25, 75, 56, 0.15)",
                    margin: "14px -20px",
                  }}
                ></Box>
                <Text
                  variant="body1"
                  fontSize="12px"
                  fontWeight={700}
                  lineHeight="18px"
                  letterSpacing="-0.01em"
                  color="#414141"
                  sx={{
                    marginBottom: "14px",
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  Recruiter's notes:
                </Text>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#818181",
                    fontFamily: "Inter",
                  }}
                >
                  {typeof getRecruiterNotes(
                    candidate?.recruiter_notes,
                    true
                  ) === "string"
                    ? getRecruiterNotes(candidate?.recruiter_notes, true) || "-"
                    : ReactHtmlParser(
                        draftToHtml(
                          getRecruiterNotes(candidate?.recruiter_notes, true)
                        )
                      ) || "-"}
                </div>
              </NotesContainer>
            ) : (
              <NotesContainer>
                <Text
                  variant="body1"
                  fontSize="18px"
                  fontWeight={600}
                  lineHeight="18px"
                  letterSpacing="-0.02em"
                  color="#414141"
                  sx={{
                    marginBottom: "30px",
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  Notes from the recruiter
                </Text>
                <Text
                  variant="body1"
                  fontSize="14px"
                  fontWeight={400}
                  lineHeight="150%"
                  letterSpacing="-0.01em"
                  color="#818181"
                  sx={{
                    marginBottom: "30px",
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  {typeof getRecruiterNotes(
                    candidate?.recruiter_notes,
                    true
                  ) === "string"
                    ? getRecruiterNotes(candidate?.recruiter_notes, true) || "-"
                    : ReactHtmlParser(
                        draftToHtml(
                          getRecruiterNotes(candidate?.recruiter_notes, true)
                        )
                      ) || "-"}
                </Text>
                <Text
                  variant="body1"
                  fontSize="12px"
                  fontWeight={700}
                  lineHeight="16px"
                  letterSpacing="-0.01em"
                  color="#414141"
                  sx={{
                    marginBottom: "14px",
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  Why is this candidate relevant?
                </Text>
                <Text
                  variant="body1"
                  fontSize="14px"
                  fontWeight={400}
                  lineHeight="150%"
                  letterSpacing="-0.01em"
                  color="#818181"
                  sx={{
                    marginBottom: "30px",
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  {candidate?.reasonForRelevance || "-"}
                </Text>
                <Text
                  variant="body1"
                  fontSize="12px"
                  fontWeight={700}
                  lineHeight="16px"
                  letterSpacing="-0.01em"
                  color="#414141"
                  sx={{
                    marginBottom: "14px",
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  Potential concerns?
                </Text>
                <Text
                  variant="body1"
                  fontSize="14px"
                  fontWeight={400}
                  lineHeight="150%"
                  letterSpacing="-0.01em"
                  color="#818181"
                  sx={{
                    marginBottom: "0",
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  {candidate?.reasonForNotRelevance || "-"}
                </Text>
              </NotesContainer>
            )}
            {Array.isArray(candidate?.skills) && candidate.skills?.length > 0 && (
              <SkillsBox>
                <Text
                  variant="body1"
                  fontSize="13px"
                  fontWeight={400}
                  lineHeight="13px"
                  letterSpacing="-0.01em"
                  color="#9A9FA5"
                  sx={{
                    marginBottom: "20px",
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  Skills
                </Text>
                <SkillsContainer>
                  {Array.isArray(candidate?.skills) &&
                    candidate.skills?.map((skill) => {
                      return <Skill key={skill?.name}>{skill?.name}</Skill>;
                    })}
                </SkillsContainer>
                <Divider
                  sx={{
                    borderColor: "#EFEFEF",
                    borderRadius: "1px",
                    marginBottom: "20px",
                    marginTop: "14px",
                  }}
                />
              </SkillsBox>
            )}
            {Array.isArray(candidate?.candidate_experiences) &&
              candidate?.candidate_experiences?.length > 0 && (
                <ExperienceBox>
                  <Text
                    variant="body1"
                    fontSize="13px"
                    fontWeight={400}
                    lineHeight="13px"
                    letterSpacing="-0.01em"
                    color="#9A9FA5"
                    sx={{
                      marginBottom: "20px",
                      marginLeft: 0,
                      textAlign: "left",
                    }}
                  >
                    Experience
                  </Text>
                  {Array.isArray(candidate?.candidate_experiences) &&
                    candidate?.candidate_experiences?.map((experience) => (
                      <Box
                        key={`${experience?.companyName}-${
                          experience?.jobTitle
                        }-${
                          experience?.dateRange
                            ? experience?.dateRange
                            : experience?.date_range
                        }`}
                        sx={{ flexGrow: 1 }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={2}>
                            <Text
                              variant="body1"
                              fontSize="13px"
                              fontWeight={400}
                              lineHeight="13px"
                              letterSpacing="-0.01em"
                              color="#9A9FA5"
                              sx={{
                                marginBottom: "5px",
                                paddingRight: "10px",
                                marginLeft: 0,
                                textAlign: "left",
                              }}
                            >
                              {experience?.dateRange
                                ? experience?.dateRange.split(" · ")[0]
                                : experience?.date_range
                                ? experience?.date_range.split(" · ")[0]
                                : ""}
                            </Text>
                            {experience?.dateRange &&
                              experience?.dateRange.split(" · ")[1] && (
                                <Text
                                  variant="body1"
                                  fontSize="13px"
                                  fontWeight={400}
                                  lineHeight="13px"
                                  letterSpacing="-0.01em"
                                  color="#9A9FA5"
                                  sx={{
                                    marginBottom: "20px",
                                    paddingRight: "10px",
                                    marginLeft: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  {experience?.dateRange.split(" · ")[1]}
                                </Text>
                              )}
                            {experience?.date_range &&
                              experience?.date_range.split(" · ")[1] && (
                                <Text
                                  variant="body1"
                                  fontSize="13px"
                                  fontWeight={400}
                                  lineHeight="13px"
                                  letterSpacing="-0.01em"
                                  color="#9A9FA5"
                                  sx={{
                                    marginBottom: "20px",
                                    paddingRight: "10px",
                                    marginLeft: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  {experience?.date_range &&
                                    experience?.date_range.split(" · ")[1]}
                                </Text>
                              )}
                          </Grid>
                          <Grid item xs={12} md={10}>
                            <Text
                              variant="body1"
                              fontSize="15px"
                              fontWeight={700}
                              lineHeight="15px"
                              letterSpacing="-0.01em"
                              color="#1A1D1F"
                              sx={{
                                marginBottom: "10px",
                                marginLeft: 0,
                                textAlign: "left",
                              }}
                            >
                              {experience?.jobTitle
                                ? experience?.jobTitle
                                : experience?.title}
                            </Text>
                            <Text
                              variant="body1"
                              fontSize="13px"
                              fontWeight={500}
                              lineHeight="13px"
                              letterSpacing="-0.01em"
                              color="#1A1D1F"
                              sx={{
                                marginBottom: "10px",
                                marginLeft: 0,
                                textAlign: "left",
                              }}
                            >
                              {experience?.companyName
                                ? experience?.companyName
                                : experience?.company_name}
                            </Text>
                            <Text
                              variant="body1"
                              fontSize="14px"
                              fontWeight={400}
                              lineHeight="150%"
                              letterSpacing="-0.01em"
                              color="#9A9FA5"
                              sx={{
                                marginBottom: "24px",
                                marginLeft: 0,
                                textAlign: "left",
                              }}
                            >
                              {experience?.description}
                            </Text>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  <Divider
                    sx={{
                      borderColor: "#EFEFEF",
                      borderRadius: "1px",
                      marginBottom: "20px",
                    }}
                  />
                </ExperienceBox>
              )}
            {Array.isArray(candidate?.educations) &&
              candidate?.educations?.length > 0 && (
                <EducationBox>
                  <Text
                    variant="body1"
                    fontSize="13px"
                    fontWeight={400}
                    lineHeight="13px"
                    letterSpacing="-0.01em"
                    color="#9A9FA5"
                    sx={{
                      marginBottom: "20px",
                      marginLeft: 0,
                      textAlign: "left",
                    }}
                  >
                    Education
                  </Text>
                  {Array.isArray(candidate?.educations) &&
                    candidate?.educations?.map((education) => (
                      <Box
                        key={`${education?.degree_title}-${education?.date_range}`}
                        sx={{ flexGrow: 1 }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={2}>
                            <Text
                              variant="body1"
                              fontSize="13px"
                              fontWeight={400}
                              lineHeight="13px"
                              letterSpacing="-0.01em"
                              color="#9A9FA5"
                              sx={{
                                marginBottom: "20px",
                                paddingRight: "10px",
                                marginLeft: 0,
                                textAlign: "left",
                              }}
                            >
                              {education?.date_range}
                            </Text>
                          </Grid>
                          <Grid item xs={12} md={10}>
                            <Text
                              variant="body1"
                              fontSize="15px"
                              fontWeight={700}
                              lineHeight="15px"
                              letterSpacing="-0.01em"
                              color="#1A1D1F"
                              sx={{
                                marginBottom: "10px",
                                marginLeft: 0,
                                textAlign: "left",
                              }}
                            >
                              {education?.degree_title}
                            </Text>
                            <Text
                              variant="body1"
                              fontSize="13px"
                              fontWeight={500}
                              lineHeight="13px"
                              letterSpacing="-0.01em"
                              color="#1A1D1F"
                              sx={{
                                marginBottom: "24px",
                                marginLeft: 0,
                                textAlign: "left",
                              }}
                            >
                              {education?.institution_name}
                            </Text>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  <Divider
                    sx={{
                      borderColor: "#EFEFEF",
                      borderRadius: "1px",
                      marginBottom: "20px",
                    }}
                  />
                </EducationBox>
              )}
          </ModalContentBody>
          <ModalFooter></ModalFooter>
        </ContentContainer>
        <Box
          style={{
            width: "20%",
            paddingTop: "411px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconContainer onClick={() => switchCandidate("next")}>
            <img width={48} height={48} src={CandidateNext} alt="Next" />
          </IconContainer>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default CandidateDetails;
