import { ICompany } from "types/types";
import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { ICreateCompanyPayload } from "./interface";
import { collection, db, doc, serverTimestamp, setDoc } from "utils/firebase";

export const doCreateCompanyAction: AsyncThunk<
  ICompany,
  ICreateCompanyPayload,
  {}
> = createAsyncThunk("company/create", async (payload, { rejectWithValue }) => {
  try {
    const companiesRef = collection(db, "v2_company");
    const company_id = doc(companiesRef).id;
    const company: ICompany = {
      ...payload.company,
      company_id,
    };

    const companyRef = doc(db, `v2_company/${company_id}`);
    await setDoc(companyRef, { ...company, created_at: serverTimestamp() });

    return company;
  } catch (error: unknown) {
    return rejectWithValue(JSON.stringify(error));
  }
});
