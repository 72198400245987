/* eslint-disable prettier/prettier */
import React, { useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthForm from "components/auth-form/auth-form";
import Text from "components/shared/text/text";
import Input from "components/shared/input/input";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import STYLES from "./styles";
import SharedButton from "components/shared/button/button";
import { IndustryTypes } from "constants/constants";
import Form from "components/shared/form/form";
import { IndustrySchema } from "utils/form-schema";
import { useAppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { doUpdateCompanyAction } from "redux/services/company/update/update-company.actions";
import { ROUTE_WELCOME } from "routes/route-items";
import { doActionFetchUserAction } from "redux/services/user/user.actions";
import { _get, isEmpty } from "utils/lodash";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import ToastMessage from "components/toast-message";
import { toast } from "react-toastify";
import { doGetCompanyAction } from "redux/services/company/get/get.actions";
import { doUpdateUserAction } from "redux/services/user/update/update.actions";
import { auth } from "utils/firebase";

const Building = () => {
  const { register, handleSubmit, control, trigger } = useForm({
    resolver: yupResolver(IndustrySchema),
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reduxState = useSelector(
    (reduxState: RootState) => reduxState.updateCompany || {}
  );
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const industryName = useWatch({
    control,
    name: "modelAndIndustry",
  });

  const [selectedIndustries, setSelectedIndustries] = React.useState<
    string[] | null
  >([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [error, setError] = React.useState("");
  const theme = useTheme();

  const industries: string[] = useMemo(() => {
    return IndustryTypes.filter((industry: string) => {
      if (searchQuery?.length) {
        return industry.toLowerCase().includes(searchQuery.toLowerCase());
      } else {
        return industry.toLowerCase();
      }
    });
  }, [searchQuery]);

  React.useEffect(() => {
    if (industryName?.length) {
      setSearchQuery(industryName);
    } else {
      setSearchQuery(industryName);
    }
  }, [industryName]);

  const updateSelectedIndustries = (value: string) => {
    if (selectedIndustries) {
      if (selectedIndustries.includes(value)) {
        setSelectedIndustries(
          selectedIndustries.filter((industry) => industry !== value)
        );
      } else {
        setSelectedIndustries([...selectedIndustries, value]);
      }
    } else {
      setSelectedIndustries([value]);
    }
  };

  const onSubmit = async () => {
    if (userState) {
      await auth.getAuth().currentUser?.getIdTokenResult(true);
      const resp = await dispatch(
        doUpdateCompanyAction({
          company_id: userState.company_id,
          company: {
            industries: selectedIndustries || [],
          },
        })
      );

      await dispatch(
        doUpdateUserAction({
          uid: userState.uid,
          user: {
            next_onboarding_stage: ROUTE_WELCOME,
          },
        })
      );

      const comp_resp = _get(resp, "payload.message.data.message") || {};
      const company_meta =
        _get(resp, "payload.message.data.message._company.message") || {};
      if (comp_resp.company_id) {
        await dispatch(
          doGetCompanyAction({
            company_id: comp_resp.company_id,
            company: {
              ...comp_resp,
              ...company_meta,
              company_id: comp_resp.company_id,
              company_name: userState.company_name,
              industries: [],
            },
          })
        );
      }
      let user_data = {
        ...userState,
        next_onboarding_stage: ROUTE_WELCOME,
      };

      await dispatch(
        doActionFetchUserAction({
          uid: userState.uid,
          user: user_data,
        })
      );
      navigate(`/${ROUTE_WELCOME}`);
    } else {
      toast(<ToastMessage title="Error" body="Missing parameters" />, {
        type: "error",
        position: "top-right",
      });
    }
  };

  const ContentForm = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  return (
    <AuthForm
      title=""
      id="building"
      sx={{ width: "100%" }}
      currentStep={2}
      stepsStyles={{
        width: "500px",
        height: "2rem",
      }}
    >
      <ContentForm
        onSubmit={(e) => {
          if (isEmpty(selectedIndustries)) {
            setError("Pick at least one industry");
            return trigger("modelAndIndustry");
          }
          handleSubmit(onSubmit)(e);
        }}
      >
        <Box
          sx={{
            mx: "auto",
            width: "500px",
            textAlign: "left",
          }}
        >
          <Text
            variant="h2"
            fontSize="48px"
            fontWeight={600}
            lineHeight="48px"
            letterSpacing="-0.03em"
            sx={{
              mb: "2rem",
              mx: "auto",
              width: "500px",
              textAlign: "left",
            }}
          >
            What are you building?
          </Text>
          <Text
            variant="body1"
            fontFamily={"Inter"}
            fontStyle={"normal"}
            fontWeight={400}
            fontSize={"14px"}
            lineHeight={"1.5"}
            color={theme.palette.grey[400]}
            marginBottom={"20px"}
            sx={{
              textAlign: "left",
              width: "500px",
              mx: "auto",
            }}
          >
            This helps us match you with relevant candidates. This is 1 of 3
            steps in kicking off your job with us.
          </Text>
          <Input
            iconStart={
              <SearchOutlinedIcon
                sx={{ color: theme.palette.text.primary, fontSize: 25 }}
              />
            }
            fullWidth
            id="modelAndIndustry"
            register={register}
            errors={error}
            sx={{
              mb: "20px",
              mx: "auto",
              width: "100%",
              textAlign: "left",
            }}
            autoFocus
            placeholder="Type to find or select below"
            variant="filled"
          />
        </Box>
        <Box
          sx={[
            {
              ...STYLES.choicesScrollContainer,
            },
            (theme) => ({
              [theme.breakpoints.between("sm", "md")]: {
                width: "100%",
              },
              [theme.breakpoints.down("xl")]: {
                width: "500px",
              },
            }),
          ]}
        >
          <Box
            sx={{
              ...STYLES.choicesContainer,
              marginBottom: "-1rem",
            }}
          >
            {industries.map((industry: string, index: number) => (
              <Box
                key={index}
                sx={{
                  ...STYLES.buttonChoice,
                  ...(selectedIndustries?.includes(industry) && {
                    ...STYLES.buttonChoiceSelected,
                  }),
                }}
                onClick={(e) => {
                  e.preventDefault();
                  updateSelectedIndustries(industry);
                }}
              >
                {industry}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            width: "500px",
            mx: "auto",
          }}
        >
          <SharedButton
            type="submit"
            variant="contained"
            sx={{
              mx: "auto",
              mt: "20px",
            }}
          >
            <Box
              sx={[
                {
                  marginLeft: "6px",
                  textTransform: "initial",
                  mx: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                (theme) => ({
                  [theme.breakpoints.between("sm", "md")]: {
                    width: "100%",
                  },
                  [theme.breakpoints.down("xl")]: {
                    width: "600px",
                  },
                }),
              ]}
            >
              {reduxState.status === "pending" && (
                <CircularProgress
                  sx={{ marginRight: "10px" }}
                  color="inherit"
                  size="20px"
                />
              )}
              {reduxState.status === "pending" ? "Loading ..." : "Continue"}
            </Box>
          </SharedButton>
        </Box>
      </ContentForm>
    </AuthForm>
  );
};

export default Building;
