import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { IUpdateCompanyPayload } from "./interface";
import { db, doc, setDoc } from "utils/firebase";
import { ICompany } from "types/types";

export const doUpdateCompanyAction: AsyncThunk<
  Partial<ICompany>,
  IUpdateCompanyPayload,
  {}
> = createAsyncThunk("company/update", async (payload, { rejectWithValue }) => {
  try {
    const companyRef = doc(db, `v2_company/${payload.company_id}`);
    await setDoc(companyRef, payload.company, { merge: true });

    return payload.company;
  } catch (error: unknown) {
    return rejectWithValue(JSON.stringify(error));
  }
});
