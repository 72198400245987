import { yupResolver } from "@hookform/resolvers/yup";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as LockIcon } from "assets/images/lock.svg";
import { ReactComponent as Person } from "assets/images/person.svg";
import { ReactComponent as Warning } from "assets/images/warning.svg";
import AuthForm from "components/auth-form/auth-form";
import SharedButton from "components/shared/button/button";
import Form from "components/shared/form/form";
import Input from "components/shared/input/input";
import Text from "components/shared/text/text";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SignUpSchema } from "utils/form-schema";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { Asterisk } from "components/shared/asterisk";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { _get } from "utils/lodash";

interface IState {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  company_name?: string;
  isLoading?: boolean;
  next_onboarding_stage?: string;
  terms: boolean;
}

interface IProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: IState) => void;
  state: IState;
  reduxStatus: string;
  // eslint-disable-next-line no-unused-vars
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

interface CheckboxProps {
  errors?: string;
}

const FormContainer = styled(Form)`
  max-width: 330px;
  align-items: center;
`;

const CustomCheckbox = styled(Checkbox)<CheckboxProps>`
  color: ${({ theme, errors }) => {
    if (errors?.length) return _get(theme, "palette.colors.red");
  }};
`;

const CompleteSignUp: React.FC<IProps> = ({
  onSubmit,
  state,
  reduxStatus,
  handleCheckboxChange,
  checked,
}) => {
  const theme = useTheme();
  const [touched, setTouched] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IState>({
    resolver: yupResolver(SignUpSchema),
  });

  useEffect(() => {
    setValue("email", state.email);
  }, [state.email]);

  return (
    <AuthForm
      id="complete-sign-up"
      title="Complete Sign up"
      sx={{ maxWidth: "54%" }}
    >
      <Box display="flex" flexDirection="column" sx={{ maxWidth: "90%" }}>
        <Text
          fontSize="14px"
          lineHeight="150%"
          fontWeight={400}
          letterSpacing="-0.01em"
          sx={{
            marginLeft: 0,
            marginTop: ".5rem",
            marginBottom: "1.3rem",
            color: theme.palette.text.primary,
            textAlign: "left",
          }}
        >
          Let’s set up your account
        </Text>
        <FormContainer
          onSubmit={(e) => {
            setTouched(true);
            handleSubmit(onSubmit)(e);
          }}
        >
          <Input
            iconStart={
              <MailOutlineIcon
                sx={{ color: theme.palette.text.primary, fontSize: 25 }}
              />
            }
            iconEnd={errors?.email?.message ? <Warning /> : null}
            sx={{
              marginBottom: "20px",
              position: "relative",
            }}
            placeholder="Your work email"
            variant="filled"
            id="email"
            register={register}
            errors={errors?.email?.message}
            disabled={!!state.company_name}
          />
          <Input
            iconStart={<LockIcon />}
            iconEnd={errors?.password?.message ? <Warning /> : null}
            sx={{
              marginBottom: "20px",
              position: "relative",
            }}
            type="password"
            placeholder="Password"
            variant="filled"
            id="password"
            register={register}
            errors={errors?.password?.message}
          />
          <Input
            iconStart={<Person />}
            iconEnd={errors?.firstName?.message ? <Warning /> : null}
            sx={{
              marginBottom: "20px",
              position: "relative",
            }}
            placeholder="First Name"
            variant="filled"
            id="firstName"
            register={register}
            errors={errors?.firstName?.message}
          />
          <Input
            iconStart={<Person />}
            iconEnd={errors?.lastName?.message ? <Warning /> : null}
            sx={{
              marginBottom: "20px",
              position: "relative",
            }}
            placeholder="Last Name"
            variant="filled"
            id="lastName"
            register={register}
            errors={errors?.lastName?.message}
          />
          <Box
            display="flex"
            sx={{
              marginBottom: "20px",
              alignItems: "flex-start",
              marginTop: "0.5rem",
              marginLeft: "-0.8rem",
            }}
          >
            <CustomCheckbox
              id="terms"
              checked={checked}
              {...register("terms")}
              onChange={handleCheckboxChange}
              disableRipple
              sx={{
                color: () => {
                  const error = _get(errors, "terms.message");
                  if (touched && error)
                    return _get(theme, "palette.colors.red");
                  return theme.palette.text.primary;
                },
                "&.Mui-checked": {
                  color: theme.palette.secondary.main,
                },
              }}
            />
            <Text
              fontSize="14px"
              fontWeight={400}
              lineHeight="150%"
              letterSpacing="-0.01em"
              sx={{
                maxWidth: "25vw",
                fontStyle: "normal",
                textAlign: "left",
                color: theme.palette.text.primary,
                marginLeft: "0rem",
              }}
            >
              I agree to ACELR8's{" "}
              <Box
                component="a"
                href="#"
                sx={{
                  textDecoration: "underline",
                  color: theme.palette.text.primary,
                  letterSpacing: "-0.01em",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "150%",
                }}
              >
                <Link target={"_blank"} to="/privacy-policy">
                  Privacy Policy
                </Link>
              </Box>{" "}
              and to receive marketing communications.
              <Asterisk />
            </Text>
          </Box>
          <SharedButton type="submit" variant="contained">
            <Box
              sx={{
                marginLeft: "6px",
                textTransform: "initial",
                display: "flex",
                alignItems: "center",
              }}
            >
              {reduxStatus === "pending" || state.isLoading ? (
                <>
                  <CircularProgress
                    sx={{ marginRight: "10px" }}
                    color="inherit"
                    size="20px"
                  />
                  <span>Loading ...</span>
                </>
              ) : (
                "Sign up"
              )}
              {/* // {reduxStatus === "pending" ? "Loading ..." : "Sign up"} */}
            </Box>
          </SharedButton>
        </FormContainer>
      </Box>
    </AuthForm>
  );
};

export default CompleteSignUp;
