import { ICompany } from "types/types";
import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { IGetCompanyPayload } from "./interface";
import { db, doc, getDoc } from "utils/firebase";
import { collection_name } from "utils/firebase-refs";

export const doGetCompanyAction: AsyncThunk<ICompany, IGetCompanyPayload, {}> =
  createAsyncThunk("company/get", async (payload, { rejectWithValue }) => {
    if (payload.company) {
      return {
        ...payload.company,
      };
    }

    try {
      const companyRef = doc(
        db,
        `${collection_name.v2_company}/${payload.company_id}`
      );
      const companySnapshot = await getDoc(companyRef);
      const {
        location,
        name,
        company_id,
        industries,
        uid,
        billingAddress,
        billingEmailAddress,
        vatID,
        work_type,
        total_jobs,
        ats_data,
        jobs_without_criteria,
        role,
        total_candidates,
        original_avatar_url,
        thumbnail_avatar_url,
        created_at,
      } = companySnapshot.data() as ICompany;

      const company: ICompany = {
        location,
        name,
        company_id,
        industries,
        uid,
        billingAddress,
        billingEmailAddress,
        vatID,
        work_type,
        total_jobs,
        jobs_without_criteria,
        role,
        total_candidates,
        original_avatar_url,
        thumbnail_avatar_url,
        created_at,
        ats_data: ats_data
          ? {
              created_at: ats_data.created_at
                ? new Date(ats_data.created_at.seconds * 1000).toISOString()
                : null,
              link_token: {
                created_at: ats_data.link_token?.created_at
                  ? new Date(
                      ats_data.link_token.created_at.seconds * 1000
                    ).toISOString()
                  : null,
                created_by: ats_data?.link_token?.created_by,
                link_token: ats_data?.link_token?.link_token,
              },
              account_token: {
                account_token: ats_data.account_token?.account_token,
                created_at: ats_data.account_token?.created_at
                  ? new Date(
                      ats_data.account_token.created_at.seconds * 1000
                    ).toISOString()
                  : null,
                created_by: ats_data?.account_token?.created_by,
                integration: ats_data?.account_token?.integration,
                public_token: ats_data?.account_token?.public_token,
              },
              company_id: ats_data?.company_id,
            }
          : undefined,
      };

      return company;
    } catch (error: unknown) {
      return rejectWithValue(JSON.stringify(error));
    }
  });
