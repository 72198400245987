/* eslint-disable no-unused-vars */
import * as React from "react";
import PrivateLayout from "layouts/drawer";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SharedButton from "components/shared/button/button";
import Pen from "assets/images/pen.svg";
import Box from "@mui/material/Box";
import BackArrow from "assets/images/arrow_backward.svg";
import SimpleCardArea from "components/shared/card/simple-card-area";
import { _get } from "utils/lodash";
import { useParams, useNavigate } from "react-router-dom";
import SelectButton from "components/job/select-button";
import {
  ROUTE_ACTIVE_JOBS,
  ROUTE_EDIT_JOB,
  ROUTE_INTEGRATIONS,
} from "routes/route-items";
import SharedText from "components/shared/text/text";
import SharedTooltip from "components/shared/tooltip/tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { ITheme } from "theme";
import { useTheme } from "@mui/styles";
import CalendarTodayIconOutlined from "@mui/icons-material/CalendarTodayOutlined";
import CreditsIcon from "assets/images/credits-icon.svg";
import Loader from "assets/images/spinner.svg";
import Candidate from "components/job/candidate";
import NoCandidates from "components/job/no-candidates";
import DateRangePicker from "components/shared/daterangepicker";
import { RootState, useAppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import { doGetCandidatesAction } from "redux/services/candidates/getCandidates/get.actions";
import {
  IConnectedCandidate,
  IInterviewStatus,
  IJob,
  IRoleType,
  TCandidateUpdate,
} from "types/types";
import CandidateDetails from "components/candidates/candidate-details";
import { doCreateJobAction } from "redux/services/job/create/create.actions";
import {
  collection,
  db,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
} from "utils/firebase";
import { collection_name } from "utils/firebase-refs";
import NotCompanyJob from "components/job/not-company-job";
import Light from "assets/images/light.svg";
import ATSJobs from "components/jobs/ats-jobs";
import Dialog from "components/dialog";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import { doLinkJobToATSAction } from "redux/services/ats/link-job-to-ats/get.actions";
import { doGetJobsAction } from "redux/services/job/getJob/get.actions";
import { doUpdateJobAction } from "redux/services/job/update/update.actions";

const Statistics = styled("div")`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 24px;
  border-radius: 8px;
  align-items: center;
  font-family: "Inter";
  margin-top: 24px;
  height: 160px;
`;

const Results = styled(`div`)`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ResultsDivider = styled("div")`
  width: 1px;
  height: 112px;
  background: #efefef;
  border-radius: 1px;
`;

const CandidatesArea = styled("div")`
  background: ${({ theme }) => theme.palette.grey[100]};
  padding: 24px;
  margin-top: 8px;
  border-radius: 8px;
  align-items: center;
  font-family: "Inter";
`;

const Header = styled("div")`
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Spinner = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
`;

const Title = styled(Typography)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

const ButtonGroup = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
`;

const Subtitle = styled("span")`
  color: #6f767e;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  align-items: center;
`;

const Value = styled("div")`
  font-family: Inter;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.03em;
  text-align: left;
  font-size: 48px;
  font-weight: 600;
`;

const Text = styled("span")`
  font-weight: 600;
`;

const FilterGroup = styled(`div`)`
  display: flex;
  justify-content: space-between;
`;

const URL = styled("a")`
  text-decoration: underline;
  color: #6f767e;
  font-size: 13px;
`;

const Candidates = styled("div")`
  margin-top: 32px;
  margin-bottom: 18px;
  color: ${({ theme }) => theme?.palette.grey[400]};
`;

enum Filters {
  PENDING = "PENDING",
  SHORTLISTED = "SHORTLISTED",
  REJECTED = "REJECTED",
  ALL = "ALL",
}

const TitleIcon: any = {
  "Software Engineering": "💻 ",
  "Product Design": "🎨 ",
  Marketing: "📢 ",
  Sales: "☎️ ",
  "Product Management": "📈 ",
  "Customer Success": "🤝 ",
  Other: "✍️ ️",
};

type TCompayJob = "TRUE" | "FALSE" | "PENDING";

const Job = () => {
  const dispatch = useAppDispatch();
  const theme: ITheme = useTheme();
  const navigate = useNavigate();
  const { id, candidateid } = useParams();

  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const candidatesState = useSelector(
    (reduxState: RootState) => reduxState.candidatesGet.response
  );
  const companyState = useSelector(
    (reduxState: RootState) => reduxState.getCompany.response
  );
  const jobsGetState = useSelector(
    (reduxState: RootState) => reduxState.jobsGet.response
  );
  const candidates = candidatesState.candidates || [];

  const updated_by: TCandidateUpdate = {
    uid: userState.uid,
    role: IRoleType.CLIENT,
    firstName: userState.firstName,
    lastName: userState.lastName,
    updated_at: serverTimestamp(),
  };

  const initialCandidate: IConnectedCandidate = {
    firstName: "",
    lastName: "",
    email: "",
    created_at: null,
    updated_at: null,
    candidate_id: "",
    candidate_avatar_url: "",
    candidate_experiences: [],
    connected_links: [],
    educations: [],
    experience: [],
    selectedJobs: [],
    skills: [],
    currency: "",
    experience_level: "",
    is_open_to_remote: false,
    is_archived: false,
    creation_type: "",
    jobTitle: "",
    job_function: "",
    job_id: "",
    company_id: "",
    linked_in: "",
    linkedin_id: "",
    original_avatar_url: "",
    recruiter_notes: "",
    location: "",
    portfolio: "",
    thumbnail_avatar_url: "",
    reasonForNotRelevance: "",
    source: "",
    hasAttachedCV: false,
    max_salary_per_year: 0,
    min_salary_per_year: 0,
    file: "",
    interview_status: IInterviewStatus.PENDING,
    reason: "",
    updated_by,
  };

  const [job, setJob] = React.useState<IJob>({});
  const [candidatesList, setCandidatesList] = React.useState<
    IConnectedCandidate[]
  >([]);
  const [filter, setFilter] = React.useState<Filters>(Filters.ALL);
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [openModal, setOpenModal] = React.useState(false);
  const [loadingStatus, setLoadingStatus] = React.useState(true);
  const [candidateURL, setCandidateURL] = React.useState<string | null>(null);
  const [selectedCandidate, setSelectedCandidate] =
    React.useState(initialCandidate);
  const [companyJob, setCompanyJob] = React.useState<TCompayJob>("PENDING");
  const [unlinkATS, toggleUnlinkATSDialog] = React.useState(false);
  const [unlinkATSLoading, toggleUnlinkATSLoading] = React.useState(false);
  const [isLinkedToATS, setIsLinkedToATS] = React.useState(false);
  const [openConnectATSDialog, toggleOpenConnectATSDialog] =
    React.useState(false);
  const [openATSDialog, toggleATSDialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (job.linked_ats_job_details) {
      setIsLinkedToATS(true);
    } else {
      setIsLinkedToATS(false);
    }
  }, [job.linked_ats_job_details]);

  const handleModalOpen = (candidate: IConnectedCandidate) => {
    setOpenModal(true);
    setSelectedCandidate(candidate);
    setCandidateURL(candidate.candidate_id);
    navigate(
      `/${ROUTE_ACTIVE_JOBS}/${id}/candidate/${candidate.candidate_id}`,
      {
        state: {
          from: `${ROUTE_ACTIVE_JOBS}/${id}`,
        },
      }
    );
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const button_id = open ? "calendar-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const interested_candidates =
    (job?.attached_candidates?.total || 0) -
    (job?.attached_candidates?.archived || 0);

  const handleFilter = (
    filter: Filters,
    startDate: Date | null,
    endDate: Date | null
  ) => {
    setFilter(filter);
    const filter_ = filter as unknown;
    const start_date = startDate as Date;
    let end_date = endDate as Date;
    end_date?.setHours(23, 59, 59, 999);
    const start_date_milliseconds = start_date?.getTime();
    const end_date_milliseconds = end_date?.getTime();
    const list =
      filter === Filters.ALL
        ? candidates?.filter((candidate: IConnectedCandidate) => {
            if (startDate && endDate) {
              return (
                candidate.created_at >= start_date_milliseconds &&
                candidate.created_at <= end_date_milliseconds
              );
            }
            return candidate;
          })
        : candidates?.filter((candidate: IConnectedCandidate) => {
            if (startDate && endDate) {
              return (
                candidate.created_at >= start_date_milliseconds &&
                candidate.created_at <= end_date_milliseconds &&
                candidate.interview_status === filter_
              );
            }
            return candidate.interview_status === filter_;
          });
    setCandidatesList(list);
  };

  const handleModalClose = () => {
    setStartDate(null);
    setEndDate(null);
    handleFilter(Filters.ALL, null, null);
    setOpenModal(false);
    setSelectedCandidate(initialCandidate);
    setCandidateURL(null);
    navigate(`/${ROUTE_ACTIVE_JOBS}/${id}`, {
      state: {
        from: `${ROUTE_ACTIVE_JOBS}/${id}/candidate/${candidateURL}`,
      },
    });
  };

  const onDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      handleFilter(filter, start, end);
      handleClose();
    }
  };

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    handleFilter(filter, null, null);
    handleClose();
  };

  const switchCandidate = (move: "previous" | "next") => {
    const currentIndex = candidatesList?.indexOf(selectedCandidate);
    const firstCandidate = move === "previous" && currentIndex === 0;
    const lastCandidate =
      move === "next" && currentIndex === candidatesList.length - 1;

    if (firstCandidate || lastCandidate) {
      return;
    }

    let candidate: IConnectedCandidate;

    if (move === "next") {
      candidate = candidatesList[currentIndex + 1];
    } else {
      candidate = candidatesList[currentIndex - 1];
    }

    setSelectedCandidate(candidate);
    navigate(
      `/${ROUTE_ACTIVE_JOBS}/${id}/candidate/${candidate.candidate_id}`,
      {
        state: {
          from: `${ROUTE_ACTIVE_JOBS}/${id}/candidate/${selectedCandidate.candidate_id}`,
        },
      }
    );
  };

  const handleClickEditJob = async () => {
    await dispatch(
      doCreateJobAction({
        job: {
          seniority_level: job.seniority_level,
          job_function: job.job_function,
          custom_job_function: job.custom_job_function,
          job_description_link: job.job_description_link,
          job_description: job.job_description,
          job_title: job.job_title,
          company_id: job.company_id || "",
          company_name: job.company_name || "",
          created_by: job.created_by || "",
          skills_nice_to_have: job.skills_nice_to_have,
          skills_not_to_have: job.skills_not_to_have,
          skills_must_have: job.skills_must_have,
          job_exciting_things: job.job_exciting_things,
          salary_range: job.salary_range,
          selected_currency: job.selected_currency,
          checkbox_pay_unknown: job.checkbox_pay_unknown,
          checkbox_share_salary_range: job.checkbox_share_salary_range,
          recruiter_notes: job.recruiter_notes,
        },
      })
    );
    navigate(`/${ROUTE_EDIT_JOB}`, {
      state: {
        from: `${ROUTE_ACTIVE_JOBS}/${id}`,
        uid: job.created_by,
        job_id: job.job_id,
      },
    });
  };

  React.useEffect(() => {
    const pathname = location.pathname;
    if (candidateid && candidates?.length) {
      const cand = candidates?.find(
        (c: IConnectedCandidate) => c.candidate_id === candidateid
      ) as IConnectedCandidate;
      if (cand) {
        setSelectedCandidate(cand);
      }
      if (pathname.includes("/candidate/")) {
        setOpenModal(true);
      }
    }
  }, [id, candidates]);

  const fetchCandidates = React.useCallback(async () => {
    const job_id = id as string;
    const candidatesQuery = query(
      collection(db, `v2_jobs/${job_id}/connected_candidates`),
      orderBy("created_at", "desc")
    );
    const unsubscribe = onSnapshot(candidatesQuery, async (querySnapshot) => {
      const candidates = querySnapshot.docs.map((doc) => {
        let candidate = doc.data() as IConnectedCandidate;
        candidate = {
          ...candidate,
          created_at: candidate.created_at?.seconds * 1000,
          updated_at: candidate.updated_at?.seconds * 1000,
        };
        return candidate;
      });
      await dispatch(
        doGetCandidatesAction({
          job_id,
          candidates,
        })
      );
      setLoadingStatus(false);
    });
    return () => {
      unsubscribe();
    };
  }, [id]);

  React.useEffect(() => {
    fetchCandidates();
  }, [fetchCandidates]);

  const fetchJob = React.useCallback(async () => {
    const unsubscribe = onSnapshot(
      doc(db, `${collection_name.v2_jobs}/${id}`),
      (doc) => {
        const job_data: IJob = { ...doc.data(), job_id: doc.id };
        if (job_data.company_id === userState.company_id) {
          setJob(job_data);
          setCompanyJob("TRUE");
        } else {
          setCompanyJob("FALSE");
        }
      },
      (error) => {
        if (error.code === "permission-denied") {
          setCompanyJob("FALSE");
        }
      }
    );
    return () => {
      unsubscribe();
    };
  }, [id, userState.company_id]);

  React.useEffect(() => {
    fetchJob();
  }, [fetchJob]);

  React.useEffect(() => {
    handleFilter(filter, startDate, endDate);
  }, [candidates]);

  const handleBack = () => {
    navigate(`/${ROUTE_ACTIVE_JOBS}`);
  };

  const navigateToIntegration = () => {
    navigate(`/${ROUTE_INTEGRATIONS}`);
  };

  const linkToATS = () => {
    toggleATSDialog(!openATSDialog);
  };

  const handleLinkToATS = () => {
    if (companyState?.ats_data?.account_token) {
      if (isLinkedToATS) {
        toggleUnlinkATSDialog(true);
        return;
      }
      return linkToATS();
    } else {
      toggleOpenConnectATSDialog(true);
    }
  };

  const handleSaveATSJob = async (data: { [key: string]: string }) => {
    setIsLoading(true);
    if (job.job_id && job.company_id) {
      const platform = companyState?.ats_data?.account_token?.integration
        ?.slug as string;
      await dispatch(
        doLinkJobToATSAction({
          company_id: job.company_id,
          job_id: job.job_id,
          created_by: job.created_by || "",
          ats_jobId: data.ats_jobId,
          ats_jobStatus: data.ats_jobStatus,
          ats_jobTitle: data.ats_jobTitle,
          platform_name: platform,
        })
      );
      let curr_jobs: IJob[] = [];
      if (jobsGetState?.jobs?.length) {
        jobsGetState.jobs.map((el) => {
          if (el.job_id === job.job_id) {
            curr_jobs.push({
              ...el,
              linked_ats_job_details: {
                ats_jobId: data.ats_jobId,
                ats_jobStatus: data.ats_jobStatus,
                ats_jobTitle: data.ats_jobTitle,
              },
            });
          } else {
            curr_jobs.push({
              ...el,
            });
          }
        });
      } else {
        curr_jobs = jobsGetState.jobs || [];
      }

      await dispatch(
        doGetJobsAction({
          ...jobsGetState,
          company_id: job.company_id,
          totalPages: jobsGetState.totalPages,
          page: jobsGetState.page,
          totalJobs: jobsGetState.totalJobs,
          hasMore: jobsGetState.hasMore || false,
          jobs: curr_jobs,
        })
      );
      setIsLinkedToATS(true);
      toggleATSDialog(false);
      toast.success(
        <ToastMessage
          title="Successfully linked to ATS"
          body="This job has been successfully linked to ATS"
        />,
        { type: "success", position: "top-right" }
      );
    } else {
      setIsLoading(false);
      toast.error(
        <ToastMessage
          title="Error linking to ATS"
          body="There was an error, please try again."
        />,
        { type: "error", position: "top-right" }
      );
    }
    setIsLoading(false);
  };

  const handleUnlinkJob = async () => {
    toggleUnlinkATSLoading(true);

    await dispatch(
      doUpdateJobAction({
        job_id: job.job_id || "",
        job: {
          linked_ats_job_details: null,
        },
      })
    );

    let curr_jobs: IJob[] = [];

    if (jobsGetState?.jobs?.length) {
      jobsGetState.jobs.map((el) => {
        if (el.job_id === job.job_id) {
          curr_jobs.push({
            ...el,
            linked_ats_job_details: null,
          });
        } else {
          curr_jobs.push({
            ...el,
          });
        }
      });
    } else {
      curr_jobs = jobsGetState.jobs || [];
    }
    await dispatch(
      doGetJobsAction({
        ...jobsGetState,
        company_id:
          job.company_id ||
          companyState.company_id ||
          userState.company_id ||
          "",
        totalPages: jobsGetState.totalPages,
        page: jobsGetState.page,
        totalJobs: jobsGetState.totalJobs,
        hasMore: jobsGetState.hasMore || false,
        jobs: curr_jobs,
      })
    );

    toggleUnlinkATSLoading(false);
    toggleUnlinkATSDialog(false);

    toast.success(
      <ToastMessage
        title="Successfully unlinked from ATS"
        body="This job has been successfully unlinked from ATS"
      />,
      { type: "success", position: "top-right" }
    );
  };

  const calendlyLink = "https://calendly.com/ricardo-meeting/30min";

  return (
    <PrivateLayout>
      <Header>
        <Title>
          <span>{TitleIcon[`${job?.job_function}`]}</span> {job?.job_title}
        </Title>
        <ButtonGroup>
          <SharedButton
            sx={{
              width: "auto",
              background: "white",
              border: "1px solid #EFEFEF",
              color: "#1A1D1F",
              height: "48px",
              "&:hover": {
                background: (theme) => _get(theme, "palette.colors.light_grey"),
                border: (theme) =>
                  `1px solid ${_get(theme, "palette.colors.light_grey")}`,
              },
            }}
            variant="contained"
            onClick={handleBack}
          >
            <img src={BackArrow} alt="Edit" />
            <Box sx={{ marginLeft: "8px", textTransform: "initial" }}>Back</Box>
          </SharedButton>
          <SharedButton
            sx={{
              width: "auto",
              background: "white",
              border: "1px solid #EFEFEF",
              color: "#1A1D1F",
              height: "48px",
              "&:hover": {
                background: (theme) => _get(theme, "palette.colors.light_grey"),
                border: (theme) =>
                  `1px solid ${_get(theme, "palette.colors.light_grey")}`,
              },
            }}
            variant="contained"
            onClick={() => {
              // handleLinkToATS
              if (companyState?.ats_data?.account_token) {
                if (isLinkedToATS) {
                  toggleUnlinkATSDialog(true);
                } else {
                  return handleLinkToATS();
                }
              } else {
                toggleOpenConnectATSDialog(true);
              }
            }}
          >
            <img src={Light} alt="Edit" />
            <Box sx={{ marginLeft: "8px", textTransform: "initial" }}>
              {isLinkedToATS && companyState?.ats_data?.account_token
                ? "Unlink job from ATS"
                : "Link job to ATS"}
            </Box>
          </SharedButton>
          {companyJob && (
            <SharedButton
              onClick={handleClickEditJob}
              sx={{ width: "auto", height: "48px" }}
              variant="contained"
            >
              <img src={Pen} alt="Edit" />
              <Box sx={{ marginLeft: "8px", textTransform: "initial" }}>
                Edit Job Posting
              </Box>
            </SharedButton>
          )}
        </ButtonGroup>
      </Header>

      {loadingStatus ? (
        <Spinner>
          <img className="jobs-loader__spinner" src={Loader} alt="spinner" />
        </Spinner>
      ) : (
        <>
          {companyJob === "TRUE" ? (
            <>
              <Statistics>
                <Results>
                  <Subtitle>
                    Interested Candidates
                    <SharedTooltip title="This shows the total number of candidates that were sourced by our recruiters and have expressed their interest in having a first call.">
                      <InfoIcon
                        sx={{
                          ml: "5px",
                          height: "13.33px",
                          width: "13.33px",
                          left: "1.33",
                          top: "1.33px",
                          bordeRadius: "0px",
                        }}
                      />
                    </SharedTooltip>
                  </Subtitle>
                  <Value>{interested_candidates}</Value>
                </Results>
                <ResultsDivider />
                <Results>
                  <Subtitle>
                    Candidates to Review
                    <SharedTooltip title="This shows the number of interested candidates you still need to either choose to interview or reject.">
                      <InfoIcon
                        sx={{
                          ml: "5px",
                          height: "13.33px",
                          width: "13.33px",
                          left: "1.33",
                          top: "1.33px",
                          bordeRadius: "0px",
                        }}
                      />
                    </SharedTooltip>
                  </Subtitle>
                  <Value>{job?.attached_candidates?.PENDING || 0}</Value>
                </Results>
                <ResultsDivider />
                <Results>
                  <Subtitle>
                    Shortlisted Candidates
                    <SharedTooltip title="This shows the number of candidates that you chose to interview, out of all interested candidates for this role.">
                      <InfoIcon
                        sx={{
                          ml: "5px",
                          height: "13.33px",
                          width: "13.33px",
                          left: "1.33",
                          top: "1.33px",
                          bordeRadius: "0px",
                        }}
                      />
                    </SharedTooltip>
                  </Subtitle>
                  <Value>{job?.attached_candidates?.SHORTLISTED || 0}</Value>
                </Results>
                <ResultsDivider />
                <Results>
                  <Subtitle>Total Shortlisted Candidates Needed</Subtitle>
                  <Value>{job?.attached_candidates?.NEEDED || 0}</Value>
                </Results>
                <Results>
                  <SimpleCardArea
                    sx={{
                      background:
                        "linear-gradient(128.07deg, rgba(26, 29, 31, 0.05) 1.42%, rgba(0, 0, 0, 0.05) 99.33%)",
                      width: "227px",
                      height: "128px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img
                      width={40}
                      height={40}
                      src={CreditsIcon}
                      alt="credits icon"
                    />
                    <Text
                      sx={{
                        fontFamily: "Inter",
                        color: "#6F767e",
                        fontWeight: 600,
                        lineHeight: "32px",
                        letterSpacing: "-0.02em",
                        textAlign: "left",
                      }}
                    >
                      Get more candidates
                    </Text>
                    <URL onClick={() => window.open(calendlyLink, "_blank")}>
                      Purchase more credits
                    </URL>
                  </SimpleCardArea>
                </Results>
              </Statistics>
              {candidates.length > 0 ? (
                <CandidatesArea>
                  <FilterGroup>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          width: "16px",
                          height: "auto",
                          margin: "0px 16px 0px 0px",
                          background: theme?.custom.colors.green,
                          borderRadius: "4px",
                        }}
                      />
                      <SelectButton
                        onClick={() =>
                          handleFilter(Filters.ALL, startDate, endDate)
                        }
                        className={filter === Filters.ALL ? "selected" : ""}
                        aria-describedby={button_id}
                      >
                        All
                      </SelectButton>
                      <SelectButton
                        onClick={() =>
                          handleFilter(Filters.PENDING, startDate, endDate)
                        }
                        className={filter === Filters.PENDING ? "selected" : ""}
                      >
                        Pending
                      </SelectButton>
                      <SelectButton
                        onClick={() =>
                          handleFilter(Filters.SHORTLISTED, startDate, endDate)
                        }
                        className={
                          filter === Filters.SHORTLISTED ? "selected" : ""
                        }
                      >
                        Shortlisted
                      </SelectButton>
                      <SelectButton
                        onClick={() =>
                          handleFilter(Filters.REJECTED, startDate, endDate)
                        }
                        className={
                          filter === Filters.REJECTED ? "selected" : ""
                        }
                      >
                        Rejected
                      </SelectButton>
                    </Box>
                    <Box>
                      <SelectButton
                        sx={{
                          background: "#fcfcfc",
                        }}
                        onClick={handleOpen}
                      >
                        {startDate && endDate
                          ? `${new Date(
                              startDate
                            ).toLocaleDateString()} - ${new Date(
                              endDate
                            ).toLocaleDateString()}`
                          : "Date"}
                        <CalendarTodayIconOutlined
                          sx={{
                            ml: "5px",
                            height: "15px",
                            width: "15px",
                            left: "1.5px",
                            top: "1.5px",
                            color: " #6f767e",
                          }}
                        />
                      </SelectButton>
                    </Box>
                  </FilterGroup>

                  <DateRangePicker
                    handleClose={handleClose}
                    open={open}
                    onChange={onDateRangeChange}
                    startDate={startDate}
                    endDate={endDate}
                    clearDates={clearDates}
                    anchorEl={anchorEl}
                  />
                  <Candidates>
                    {candidatesList?.length} candidate
                    {candidatesList?.length !== 1 && <span>s</span>}
                  </Candidates>

                  {candidatesList?.map((candidate) => (
                    <Candidate
                      onClick={() => handleModalOpen(candidate)}
                      key={candidate.candidate_id}
                      candidate={candidate}
                      candidates={candidates}
                      updated_by={updated_by}
                    />
                  ))}
                  <CandidateDetails
                    candidate={selectedCandidate}
                    handleClose={handleModalClose}
                    updated_by={updated_by}
                    open={openModal}
                    switchCandidate={switchCandidate}
                    candidatesList={candidatesList}
                  />
                </CandidatesArea>
              ) : (
                <>
                  {/* {candidatesReduxState.status === "fulfilled" && ( */}
                  <NoCandidates />
                  {/* )} */}
                </>
              )}
            </>
          ) : companyJob === "FALSE" ? (
            <NotCompanyJob />
          ) : null}
        </>
      )}
      <ATSJobs
        openDialog={openATSDialog}
        handleCloseDialog={() => toggleATSDialog(false)}
        handleAcceptDialog={handleSaveATSJob}
        isLoading={isLoading}
      />

      <Dialog
        openDialog={unlinkATS}
        isLoading={unlinkATSLoading}
        handleCloseDialog={() => toggleUnlinkATSDialog(false)}
        handleAcceptDialog={handleUnlinkJob}
        title="Confirm your action"
        body="Are you sure you want to unlink this job?"
      />
      <Dialog
        openDialog={openConnectATSDialog}
        isLoading={false}
        handleCloseDialog={() => toggleOpenConnectATSDialog(false)}
        handleAcceptDialog={navigateToIntegration}
        canCloseDialog={false}
        submitButtonText="Setup ATS integration"
        showCloseIcon={true}
        title={
          <SharedText
            fontSize="22px"
            lineHeight="24px"
            fontWeight={500}
            letterSpacing="-0.01em"
            color={"#003350"}
            sx={{
              textAlign: "left",
            }}
          >
            Switch on your ATS integration
          </SharedText>
        }
        body={
          <SharedText
            fontSize="14px"
            lineHeight="150%"
            fontWeight={400}
            letterSpacing="-0.01em"
            sx={{
              marginBottom: "1.5rem",
              textAlign: "left",
            }}
          >
            You have not switched on your ATS integration yet - let’s do it!
          </SharedText>
        }
        styles={{
          minWidth: "560px",
          maxWidth: "560px",
        }}
        body_2={
          <SharedText
            fontSize="14px"
            lineHeight="150%"
            fontWeight={400}
            letterSpacing="-0.01em"
            sx={{
              textAlign: "left",
            }}
          >
            Proceed to the{" "}
            <span
              onClick={navigateToIntegration}
              style={{
                textDecoration: "underline",
                color: "#3785F9",
                cursor: "pointer",
              }}
            >
              Integrations page
            </span>{" "}
            and setup your ATS integration there. Doing so is quick and easy. It
            will automatically create candidates in your ATS whenever they are
            created in your Connect jobs.
          </SharedText>
        }
      />
    </PrivateLayout>
  );
};

export default Job;
