import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdditionalUserInfo } from "firebase/auth";
import {
  auth as firebaseAuth,
  db,
  doc,
  serverTimestamp,
  setDoc,
} from "utils/firebase";
import { IAuthType, IRoleType, IUser } from "types/types";
import { ISignInPayload } from "./interface";
import { emailRegex, initialUser } from "constants/constants";
import { customError } from "utils/helpers";

const _signInWithEmailAndPassword = async (payload: ISignInPayload) => {
  if (!payload.password || !payload.email) {
    throw customError({
      code: "auth/missing-params",
      message: "Missing password or email",
      name: "Authentication Error",
    });
  }

  const auth = firebaseAuth.getAuth();
  const userCredential = await firebaseAuth.signInWithEmailAndPassword(
    auth,
    payload.email,
    payload.password
  );

  if (!userCredential.user) {
    throw customError({
      code: "auth/no-linked-data",
      message: "User Not Found",
      name: "Authentication Error",
    });
  }

  const tokenResult = await userCredential.user.getIdTokenResult(true);
  const claims = tokenResult.claims;
  const role = claims.role as IRoleType;

  if (role === undefined) {
    throw customError({
      code: "auth/no-linked-data",
      message: "User Not Found",
      name: "Authentication Error",
    });
  }

  if (role !== IRoleType.CLIENT) {
    throw customError({
      code: "user/recruiter",
      message: "Not a client user",
      name: "Authorization Error",
    });
  }

  const uid = userCredential.user.uid;
  const email = userCredential.user.email as string;
  const company_id = claims.company_id as string;
  const displayName = userCredential.user.displayName;
  const fullName = displayName?.split(" ") || ["", ""];
  const firstName = fullName[0];
  const lastName = fullName[1];
  const client: IUser = {
    ...initialUser,
    email,
    firstName,
    lastName,
    role,
    uid,
    company_id,
  };
  return client;
};

const _signInWithGoogle = async () => {
  const GoogleAuthProvider = firebaseAuth.GoogleAuthProvider;
  const provider = new GoogleAuthProvider();
  const auth = firebaseAuth.getAuth() as any;
  const userCredential = await firebaseAuth.signInWithPopup(auth, provider);
  const additionalUserInfo = getAdditionalUserInfo(userCredential);

  if (additionalUserInfo?.isNewUser) {
    if (!userCredential.user) {
      throw customError({
        code: "auth/account-creation-failure",
        message: "Unable to create account",
        name: "Authentication Error",
      });
    }

    const email = userCredential.user.email as string;
    const isWorkEmail = emailRegex.test(email);

    if (!isWorkEmail) {
      await firebaseAuth.deleteUser(userCredential.user);

      throw customError({
        code: "auth/not-work-email",
        message: "Please provide a valid work email",
        name: "Authentication Error",
      });
    }

    const firstName = userCredential.user.displayName?.split(" ")[0] || "";
    const lastName = userCredential.user.displayName?.split(" ")[1] || "";
    const uid = userCredential.user.uid;
    const role = IRoleType.CLIENT;
    const accepted_terms = true;
    const next_onboarding_stage = "your-company";
    const has_posted_jobs = false;
    const create_job_prompt = true;

    const client: IUser = {
      ...initialUser,
      firstName,
      lastName,
      email,
      accepted_terms,
      uid,
      role,
      next_onboarding_stage,
      company_id: "",
      has_posted_jobs,
      create_job_prompt,
      notifications: {
        email: {
          new_candidate: true,
        },
      },
    };

    const clientRef = doc(db, `v2_clients/${uid}`);
    await setDoc(
      clientRef,
      { ...client, created_at: serverTimestamp() },
      { merge: true }
    );

    return client;
  }

  const tokenResult = await userCredential.user.getIdTokenResult(true);
  const claims = tokenResult.claims;
  const role = claims.role;

  if (role === undefined) {
    throw customError({
      code: "auth/no-linked-data",
      message: "User Not Found",
      name: "Authentication Error",
    });
  }

  if (role !== IRoleType.CLIENT) {
    throw customError({
      code: "user/recruiter",
      message: "Not a client user",
      name: "Authorization Error",
    });
  }

  const uid = userCredential.user.uid;
  const email = userCredential.user.email as string;
  const displayName = userCredential.user.displayName;
  const company_id = claims.company_id as string;
  const fullName = displayName?.split(" ") || ["", ""];
  const firstName = fullName[0];
  const lastName = fullName[1];
  const client: IUser = {
    ...initialUser,
    email,
    firstName,
    lastName,
    role,
    uid,
    company_id,
  };

  return client;
};

export const doSignInAction: AsyncThunk<IUser, ISignInPayload, {}> =
  createAsyncThunk("users/signin", async (payload) => {
    if (payload.type === IAuthType.EMAIL_PASS) {
      const user = _signInWithEmailAndPassword(payload);
      return user;
    }

    const user = _signInWithGoogle();
    return user;
  });
