/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRoleType } from "types/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPayload } from "./interface";
import * as axios from "api/axios";
const axiosInstance = axios.default;

export const doInviteMemberAction = createAsyncThunk(
  "send-company-invite",
  async (payload: IPayload) => {
    if (payload.cancelled) {
      return {
        ...payload,
      };
    }
    try {
      const response = await axiosInstance.post("/company-invite-member", {
        sendersFirstName: payload.sendersFirstName,
        sendersLastName: payload.sendersLastName,
        sendersEmail: payload.sendersEmail,
        sendersId: payload.sendersId,
        receiver_id: payload.receiver_id,
        receiversFirstName: payload.receiversFirstName,
        receiversLastName: payload.receiversLastName,
        receiversEmail: payload.receiversEmail,
        role: IRoleType.CLIENT,
        company_id: payload.company_id,
        company_name: payload.company_name,
        isExistingUser: payload.isExistingUser,
      });

      return {
        success: true,
        message: response.data,
      };
    } catch (error: any) {
      return error.response?.data || "Something went wrong";
    }
  }
);
