import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUpdateCandidatePayload, IUpdateCandidateState } from "./interface";
import { doUpdateCandidateAction } from "./update.actions";
import { IInterviewStatus, IRoleType, ReduxStatus } from "types/types";

const initialResponse: IUpdateCandidatePayload = {
  job_id: "",
  candidate_id: "",
  updated_candidate: {
    interview_status: IInterviewStatus.PENDING,
    reason: "",
    updated_by: {
      firstName: "",
      lastName: "",
      role: IRoleType.CLIENT,
      uid: "",
      updated_at: null,
    },
  },
};
const initialState: IUpdateCandidateState = {
  status: ReduxStatus.empty,
  response: initialResponse,
  error: {},
};

export const updateCandidate = createSlice({
  name: "candidate/update",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        doUpdateCandidateAction.pending,
        (state: IUpdateCandidateState) => {
          state.status = ReduxStatus.pending;
        }
      )
      .addCase(
        doUpdateCandidateAction.fulfilled,
        (
          state: IUpdateCandidateState,
          action: PayloadAction<IUpdateCandidatePayload>
        ) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doUpdateCandidateAction.rejected,
        (state: IUpdateCandidateState, action: PayloadAction<unknown>) => {
          state.status = ReduxStatus.rejected;
          state.response = initialResponse;
          state.error = action.payload;
        }
      );
  },
});

export default updateCandidate.reducer;
