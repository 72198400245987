import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { _get } from "utils/lodash";
import {
  ROUTE_ACTIVE_JOBS,
  ROUTE_INTEGRATIONS,
  ROUTE_COMMAND_BAR,
  ROUTE_RESIZE_NAVBAR,
  ROUTE_RELEASE_NOTES,
} from "routes/route-items";
import CarretLeft from "assets/images/carret-backwards.svg";

interface IProps {
  value: string;
  menuItems: any;
  route: string;
  active: boolean;
  isExpanded?: boolean;
  path: string;
  routes: any;
  integrations: number;
  updateMenu: any;
  openCommandBar?: any;
  openReleaseNotes?: any;
  resizeDrawer?: any;
  nonRedirect?: any;
  topLevel?: boolean;
  jobsWithoutCriteria: string[];
}
const Circle = styled("div")`
  top: 4px;
  color: black;
  font-weight: 600;
  background-color: ${({ theme }) => _get(theme, "palette.colors.orange")};
  width: 24px;
  height: 24px;
  border-radius: 30%;
  line-height: 24px;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
`;

const TheTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "black",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "black",
  },
}));

const SideMenuListItem: FC<IProps> = ({
  value,
  menuItems,
  route,
  routes,
  active,
  path,
  integrations,
  jobsWithoutCriteria,
  topLevel,
  updateMenu,
  openCommandBar,
  openReleaseNotes,
  resizeDrawer,
  nonRedirect,
  isExpanded,
}) => {
  return (
    <ListItem
      disablePadding
      onClick={
        route === ROUTE_COMMAND_BAR
          ? () => openCommandBar()
          : route === ROUTE_RESIZE_NAVBAR
          ? () => {
              resizeDrawer();
              nonRedirect(!active);
            }
          : route === ROUTE_RELEASE_NOTES
          ? () => openReleaseNotes()
          : () => updateMenu({ [value]: !active }, routes, topLevel)
      }
      sx={{
        paddingLeft: "24px",
        paddingRight: "24px",
        marginTop: "4px",
      }}
    >
      <ListItemButton
        className={active || path === route ? "active" : ""}
        sx={{
          ":hover": {
            color: (theme) => theme.palette.text.secondary,
            borderRadius: "12px",
            border:
              route === ROUTE_RELEASE_NOTES && !isExpanded
                ? "2px solid #6f767e"
                : "none",
            background:
              route === ROUTE_RELEASE_NOTES && !isExpanded ? "#f4f4f4" : "#fff",
          },
          "&.active": {
            background: (theme) => theme.palette.grey[300],
            color: (theme) => theme.palette.text.secondary,
            borderRadius: "12px",
          },
          height: "48px",
          border:
            route === ROUTE_RELEASE_NOTES && !isExpanded
              ? "2px solid #ddd"
              : "none",
          borderRadius:
            route === ROUTE_RELEASE_NOTES && !isExpanded ? "12px" : 0,
          transition: "all 0.3s cubic-bezier(0.16, 1, 0.3, 1)",
        }}
      >
        <TheTooltip
          title={
            route == ROUTE_RESIZE_NAVBAR && !isExpanded ? (
              <span style={{ fontSize: "15px" }}>Expand Navbar</span>
            ) : !isExpanded ? (
              <span style={{ fontSize: "15px" }}>{value}</span>
            ) : (
              ""
            )
          }
          placement="right"
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              paddingRight: "14px",
              color: (theme) => theme.palette.grey[400],
              display:
                isExpanded && route == ROUTE_RESIZE_NAVBAR
                  ? "none"
                  : "inline-flex",
              marginLeft:
                route == ROUTE_RELEASE_NOTES
                  ? "0"
                  : route == ROUTE_RESIZE_NAVBAR
                  ? "8px"
                  : "2px",
              "& img": {
                transition: "display 0.3s cubic-bezier(0.16, 1, 0.3, 1)",
                ":hover": {
                  filter: isExpanded ? "brightness(1)" : "brightness(0)",
                },
                width: "20px",
              },
            }}
          >
            {_get(menuItems, `${value}.icon`)}
            {route === ROUTE_INTEGRATIONS && (
              <>
                {integrations > 0 && (
                  <Circle
                    sx={{
                      display: isExpanded ? "none" : "inline-block",
                      transition: "display 3s cubic-bezier(0.16, 1, 0.3, 1)",
                      position: "absolute",
                      top: 0,
                      right: "-3px",
                      width: "16px",
                      height: "16px",
                      borderRadius: "6px",
                      fontSize: "12px",
                      lineHeight: "16px",
                    }}
                  >
                    {integrations}
                  </Circle>
                )}
              </>
            )}
            {route === ROUTE_ACTIVE_JOBS && (
              <>
                {jobsWithoutCriteria?.length > 0 && (
                  <Circle
                    sx={{
                      display: isExpanded ? "none" : "inline-block",
                      transition: "display 3s cubic-bezier(0.16, 1, 0.3, 1)",
                      position: "absolute",
                      top: 0,
                      right: "-3px",
                      width: "16px",
                      height: "16px",
                      borderRadius: "6px",
                      fontSize: "12px",
                      lineHeight: "16px",
                    }}
                  >
                    {jobsWithoutCriteria?.length}
                  </Circle>
                )}
              </>
            )}
          </ListItemIcon>
        </TheTooltip>
        <ListItemText
          primary={value}
          sx={{
            "& .MuiTypography-root": {
              fontFamily: "Inter",
              fontWeight: "600",
              transition: "display 3s cubic-bezier(0.16, 1, 0.3, 1)",
            },
            display: isExpanded ? "flex" : "none",
            transition: "display 3s cubic-bezier(0.16, 1, 0.3, 1)",
          }}
        />
        {route === ROUTE_RESIZE_NAVBAR && (
          <>
            {isExpanded && (
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  background: "#EFEFEF",
                  boxShadow:
                    "inset 0px -2px 1px rgb(0 0 0 / 5%), inset 0px 1px 1px #ffffff",
                  borderRadius: "12px",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={CarretLeft} alt="" />
              </Box>
            )}
          </>
        )}
        {route === ROUTE_INTEGRATIONS && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              paddingRight: "14px",
              paddingLeft: "20px",
              color: (theme) => theme.palette.grey[400],
            }}
          >
            {integrations > 0 && (
              <Circle
                sx={{
                  display: isExpanded ? "inline-block" : "none",
                  transition: "display 3s cubic-bezier(0.16, 1, 0.3, 1)",
                }}
              >
                {integrations}
              </Circle>
            )}
          </ListItemIcon>
        )}
        {route === ROUTE_ACTIVE_JOBS && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              paddingRight: "14px",
              paddingLeft: "20px",
              color: (theme) => theme.palette.grey[400],
            }}
          >
            {jobsWithoutCriteria?.length > 0 && (
              <Circle
                sx={{
                  display: isExpanded ? "inline-block" : "none",
                  transition: "display 3s cubic-bezier(0.16, 1, 0.3, 1)",
                }}
              >
                {jobsWithoutCriteria?.length}
              </Circle>
            )}
          </ListItemIcon>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default SideMenuListItem;
