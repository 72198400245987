import React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/material";
import { IConnectedCandidate, IInterviewStatus } from "types/types";
import { formatCandidateDate } from "utils/helpers";
import Archive from "assets/images/archive.svg";
import { _first, _get } from "utils/lodash";

interface IProps {
  candidate: IConnectedCandidate;
}

const Main = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const AvatarDescription = styled("div")`
  padding-left: 12px;
`;

const Text = styled("span")`
  font-weight: 600;
`;

const ArchiveContainer = styled(Box)`
  display: flex;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.palette.grey[500]};
  },
`;

const AvatarContent = ({ candidate }: IProps) => {
  const experiences = _first(_get(candidate, "candidate_experiences")) || {};
  const jobTitle = experiences.jobTitle || candidate.jobTitle;
  const companyName = experiences.companyName || candidate.currentCompany;
  const location = experiences.location || candidate.location;

  return (
    <Main>
      <Box
        sx={{
          display: "flex",
          marginTop: "9px",
        }}
      >
        <Avatar
          alt="Remy Sharp"
          src={candidate?.candidate_avatar_url}
          sx={{ width: 80, height: 80 }}
        />
        <AvatarDescription>
          <Text
            sx={{
              fontFamily: "Inter",
              fontSize: "15px",
              fontWeight: 700,
              lineHeight: "24px",
              letterSpacing: "-0.01em",
              textAlign: "left",
            }}
          >
            {candidate?.firstName} {candidate?.lastName}
          </Text>
          <div></div>
          <Text
            sx={{
              fontFamily: "Inter",
              fontSize: "13px",
              color: "#9A9FA5",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "-1%",
              textAlign: "left",
            }}
          >
            {jobTitle || ""}
            {(
              <>
                {companyName ? " @ " : ""}
                {companyName}
              </>
            ) || ""}
          </Text>
          <div></div>
          <Text
            sx={{
              fontFamily: "Inter",
              fontSize: "13px",
              color: "#9A9FA5",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "-1%",
              textAlign: "left",
            }}
          >
            {location || ""}
          </Text>
          <Text></Text>
        </AvatarDescription>
      </Box>
      <Box
        sx={{
          marginBottom: "18px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            fontWeight: "normal",
            fontSize: "13px",
            color: "#9A9FA5",
            marginRight: "12px",
          }}
        >
          {candidate?.interview_status === IInterviewStatus.ARCHIVED && (
            <ArchiveContainer>
              Archived
              <img
                style={{
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
                height="13.33px"
                width="13.33px"
                src={Archive}
                alt="archived"
              />
            </ArchiveContainer>
          )}
        </Box>
        <Box
          sx={{
            fontWeight: "normal",
            fontSize: "12px",
            color: "#9A9FA5",
          }}
        >
          Added {formatCandidateDate(candidate?.created_at)}
        </Box>
      </Box>
    </Main>
  );
};

export default AvatarContent;
