/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import SignIn from "pages/auth/sign-in";
import SignUp from "pages/auth/sign-up";
import ForgotPassword from "pages/auth/forgot-password";

import PrivacyPolicy from "pages/privacy-policy";

import Jobs from "pages/jobs";
import Job from "pages/job";
import Integrations from "pages/integrations";
import FAQs from "pages/faqs";

import Building from "pages/building";
import Company from "pages/company";
import Welcome from "pages/welcome";
import JobPageNewJob from "pages/new-job";
import JobOnboardingComplete from "pages/job-onboarding-complete";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./private-routes";
import * as ROUTES from "./route-items";
import PublicRoute from "./public-routes";
import Layout from "layouts/layout";
import { RootState, useAppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { _get } from "utils/lodash";
import Settings from "pages/settings";
import CompanyProfile from "pages/company-profile";
import DesktopRoute from "./desktop-routes";
import PageNotFound from "pages/page-not-found";
import MobileNotice from "pages/mobile-notice";
import ReactGA from "react-ga";
import { IUser, IRoleType } from "types/types";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import { doRemoveSlackUserAction } from "redux/services/manageSlackNotificationUsers/manageSlackNotification.actions";
import { doUpdateCompanyAction } from "redux/services/company/update/update-company.actions";
import { doGetLinkTokenAction } from "redux/services/ats/link-token/get.actions";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { refetchLinkToken } from "utils/helpers";
import { init } from "commandbar";
import * as axios from "api/axios";
const axiosInstance = axios.default;
import { config } from "config";

/*below is not a real route, but a placeholder for the page loader
test while signed out*/
import PageLoader from "components/page-loader";

/*Just a demo route for toasts*/
import ToastDemo from "pages/toast-demo";

const is_prod = config.REACT_APP_IS_PRODUCTION;
const organisationId = config.REACT_APP_COMMANDBAR_ORGANISATION_ID;
init(organisationId, { environment: is_prod ? "Production" : "Staging" });

const RouteComponent = () => {
  const location = useLocation();
  const userState = useSelector(
    (reduxState: RootState) => reduxState.user.response
  );
  const companyState = useSelector(
    (reduxState: RootState) => reduxState.getCompany.response
  );
  const atsData = useSelector((reduxState: RootState) => reduxState.linkToken);

  const APP_ID = config.REACT_APP_ALGOLIA_APP_ID;
  const SEARCH_KEY = config.REACT_APP_ALGOLIA_SEARCH_KEY;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSuccess = React.useCallback(async (public_token: string) => {
    await axiosInstance.get("/ats-account-token", {
      params: {
        company_id: userState.company_id,
        user_email: userState.email,
        public_token,
      },
    });
    await dispatch(
      doGetLinkTokenAction({
        company_id: userState.company_id,
        user_email: userState.email,
        _link_token: {},
      })
    );
  }, []);

  const linkedAccount = _get(atsData, "response.message") || "";
  // eslint-disable-next-line no-unused-vars
  const { open, isReady } = useMergeLink({
    linkToken: linkedAccount.link_token,
    onSuccess,
  });

  const redirectPath = React.useCallback(() => {
    if (!userState.uid) return;

    const allowed_auto_redirect_url: string[] = [
      ROUTES.ROUTE_COMPANY,
      ROUTES.ROUTE_BUILDING,
      ROUTES.ROUTE_WELCOME,
    ];
    if (
      userState?.next_onboarding_stage &&
      allowed_auto_redirect_url.includes(userState?.next_onboarding_stage)
    ) {
      navigate(`/${userState.next_onboarding_stage}`);
    }
    if (
      userState?.next_onboarding_stage === ROUTES.ROUTE_JOB_ONBOARDING_COMPLETE
    ) {
      navigate(`/${ROUTES.ROUTE_ACTIVE_JOBS}`);
    }
  }, [userState?.next_onboarding_stage, userState?.uid]);

  React.useEffect(() => {
    redirectPath();
  }, [redirectPath]);

  React.useEffect(() => {
    if (is_prod) {
      const UA_ID = config.REACT_APP_GOOGLE_UNIVERSIAL_ANALYTICS_PROPERTY_ID;
      ReactGA.initialize(UA_ID);
    }
  }, []);

  const fetchJobs = (company_id: string) => {
    const requestOptions = {
      method: "POST",
      headers: new Headers({
        "X-Algolia-Application-Id": APP_ID,
        "X-Algolia-API-Key": SEARCH_KEY,
      }),
    };

    window.CommandBar.addRecords("v2_jobs", [], {
      onInputChange: async (query: unknown) => {
        const response = await fetch(
          `https://${APP_ID}-dsn.algolia.net/1/indexes/v2_jobs/query`,
          {
            ...requestOptions,
            body: JSON.stringify({
              query,
              filters: `company_id:'${company_id}'`,
            }),
          }
        );
        const { hits } = await response.json();
        return hits;
      },
    });
  };

  const enableSlack = (user: IUser) => {
    window.CommandBar.addCallback("enableSlack", () => {
      window.open(
        `https://slack.com/oauth/v2/authorize?client_id=3417414999671.3444660245457&scope=chat:write,chat:write.customize,users:read&user_scope=chat:write&redirect_uri=${
          config.REACT_APP_SLACK_REDIRECT_URI
        }/&state=${user.email}~${user.company_id}~${user?.firstName}~${
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname
        }~${user?.uid}
  		`,
        "_blank"
      );
    });
  };

  const disableSlack = (user: IUser) => {
    window.CommandBar.addCallback("disableSlack", async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await dispatch(
        doRemoveSlackUserAction({
          email: user.email,
          uid: user.uid,
          slack_access_token: user.slack_access_token,
          slack_user_access_token: user.slack_user_access_token,
          role: IRoleType.CLIENT,
        })
      );

      if (response.payload.data.success) {
        toast(
          <ToastMessage
            title="Slack Integration"
            body="Unlinking Slack was successful!"
          />,
          { type: "success", position: "top-right" }
        );
      }
    });
  };

  const enableATS = (user: IUser) => {
    window.CommandBar.addCallback("enableATS", async () => {
      const is_tokenExpired = linkedAccount.created_at
        ? refetchLinkToken(new Date(linkedAccount.created_at?._seconds * 1000))
        : true;

      if (linkedAccount.link_token && !is_tokenExpired) {
        open();
      } else {
        const resp = await dispatch(
          doGetLinkTokenAction({
            company_id: user.company_id,
            user_email: user.email,
          })
        );

        const _resp = _get(resp, "payload.message") || {};
        if (_resp.success) {
          open();
        } else {
          toast(
            <ToastMessage
              title="Link ATS"
              body={_resp.message || "Error occurred linking to ATS "}
            />,
            { type: "error", position: "top-right" }
          );
        }
      }
    });
  };

  const disableATS = (user: IUser) => {
    window.CommandBar.addCallback("disableATS", async () => {
      if (!user.company_id) {
        toast(<ToastMessage title="Disable ATS" body="Missing company id" />, {
          type: "warning",
          position: "top-right",
        });
        return;
      }

      await dispatch(
        doUpdateCompanyAction({
          company_id: user.company_id,
          company: {
            ats_data: null,
          },
        })
      );

      await dispatch(
        doGetLinkTokenAction({
          company_id: user.company_id,
          user_email: user.email,
          _link_token: {},
        })
      );

      toast(
        <ToastMessage title="Disable ATS" body="Successfully disabled ATS" />,
        {
          type: "success",
          position: "top-right",
        }
      );
    });
  };

  React.useEffect(() => {
    if (userState?.uid && is_prod) {
      ReactGA.set({
        userId: userState.uid,
      });
    }

    const isOnBoardingComplete =
      userState?.next_onboarding_stage === ROUTES.ROUTE_ACTIVE_JOBS;

    if (userState?.uid && userState?.accepted_terms && isOnBoardingComplete) {
      window.CommandBar?.boot(userState?.uid);

      const routerFunction = (url: string) => navigate(url);
      window.CommandBar.addRouter(routerFunction);

      const isSlackEnabled = userState?.integrations?.Slack?.selected;
      const isATSEnabled = userState?.integrations?.ATS?.selected;
      const hasJobs = companyState?.total_jobs > 0;
      window.CommandBar.addMetadata("role", IRoleType.CLIENT);
      window.CommandBar.addMetadata("isSlackEnabled", isSlackEnabled);
      window.CommandBar.addMetadata("isATSEnabled", isATSEnabled);
      window.CommandBar.addMetadata("hasJobs", hasJobs);

      enableSlack(userState);
      disableSlack(userState);
      enableATS(userState);
      disableATS(userState);
      fetchJobs(userState?.company_id);
    } else {
      window.CommandBar?.shutdown();
    }

    return () => {
      window.CommandBar?.shutdown();
    };
  }, [
    userState?.uid,
    userState?.accepted_terms,
    userState?.next_onboarding_stage,
  ]);

  React.useEffect(() => {
    if (is_prod) {
      ReactGA.pageview(`${location.pathname}${location.search}`);
    }
  }, [location]);

  return (
    <Routes>
      <Route element={<DesktopRoute />}>
        <Route element={<PublicRoute user={userState} />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<SignIn />} />
            <Route path={ROUTES.ROUTE_SIGN_IN} element={<SignIn />} />
            <Route path={ROUTES.ROUTE_SIGN_UP} element={<SignUp />} />
            <Route
              path={ROUTES.ROUTE_FORGOT_PASSWORD}
              element={<ForgotPassword />}
            />
          </Route>
          <Route
            path={ROUTES.ROUTE_LOADER}
            element={<PageLoader text="Connect..." />}
          />
          <Route path={ROUTES.ROUTE_TOAST_DEMO} element={<ToastDemo />} />
        </Route>
        <Route element={<PrivateRoute user={userState} />}>
          <Route element={<Layout />}>
            <Route path={ROUTES.ROUTE_COMPANY} element={<Company />} />
            <Route path={ROUTES.ROUTE_BUILDING} element={<Building />} />
            <Route path={ROUTES.ROUTE_WELCOME} element={<Welcome />} />
            <Route
              path={ROUTES.ROUTE_JOB_ONBOARDING_COMPLETE}
              element={<JobOnboardingComplete />}
            />
          </Route>
          <Route path={ROUTES.ROUTE_NEW_JOB} element={<JobPageNewJob />} />
          <Route path={ROUTES.ROUTE_EDIT_JOB} element={<JobPageNewJob />} />

          <Route path={ROUTES.ROUTE_ACTIVE_JOBS} element={<Jobs />} />
          <Route path={ROUTES.ROUTE_ACTIVE_JOB} element={<Job />} />
          <Route path={ROUTES.ROUTE_ACTIVE_JOB_CANDIDATE} element={<Job />} />
          <Route path={ROUTES.ROUTE_CANDIDATE_REJECT} element={<Job />} />
          <Route path={ROUTES.ROUTE_CANDIDATE_SHORTLIST} element={<Job />} />
          <Route path={ROUTES.ROUTE_SETTINGS} element={<Settings />} />
          <Route
            path={ROUTES.ROUTE_COMPANY_PROFILE}
            element={<CompanyProfile />}
          />
          <Route path={ROUTES.ROUTE_WELCOME} element={<Welcome />} />
          <Route path={ROUTES.ROUTE_INTEGRATIONS} element={<Integrations />} />
          <Route path={ROUTES.ROUTE_FAQS} element={<FAQs />} />
        </Route>
      </Route>
      <Route path={ROUTES.ROUTE_PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={ROUTES.ROUTE_MOBILE_NOTICE} element={<MobileNotice />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteComponent;
