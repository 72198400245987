import React from "react";
import Box from "@mui/material/Box";
import Text from "components/shared/text/text";

interface IProps {
  steps?: number;
  currentStep: number;
}

const Steps: React.FC<IProps> = ({ steps = 2, currentStep }) => {
  const arr: any = Array.from({ length: steps }).fill(1);

  return (
    <Box display="flex" alignItems="center">
      {arr.map((el: number, i: number) => {
        return (
          <Box key={i + 1} display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: i + 1 < currentStep ? "#3785F9" : "#ffffff",
                border: `1.3px solid ${
                  i + 1 <= currentStep ? "#3785F9" : "#E2E2E2"
                }`,
                height: "28px",
                width: "28px",
                borderRadius: "50%",
              }}
            >
              <Text
                fontSize="14px"
                fontWeight={500}
                lineHeight="17px"
                letterSpacing="-0.02em"
                sx={{
                  color:
                    i + 1 < currentStep
                      ? "#ffffff"
                      : i + 1 > currentStep
                      ? "#E2E2E2"
                      : "#33383F",
                  marginLeft: 0,
                }}
              >
                {i + 1}
              </Text>
            </Box>
            {i + 1 != arr.length && (
              <Box
                sx={{
                  height: "2px",
                  backgroundColor: i + 1 < currentStep ? "#3785F9" : "#E2E2E2",
                  width: "70px",
                }}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default Steps;
