import { doActionFetchUserAction } from "redux/services/user/user.actions";
import SignupForm from "components/signup-form";
import CompleteSignUp from "components/signup-form/complete";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { doSignUpAction } from "redux/services/signup/signup.actions";
import { RootState, useAppDispatch } from "redux/store";
import { IAuthType, InviteStatus, IUser } from "types/types";
import { useLocation } from "react-router-dom";
import queryString, { ParsedQuery } from "query-string";
import { decodeBase64, splitCompanyName } from "utils/helpers";
import { doGetCompanyAction } from "redux/services/company/get/get.actions";
import { ROUTE_ACTIVE_JOBS, ROUTE_WELCOME } from "routes/route-items";
import { useNavigate } from "react-router-dom";
import { collection_name } from "utils/firebase-refs";
import { doc, setDoc, db } from "utils/firebase";
import { toast } from "react-toastify";
import ToastMessage from "components/toast-message";
import { SerializedError } from "@reduxjs/toolkit";

interface IProps {}
interface IState {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  checked?: boolean;
  error?: string;
  company_name?: string;
  company_id?: string;
  isLoading?: boolean;
  next_onboarding_stage?: string;
  terms: boolean;
}

const initialState: IState = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  checked: false,
  error: "",
  company_name: "",
  company_id: "",
  isLoading: false,
  next_onboarding_stage: "",
  terms: false,
};

const SignUp: React.FC<IProps> = () => {
  const [state, setState] = useState(initialState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const reduxState = useSelector(
    (reduxState: RootState) => reduxState.signup || {}
  );

  React.useEffect(() => {
    if (location?.search?.includes("invitationCode")) {
      const params: ParsedQuery<any> = queryString.parse(location.search);
      if (params.invitationCode) {
        const invited_email = decodeBase64(params.invitationCode);
        const invited_company_id = decodeBase64(params.co_id);
        const invited_company_name = splitCompanyName(params.companyName);
        setState((st: IState) => ({
          ...st,
          email: invited_email,
          company_name: invited_company_name,
          company_id: invited_company_id,
          next_onboarding_stage: ROUTE_ACTIVE_JOBS,
        }));
      }
    }
  }, [location]);

  const onCompleteSubmit = async (data: IState) => {
    setState((st: IState) => ({ ...st, isLoading: true }));

    const signUpResponse = await dispatch(
      doSignUpAction({
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password as string,
        email: data.email,
        type: IAuthType.EMAIL_PASS,
        accepted_terms: state.checked,
        next_onboarding_stage: state.next_onboarding_stage,
        company_id: state.company_id,
        company_name: state.company_name,
      })
    );

    if (signUpResponse.meta.requestStatus === "rejected") {
      setState((st: IState) => ({ ...st, isLoading: false }));
      const serializedError = (signUpResponse as any).error as SerializedError;

      toast.error(
        <ToastMessage
          title="Sign up error"
          body={
            serializedError.code === "auth/email-already-in-use"
              ? "User with this email already exists"
              : "Something went wrong. Try again"
          }
        />,
        { type: "error", position: "top-right" }
      );
      return;
    }

    const user = signUpResponse.payload as IUser;

    if (state.company_id && state.company_name) {
      const params: ParsedQuery<any> = queryString.parse(location.search);
      const invitedMemberRef = doc(
        db,
        collection_name.v2_company,
        state.company_id,
        "invited_members",
        params.invitationCode
      );
      setDoc(
        invitedMemberRef,
        { status: InviteStatus.accepted },
        { merge: true }
      );

      await dispatch(
        doGetCompanyAction({
          company_id: state.company_id,
        })
      );
    }

    await dispatch(
      doActionFetchUserAction({
        uid: user.uid,
        user,
      })
    );

    if (state.company_id && state.company_name) {
      const next = state.next_onboarding_stage || ROUTE_WELCOME;
      navigate(`/${next}`);
    }
  };

  const onSubmit = (data: IState) => {
    setState((st: IState) => ({
      ...st,
      email: data.email,
    }));
  };

  const handleGoogleSignUp = async () => {
    const signUpResponse = await dispatch(
      doSignUpAction({
        type: IAuthType.GOOGLE_AUTH,
      })
    );

    if (signUpResponse.meta.requestStatus === "rejected") {
      setState((st: IState) => ({ ...st, isLoading: false }));
      const serializedError = (signUpResponse as any).error as SerializedError;
      toast.error(
        <ToastMessage
          title="Sign up error"
          body={
            serializedError.code === "auth/email-already-in-use"
              ? "User with this email already exists"
              : serializedError.code === "auth/not-work-email"
              ? serializedError.message
              : serializedError.code === "auth/account-creation-failure"
              ? serializedError.message
              : "Something went wrong. Try again"
          }
        />,
        { type: "error", position: "top-right" }
      );
      return;
    }

    const user = signUpResponse.payload as IUser;

    await dispatch(
      doActionFetchUserAction({
        uid: user.uid,
        user,
      })
    );
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((st: IState) => ({
      ...st,
      checked: event.target.checked,
    }));
  };

  return state.email.length > 0 ? (
    <CompleteSignUp
      reduxStatus={reduxState.status}
      state={state}
      handleCheckboxChange={handleCheckboxChange}
      checked={state.checked}
      onSubmit={onCompleteSubmit}
    />
  ) : (
    <SignupForm
      handleGoogleSignUp={handleGoogleSignUp}
      onSubmit={onSubmit}
      isLoading={reduxState.status === "pending" || state.isLoading}
    />
  );
};

export default SignUp;
