import { combineReducers } from "redux";
import signup from "./services/signup/signup";
import signin from "./services/signin/signin";
import forgotPassword from "./services/forgot-password/forgot-password";
import user from "./services/user/user";
import companyCreate from "./services/company/create/create";
import updateCompany from "./services/company/update/update";
import getCompany from "./services/company/get/get";
import jobCreate from "./services/job/create/create";
import jobGet from "./services/job/get/get";
import jobsGet from "./services/job/getJob/get";
import jobUpdate from "./services/job/update/update";
import UserUpdate from "./services/user/update/update";
import removeSlackNotificationUser from "./services/manageSlackNotificationUsers/removeSlackNotificationUser";
import inviteMember from "./services/company/invite/invite";
import candidatesGet from "./services/candidates/getCandidates/get";
import candidateUpdate from "./services/candidates/update/update";
import linkToken from "./services/ats/link-token/get";
import getATSJobs from "./services/ats/get-ats-jobs/get";

const rootReducer = combineReducers({
  signup,
  signin,
  forgotPassword,
  user,
  companyCreate,
  updateCompany,
  getCompany,
  inviteMember,
  jobsGet,
  jobCreate,
  jobUpdate,
  jobGet,
  removeSlackNotificationUser,
  UserUpdate,
  candidatesGet,
  linkToken,
  getATSJobs,
  candidateUpdate,
});

export default rootReducer;
