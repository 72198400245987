import * as React from "react";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import JobsContainer from "components/jobs/jobs-container";
import JobsHeader from "components/jobs/jobs-header";
import Drawer from "layouts/drawer";
import { Link, useLocation } from "react-router-dom";
import Dialog from "components/dialog";
import { _get } from "utils/lodash";
import * as ROUTES from "routes/route-items";
import { resetUsers } from "redux/services/user/user";
import { RootState, useAppDispatch } from "redux/store";
import storage from "redux-persist/lib/storage";
import { useNavigate } from "react-router-dom";
import queryString, { ParsedQuery } from "query-string";
import { IJobsState } from "components/jobs/interface";
import { ReduxStatus } from "types/types";
import { doUpdateUserAction } from "redux/services/user/update/update.actions";
import Text from "components/shared/text/text";

const Container = styled(Box)`
  display: flex;
  padding: 24px;
  border-radius: 8px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  margin-top: 40px;
`;
interface IState {
  openDialog: boolean;
}

const initialState: IState = {
  openDialog: false,
};

const initialJobsState: IJobsState = {
  page: 0,
  totalJobs: 0,
  totalPages: 0,
  hasMore: true,
  openDialog: false,
  selectedCard: {},
  job_list: [],
  jobStatus: "ACTIVE",
  selected_job_function: "All_job_functions",
  period: {
    start: null,
    end: null,
  },
  searchQuery: "",
};

export default function Jobs() {
  const [state, setState] = React.useState<IState>(initialState);
  const [jobsState, setJobsState] =
    React.useState<IJobsState>(initialJobsState);
  let location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const locationState = _get(location, "state.from") || {};
  const params: ParsedQuery<any> = queryString.parse(locationState.search);

  const user = useSelector((reduxState: RootState) => reduxState.user.response);
  const userUpdateStatus = useSelector(
    (reduxState: RootState) => reduxState.UserUpdate.status
  );

  React.useEffect(() => {
    if (
      location.pathname === `/${ROUTES.ROUTE_ACTIVE_JOBS}` &&
      locationState.pathname === `/${ROUTES.ROUTE_SIGN_IN}` &&
      locationState.search.includes("invitationCode") &&
      !params.accepted
    ) {
      setState((st: IState) => ({
        ...st,
        openDialog: true,
      }));
    }
  }, [location]);

  const handleAcceptDialog = () => {
    dispatch(resetUsers());
    storage.removeItem("persist:root");
    window.localStorage.removeItem("persist:root");
    const search_location = `${locationState.search}&accepted=true`;
    navigate(`/${ROUTES.ROUTE_SIGN_IN}${search_location}`);
  };

  const acceptTerms = async () => {
    await dispatch(
      doUpdateUserAction({
        uid: user.uid,
        user: {
          accepted_terms: true,
        },
      })
    );
  };

  const closeDialog = () => {
    setState((st: IState) => ({
      ...st,
      openDialog: false,
    }));
    if (location?.pathname) {
      navigate(`${location.pathname}`);
    } else {
      navigate(ROUTES.ROUTE_ACTIVE_JOBS);
    }
  };

  const capitalizeFirstLetter = (str: string) =>
    str ? str.charAt(0).toUpperCase() + str.slice(1) : "";

  return (
    <Drawer>
      <Container
        sx={[
          (theme) => ({
            [theme.breakpoints.down("md")]: {
              height: "calc(65vh - 5px)",
            },
            [theme.breakpoints.up("xl")]: {
              height: "calc(86vh - 5px)",
            },
          }),
        ]}
      >
        <JobsHeader setJobsState={setJobsState} />
        <JobsContainer jobsState={jobsState} setJobsState={setJobsState} />
      </Container>
      <Dialog
        openDialog={state.openDialog}
        isLoading={false}
        handleCloseDialog={closeDialog}
        handleAcceptDialog={handleAcceptDialog}
        body={`You were invited to the team: ${capitalizeFirstLetter(
          params.companyName
        )}`}
        body_2={`Do you want your account to join this team?`}
      />
      <Dialog
        openDialog={!!user?.uid && !user?.accepted_terms}
        isLoading={userUpdateStatus === ReduxStatus.pending}
        handleCloseDialog={() => {}}
        canCloseDialog={false}
        handleAcceptDialog={acceptTerms}
        showCloseIcon={false}
        title={"Terms and Conditions"}
        submitButtonText="Accept"
        body={
          <Text
            fontSize="14px"
            fontWeight={400}
            lineHeight="150%"
            letterSpacing="-0.01em"
            sx={{
              maxWidth: "25vw",
              fontStyle: "normal",
              textAlign: "left",
              color: theme.palette.text.primary,
              marginLeft: "0rem",
            }}
          >
            I agree to ACELR8's{" "}
            <Box
              component="a"
              href="#"
              sx={{
                textDecoration: "underline",
                color: theme.palette.text.primary,
                letterSpacing: "-0.01em",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "150%",
              }}
            >
              <Link target={"_blank"} to="/privacy-policy">
                Privacy Policy
              </Link>
            </Box>{" "}
            and to receive marketing communications.
          </Text>
        }
      />
    </Drawer>
  );
}
