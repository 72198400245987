import React from "react";
import { styled } from "@mui/material/styles";
import { ICandidate } from "types/types";
import SelectedSkill from "../candidates/selected-skill";
import { getRecruiterNotes } from "utils/helpers";
import ReactHtmlParser from "react-html-parser";
import draftToHtml from "draftjs-to-html";

interface IProps {
  candidate: ICandidate;
}

const Notes = styled("div")`
  margin-bottom: 20px;
`;

const Text = styled("div")`
  font-weight: 600;
`;

const InterviewNotes = ({ candidate }: IProps) => {
  return (
    <>
      <Notes
        sx={{
          marginTop: "10px",
        }}
      >
        <Text sx={{ fontSize: "14px", height: "24px" }}>
          {candidate?.skills_must_have
            ? "Candidate meets the following must-have criteria:"
            : "Why is this candidate relevant?"}
        </Text>
        <div style={{ fontSize: "14px", color: "#9A9FA5" }}>
          {candidate?.skills_must_have &&
          candidate?.skills_must_have?.length > 0
            ? candidate?.skills_must_have?.map((skill, i) => (
                <SelectedSkill key={i} skill={skill}></SelectedSkill>
              ))
            : candidate?.reasonForRelevance || "-"}
        </div>
      </Notes>
      <Notes sx={{ fontSize: "14px" }}>
        <Text style={{ fontSize: "14px", height: "24px" }}>
          {candidate?.skills_nice_to_have
            ? "Candidate meets the following nice-to-have criteria:"
            : "Potential concerns"}
        </Text>
        <Text style={{ fontSize: "14px", color: "#9A9FA5" }}>
          {candidate?.skills_nice_to_have
            ? candidate?.skills_nice_to_have?.length > 0
              ? candidate?.skills_nice_to_have?.map((skill, i) => (
                  <SelectedSkill key={i} skill={skill}></SelectedSkill>
                ))
              : "-"
            : candidate?.reasonForNotRelevance || "-"}
        </Text>
      </Notes>
      <Notes>
        <Text sx={{ fontSize: "14px", height: "24px" }}>Recruiter's notes</Text>
        <div style={{ fontSize: "14px", color: "#9A9FA5" }}>
          {typeof getRecruiterNotes(candidate?.recruiter_notes, true) ===
          "string"
            ? getRecruiterNotes(candidate?.recruiter_notes, true) || "-"
            : ReactHtmlParser(
                draftToHtml(getRecruiterNotes(candidate?.recruiter_notes, true))
              ) || "-"}
        </div>
      </Notes>
    </>
  );
};

export default InterviewNotes;
