import * as React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTE_ACTIVE_JOBS, ROUTE_COMPANY } from "./route-items";
import { IUser } from "types/types";

interface IProps {
  user: IUser;
}

const PublicRoute: React.FC<IProps> = ({ user }) => {
  let location = useLocation();

  if (!user?.uid) {
    return <Outlet />;
  }

  if (user.next_onboarding_stage) {
    const to =
      user.next_onboarding_stage === "active-pages"
        ? ROUTE_ACTIVE_JOBS
        : user.next_onboarding_stage;
    return <Navigate to={to} state={{ from: location }} replace />;
  }

  return <Navigate to={ROUTE_COMPANY} state={{ from: location }} replace />;
};

export default PublicRoute;
