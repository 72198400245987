/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPayload } from "./interface";
import * as axios from "api/axios";
const axiosInstance = axios.default;

export const doGetATSJobsAction = createAsyncThunk(
  "ats/get-atsJobs",
  async (payload: IPayload) => {
    try {
      const response = await axiosInstance.get("/ats-jobs", {
        params: {
          company_id: payload.company_id,
        },
      });

      return {
        success: true,
        message: response.data || {},
      };
    } catch (error: any) {
      return error.response?.data || "Something went wrong";
    }
  }
);
