import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { IGetJobsPayload } from "./interface";
import * as axios from "api/axios";
const axiosInstance = axios.default;

export const doGetJobsAction: AsyncThunk<
  IGetJobsPayload | unknown,
  IGetJobsPayload,
  {}
> = createAsyncThunk("jobs/get", async (payload: IGetJobsPayload) => {
  if (payload.jobs) {
    return payload;
  }
  try {
    // ?? Needs to be sorted: Not currently being used
    // ?? Structure set fot http request is set: params needs to be sorted
    const response = await axiosInstance.get("/job", {
      params: {
        job_id: "",
        fetchCandidates: false,
      },
    });

    return {
      success: true,
      message: response.data,
    };
  } catch (error: unknown) {
    return error;
  }
});
