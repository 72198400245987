/* eslint-disable prettier/prettier */
import { TOTAL_INTEGRATIONS } from "constants/constants";
import { lightFormat } from "date-fns";
import { _first } from "utils/lodash";
import moment from "moment";
import { SerializedError } from "@reduxjs/toolkit";

export const encodeBase64 = (str: string) => {
  return window.btoa(str);
};

export const decodeBase64 = (str: string) => {
  return window.atob(str);
};

export const scrollSectionIntoView = (hashId: string): void => {
  if (hashId) {
    const element = document.querySelector(hashId);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }
};

export const formatCandidateDate = (milliseconds: number) => {
  if (!milliseconds) return "-";
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const newDate = new Date(milliseconds);
  const day = newDate.getDate();
  const month = monthNames[newDate.getMonth()];
  const year = newDate.getFullYear();
  const time = lightFormat(newDate, "HH:mm");
  return `${day} ${month} ${year} ${time}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUnselectedIntegrations = (integrations: any) => {
  if (!integrations) {
    return TOTAL_INTEGRATIONS;
  }

  const selected_integrations = Object.keys(integrations).filter(
    (integration) => integrations[integration].selected
  );

  return TOTAL_INTEGRATIONS - selected_integrations.length;
};

export const getRecruiterNotes = (
  notes: string | undefined,
  block?: boolean
) => {
  if (!notes) return;
  try {
    const parsedNotes = JSON.parse(notes);
    return block ? parsedNotes : _first(parsedNotes.blocks).text;
  } catch (e) {
    return notes;
  }
};

export const joinCompanyName = (companyName: string) => {
  return companyName.split(" ").join("_");
};

export const splitCompanyName = (companyName: string) => {
  return companyName.split("_").join(" ");
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const refetchLinkToken = (created_at: any) => {
  moment.updateLocale("en", {
    relativeTime: {
      past: function (input) {
        return input === "just now" ? input : input + " ago";
      },
      s: "%d seconds",
      future: "in %s",
      ss: "%d seconds",
      m: "%d minute",
      mm: "%d minutes",
      h: "%d hour",
      hh: "%d hours",
      d: "%d day",
      dd: "%d days",
      M: "%d month",
      MM: "%d months",
      y: "%d year",
      yy: "%d years",
    },
  });
  if (!created_at) return true;
  const created_time = moment(created_at).startOf("minutes").fromNow(true);
  const past_time = [
    "hours",
    "hour",
    "days",
    "day",
    "months",
    "month",
    "years",
    "year",
  ];
  let refetch_token = false;
  if (past_time.includes(created_time.split(" ")[1])) {
    refetch_token = true;
  } else if (["minute", "minutes"].includes(created_time.split(" ")[1])) {
    if (Number(created_time.split(" ")[0]) > 25) {
      refetch_token = true;
    }
  }
  return refetch_token;
};

export const customError = (options: SerializedError) => options;
