const styles = {
  choicesScrollContainer: {
    width: "500px",
    height: "14.5rem",
    overflowY: "auto",
    scollBehaviour: "smooth",
    "&::-webkit-scrollbar": {
      width: "0.375em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f4f4f4",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(111, 118, 126, 0.4)",
      borderRadius: "40px",
    },
  },
  choicesContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  buttonChoice: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "24px",
    background: "#fcfcfc",
    border: "2px solid #efefef",
    borderRadius: "20px 4px",
    outline: "none",
    padding: "12px 20px",
    height: "48px",
    marginRight: "20px",
    marginBottom: "20px",
    cursor: "pointer",
    transition: "ease-in-out 0.2s",
    "&:hover": {
      color: "#fcfcfc",
      background: "#1a1d1f",
    },
  },
  buttonChoiceSelected: {
    color: "#fcfcfc",
    background: "#1a1d1f",
  },
};
export default styles;
