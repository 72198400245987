import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { db, doc, setDoc, serverTimestamp } from "utils/firebase";
import { IUpdateJobPayload } from "./interface";

export const doUpdateJobAction: AsyncThunk<
  IUpdateJobPayload,
  IUpdateJobPayload,
  {}
> = createAsyncThunk("job/update", async (payload, { rejectWithValue }) => {
  try {
    const job_data = {
      ...payload.job,
      updated_at: serverTimestamp(),
    };
    const jobRef = doc(db, `v2_jobs/${payload.job_id}`);
    await setDoc(jobRef, job_data, { merge: true });
    return payload;
  } catch (error: unknown) {
    return rejectWithValue(JSON.stringify(error));
  }
});
