/* eslint-disable */
import * as firebase from "firebase/app";
import * as auth from "firebase/auth";
import * as firestore from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import * as storage from "firebase/storage";
import { config } from "config";

(window as any).firebase = firebase as any;

const firebaseConfig: firebase.FirebaseOptions = {
  apiKey: config.REACT_APP_FIREBASE_API_KEY,
  authDomain: config.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: config.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: config.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: config.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: config.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

//const auth = firebase.auth();
// const firestore = firebase.firestore();
// const storage = firebase.storage();
const functions = getFunctions(firebaseApp, "europe-west3");
const db = firestore.getFirestore();
const {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  startAfter,
  getDocs,
  getDoc,
  doc,
  setDoc,
  serverTimestamp,
} = firestore;

export {
  firebase,
  auth,
  firestore,
  db,
  storage,
  functions,
  httpsCallable,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  startAfter,
  getDocs,
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
};
