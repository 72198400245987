/* eslint-disable no-unused-vars */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import SharedButton from "../shared/button/button";
import Span from "components/shared/span/span";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

type IStylesProps = {
  styles?: { [key: string]: string };
};

const DialogContainer = styled(Dialog)<IStylesProps>`
  & .MuiDialog-paperScrollPaper {
    min-width: ${(props) => {
      return props.styles?.minWidth ? props.styles.minWidth : "30rem";
    }}
    max-width: 30rem;
    background-color: ${({ theme }) => theme.palette.grey[50]};
  }
  & .MuiDialogTitle-root {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 500;
    font-size: 22px;
    font-family: Inter;
  }
  & .MuiDialogContentText-root {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 400;
    font-size: 16px;
    font-family: Inter;
  }
  & .MuiLoadingButton-text {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: 400;
    font-size: 16px;
    font-family: Inter;
  }
`;

const DisButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 400;
  font-size: 16px;
  font-family: Inter;
`;
const LoadButton = styled(LoadingButton)`
  color: ${({ theme }) => theme.palette.primary.main} !important;
  font-weight: 400;
  font-size: 16px;
  font-family: Inter;
  min-width: 5.3rem;
  display: flex;
  justify-content: flex-start;
  border: none;
  &:hover {
    border: none !important;
  }
`;

const ErrorMessage = styled(Span)`
  color: ${({ theme }) => theme.palette.error.main};
`;

interface IProps {
  body: string | React.ReactNode;
  body_2?: string | React.ReactNode;
  title?: string | React.ReactNode;
  submitButtonText?: string;
  canCloseDialog?: boolean;
  isLoading: boolean;
  openDialog: boolean;
  handleAcceptDialog: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseDialog: (e: React.MouseEvent<HTMLButtonElement>) => void;
  errorMessage?: string;
  styles?: { [key: string]: string };
  showCloseIcon?: boolean;
}

const AlertDialog: React.FC<IProps> = ({
  body = "",
  body_2 = "",
  title = "Confirm your action",
  submitButtonText = "Yes",
  canCloseDialog = true,
  isLoading = false,
  openDialog = false,
  handleAcceptDialog,
  handleCloseDialog,
  errorMessage = "",
  styles = {},
  showCloseIcon = false,
}) => {
  return (
    <DialogContainer
      open={openDialog}
      styles={styles ? { ...styles } : {}}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        {showCloseIcon ? (
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          {body_2}
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          mx: "15px",
          marginBottom: "10px",
        }}
      >
        {canCloseDialog && (
          <DisButton onClick={handleCloseDialog}>Cancel</DisButton>
        )}
        <SharedButton
          variant="contained"
          sx={{
            width: "auto",
          }}
          onClick={handleAcceptDialog}
        >
          {isLoading ? (
            <CircularProgress
              sx={{ marginRight: "10px" }}
              color="inherit"
              size="20px"
            />
          ) : (
            <>{submitButtonText}</>
          )}
        </SharedButton>
      </DialogActions>
    </DialogContainer>
  );
};

export default AlertDialog;
