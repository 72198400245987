/* eslint-disable prettier/prettier */
import React, { MouseEventHandler, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import Input from "components/shared/input/input";
import Button from "@mui/material/Button";
import CheckBox from "components/shared/checkBox";
import Slider from "@mui/material/Slider";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { JobPitchSchema } from "utils/form-schema";
import Form from "components/shared/form/form";
import SharedButton from "components/shared/button/button";
import { Asterisk } from "components/shared/asterisk";

import BackArrow from "assets/images/arrow_backward.svg";
import { _get as get } from "utils/lodash";
import SharedEditor from "components/shared/editor";
import Select from "react-select";
import { useTheme } from "@mui/material/styles";
import Union from "assets/images/Union-Black.svg";
import styles from "./styles";
import CircularProgress from "@mui/material/CircularProgress";
import { boxContainer } from "./shared";
import InfoIcon from "@mui/icons-material/Info";
import SharedTooltip from "../shared/tooltip/tooltip";
import { ITheme } from "theme";

const FormLabel = styled("span")`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #33383f;
  &.light {
    color: #adaeaf;
    font-style: italic;
  }
`;

const FormContainer = styled(Box)`
  margin-bottom: 32px;
`;

const AddIcon = styled("img")`
  margin-right: 15px;
`;

const ModifiedTextButton = styled(Button)`
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #33383f;
  text-transform: none;
`;

const ModifiedSlider = styled(Slider)`
  & .MuiSlider-track {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border: none;
  }
  & .MuiSlider-rail {
    background-color: #ccc;
  }
  & .MuiSlider-thumb {
    background-color: ${({ theme }) => theme.palette.grey[50]};
    border: 2px solid ${({ theme }) => theme.palette.secondary.main};
    height: 12px;
    width: 12px;
    box-shadow: 0px 2px 5px 0px rgb(55 133 249 / 61%);
  }
  & .MuiSlider-valueLabel {
    background-color: #272b30;
  }
`;

interface IProps {
  handleSetState: Function;
  handleBack: MouseEventHandler;
  reduxStateStatus: string;
  // eslint-disable-next-line
  handleSavePitch: (data: any) => void;
  state: {
    step_3: {
      checkbox_share_salary_range: boolean;
      checkbox_pay_unknown: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editorState: any;
      salaryRange: number[];
      job_exciting_things: { [key: string]: string };
      recruiter_notes: string;
      selected_currency: { value: string; label: string };
    };
  };
}

interface IState {
  job_exciting_things: number[];
}

interface ICurrencyOptions {
  readonly value: string;
  readonly label: string;
  readonly color?: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const initialState = {
  job_exciting_things: [1, 2],
};

const PageThree: React.FC<IProps> = ({
  handleSavePitch,
  state,
  handleSetState,
  handleBack,
  reduxStateStatus,
}) => {
  const app_theme = useTheme();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(JobPitchSchema),
  });
  const currency_opt: readonly ICurrencyOptions[] = [
    { value: "eur", label: "€ EUR" },
    { value: "usd", label: "$ USD" },
    { value: "GBP", label: "£ GBP" }
  ];

  const MIN = 25000;
  const MAX = 100000;

  const [moreInputs, setMoreInputs] = useState<IState>(initialState);
  const [first, second] = get(state, "step_3.salaryRange");
  const theme: ITheme = useTheme();

  React.useEffect(() => {
    Object.keys(state.step_3.job_exciting_things).map((el: string) => {
      setValue(
        `${el}_job_exciting_things`,
        state.step_3.job_exciting_things[el]
      );
      setMoreInputs((prevState) => {
        const prev = get(prevState, "job_exciting_things", []);
        if (prev[prev.length - 1] < Number(el) + 1) {
          return {
            ...prevState,
            job_exciting_things: prev.concat(Number(el) + 1),
          };
        } else {
          return {
            ...prevState,
          };
        }
      });
    });
  }, [state.step_3]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    handleSetState({
      value: newValue as number[],
      key: "salaryRange",
      step: "step_3",
    });
  };

  const handleInputChange = (e: Event, previous: number) => {
    const inputValue = get(e, "target.value");
    const value = inputValue ? inputValue : "0";

    const formattedValue = parseFloat(value.replace(/,/g, ""));
    if (isNaN(formattedValue)) return;

    let { salaryRange } = state.step_3;
    const index = salaryRange.indexOf(previous);

    if (index !== -1) {
      salaryRange[index] = formattedValue;
      handleSetState({
        value: salaryRange,
        key: "salaryRange",
        step: "step_3",
      });
    }
  };

  const addMore = (title: string) => {
    setMoreInputs((prevState) => {
      const prev = get(prevState, title, []);
      return {
        ...prevState,
        [title]: prev.concat(prev[prev.length - 1] + 1),
      };
    });
  };

  const currencyFormatted = (amount: number) => {
    return (parseInt(`${amount}`.replace(/[^\d]+/gi, "")) || 0).toLocaleString("en-US");
  };

  return (
    <Box>
      <Form onSubmit={handleSubmit(handleSavePitch)}>
        <FormContainer>
          <Box sx={boxContainer}>
            <FormLabel>
              What will make the ideal candidates excited about this job?:
            </FormLabel>
            <Asterisk />
            <SharedTooltip
              title="Add selling points of the position and company so our recruiters can attract the right candidates."
            >
              <InfoIcon
                sx={{
                  color: theme.palette.text.primary,
                  height: "19.33px",
                  ml: "5px",
                }}
              />
            </SharedTooltip>
          </Box>
          <Box>
            {get(moreInputs, "job_exciting_things", []).map(
              (_: never, index: number) => {
                return (
                  <Input
                    id={`${index}_job_exciting_things`}
                    key={`${index}_job_exciting_things`}
                    register={register}
                    errors={get(errors, `${index}_job_exciting_things.message`, null)}
                    variant="filled"
                    placeholder="e.g Can launch space rocket"
                    errorSx={{
                      top: "5px"
                    }}
                    sx={{
                      marginBottom: "10px",
                    }}
                  />
                );
              }
            )}
            <Box>
              <ModifiedTextButton
                variant="text"
                onClick={() => addMore("job_exciting_things")}
              >
                <AddIcon alt="Add icon" src={Union} /> <span>Add more</span>
              </ModifiedTextButton>
            </Box>
          </Box>
        </FormContainer>
        <FormContainer>
          <Box sx={{ ...boxContainer, mb: "50px" }}>
            <FormLabel>What’s the salary range for this role?</FormLabel>
            <SharedTooltip
              title="Drag the salary range or write down the numbers directly.
                The numbers should describe the annual salary, before tax,
                on target earnings including both base and bonus."
            >
              <InfoIcon
                sx={{
                  color: theme.palette.text.primary,
                  height: "19.33px",
                  ml: "5px",
                }}
              />
            </SharedTooltip>
          </Box>
          <Box>
            <ModifiedSlider
              min={MIN}
              max={MAX}
              // step={1000}
              value={state.step_3.salaryRange}
              onChange={handleChange}
              valueLabelDisplay="on"
              getAriaLabel={() => "Always visible"}
            />
          </Box>
          <Box sx={{ display: "flex", gap: "12px", mb: "15px" }}>
            <Select
              className="basic-single"
              classNamePrefix="react-select"
              defaultValue={currency_opt[0]}
              value={state.step_3.selected_currency}
              isSearchable={false}
              isClearable={false}
              name="currency"
              options={currency_opt}
              styles={styles.reactSelect}
              onChange={(selected) => {
                handleSetState({
                  value: selected,
                  key: "selected_currency",
                  step: "step_3",
                });
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: app_theme.palette.grey[900],
                  primary: app_theme.palette.grey[700],
                },
              })}
            />
            <Input
              type="text"
              id="salary_range_lower_bound"
              register={(id: string) => id}
              value={currencyFormatted(first)}
              // @ts-ignore
              onChange={(e) => handleInputChange(e, first)}
              // register={register}
              // errors={errors.salary_range?.message}
              variant="filled"
            />
            <Input
              type="text"
              id="salary_range_upper_bound"
              // register={register}
              // errors={errors.salary_range?.message}
              // @ts-ignore
              onChange={(e) => handleInputChange(e, second)}
              value={currencyFormatted(second)}
              register={(id: string) => id}
              variant="filled"
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: "10px" }}>
            <CheckBox
              checked={state.step_3.checkbox_share_salary_range}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleSetState({
                  value: event.target.checked,
                  key: "checkbox_share_salary_range",
                  step: "step_3",
                });
              }}
            />
            <FormLabel>
              <Box sx={{ ...boxContainer, marginBottom: 0 }}>
                I’d like for the salary range to be shared with candidates
                directly.
                <SharedTooltip
                  title="If you check this box, our recruiters will share the
                  salary range with candidates when reaching out to them."
                  >
                    <InfoIcon
                      sx={{
                        color: theme.palette.text.primary,
                        height: "19.33px",
                        ml: "5px",
                      }}
                      />
                  </SharedTooltip>
                </Box>
              <FormLabel className="light">
                This could increase the candidates’ interest.
              </FormLabel>
            </FormLabel>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: "10px" }}>
            <CheckBox
              checked={state.step_3.checkbox_pay_unknown}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleSetState({
                  value: event.target.checked,
                  key: "checkbox_pay_unknown",
                  step: "step_3",
                });
              }}
            />
            <FormLabel>We don’t know the pay range for this position</FormLabel>
          </Box>
          <Box>
            <FormLabel>
              Is this salary range competitive to the role?{" "}
              <a
                target="_blank"
                href="https://calendly.com/michael-79/30min"
                rel="noreferrer"
              >
                Speak to a recruiter
              </a>
            </FormLabel>
          </Box>
        </FormContainer>
        <FormContainer>
          <Box sx={{ marginBottom: "10px" }}>
            <FormLabel sx={boxContainer}>
              Note to recruiter
              <SharedTooltip
                title="Feel free to write down any additional notes or details that will
                  help with the search. This will only be visible to our recruiters."
              >
                <InfoIcon
                  sx={{
                    color: theme.palette.text.primary,
                    height: "19.33px",
                    ml: "5px",
                  }}
                />
              </SharedTooltip>
            </FormLabel>
          </Box>
          <Box>
            <SharedEditor
              editorState={state.step_3.editorState}
              onEditorStateChange={(editorState) => {
                handleSetState({
                  value: editorState,
                  key: "editorState",
                  step: "step_3",
                });
              }}
            />
          </Box>
        </FormContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            alignItems: "center",
          }}
        >
          <SharedButton
            variant="outlined"
            onClick={handleBack}
            sx={{
              width: "auto",
              "&.Mui-disabled": {
                display: "none",
              },
            }}
          >
            <img src={BackArrow} alt="back arrow" />
            <Box sx={{ marginLeft: "6px", textTransform: "initial" }}>Back</Box>
          </SharedButton>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ flex: "1 1 auto" }}
          >
            <SharedButton type="submit" variant="contained" sx={{ width: "35%" }}>
              <Box
                sx={{
                  marginLeft: "6px",
                  textTransform: "initial",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {reduxStateStatus === "pending" && (
                  <CircularProgress
                    sx={{ marginRight: "10px" }}
                    color="inherit"
                    size="20px"
                  />
                )}
                {reduxStateStatus === "pending" ? "" : "Finish"}
              </Box>
            </SharedButton>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export default PageThree;
