import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUpdateState } from "./interface";
import { doUpdateCompanyAction } from "./update-company.actions";
import { ICompany, ReduxStatus } from "types/types";

const initialState: IUpdateState = {
  status: ReduxStatus.empty,
  response: {},
  error: {},
};

export const updateCompany = createSlice({
  name: "company/update",
  initialState,
  reducers: {
    increment: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doUpdateCompanyAction.pending, (state: IUpdateState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doUpdateCompanyAction.fulfilled,
        (state: IUpdateState, action: PayloadAction<Partial<ICompany>>) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doUpdateCompanyAction.rejected,
        (state: IUpdateState, action: PayloadAction<unknown>) => {
          state.status = ReduxStatus.rejected;
          state.response = {};
          state.error = action.payload;
        }
      );
  },
});

export const { increment } = updateCompany.actions;
export default updateCompany.reducer;
