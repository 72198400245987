import Box from "@mui/material/Box";
import SharedButton from "components/shared/button/button";
import Text from "components/shared/text/text";
import React, { FC, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import Steps from "components/shared/steps/steps";
import STYLES from "./styles";

type IVariant = "outlined" | "contained" | "text" | undefined;

interface IProps {
  children: ReactNode;
  title?: string;
  btn_text?: string;
  variant?: IVariant;
  Icon?: ReactNode;
  id: string;
  currentStep?: number;
  stepsStyles?: any;
  sx?: any;
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: 760px) {
    width: 100%;
    padding: 20px;
  }
`;

const AuthForm: FC<IProps> = ({
  children,
  title = "",
  btn_text = "",
  variant = "contained",
  Icon,
  id = "",
  sx = {},
  stepsStyles = {},
  currentStep,
}) => {
  return (
    <Wrapper
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      position="relative"
      sx={{ ...STYLES.container, ...sx }}
      id={id}
    >
      {currentStep || currentStep === 0 ? (
        <Box
          sx={{
            alignSelf: "center",
            width: stepsStyles.width || "500px",
            height: stepsStyles.height || "0px",
          }}
        >
          <Box
            sx={
              {
                // position: "absolute",
                // top: stepsStyles.top || "-3rem",
                // left: stepsStyles.left || "5.6rem",
              }
            }
            display="flex"
          >
            <Steps steps={3} currentStep={currentStep} />
          </Box>
        </Box>
      ) : null}
      <Text
        variant="h2"
        fontSize="36px"
        fontWeight={600}
        lineHeight="48px"
        letterSpacing="-0.03em"
        sx={{
          marginBottom: "2rem",
          marginLeft: 0,
          textAlign: "left",
        }}
      >
        {title}
      </Text>
      {children}
      {btn_text && (
        <SharedButton type="submit" variant={variant}>
          {" "}
          {Icon}
          <Box sx={{ marginLeft: "6px", textTransform: "initial" }}>
            {btn_text}
          </Box>
        </SharedButton>
      )}
    </Wrapper>
  );
};

export default AuthForm;
