import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetCompanyState } from "./interface";
import { doGetCompanyAction } from "./get.actions";
import { ICompany, IRoleType, IWorkType, ReduxStatus } from "types/types";

const initialResponse: ICompany = {
  company_id: "",
  billingAddress: "",
  billingEmailAddress: "",
  created_at: null,
  industries: [],
  location: "",
  name: "",
  original_avatar_url: "",
  role: IRoleType.CLIENT,
  thumbnail_avatar_url: "",
  jobs_without_criteria: [],
  total_candidates: 0,
  total_jobs: 0,
  uid: "",
  vatID: "",
  work_type: IWorkType.OFFICE,
};

const initialState: IGetCompanyState = {
  status: ReduxStatus.empty,
  response: initialResponse,
  error: {},
};

export const getCompany = createSlice({
  name: "company/get",
  initialState,
  reducers: {
    increment: () => {
      // state.candidateList = [1, 2];
      // ✅ CORRECT: logs a plain JS copy of the current data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doGetCompanyAction.pending, (state: IGetCompanyState) => {
        state.status = ReduxStatus.pending;
      })
      .addCase(
        doGetCompanyAction.fulfilled,
        (state: IGetCompanyState, action: PayloadAction<ICompany>) => {
          state.status = ReduxStatus.fulfilled;
          state.error = {};
          state.response = action.payload;
        }
      )
      .addCase(
        doGetCompanyAction.rejected,
        (state: IGetCompanyState, action: PayloadAction<unknown>) => {
          state.status = ReduxStatus.rejected;
          state.response = initialResponse;
          state.error = action.payload;
        }
      );
  },
});

export const { increment } = getCompany.actions;
export default getCompany.reducer;
